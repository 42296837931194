import React, { useState, useEffect, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, X, Edit, Trash2, AlertCircle, Loader, MapPin, Camera, SquareParking, Shield, Star, Clock, Info, Plane, ImageIcon } from 'lucide-react';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import 'leaflet/dist/leaflet.css';

interface Location {
  id: string;
  latitude: number;
  longitude: number;
  title: string;
  description: string;
  bestEquipment: string;
  parkingInformation: string;
  securityInformation: string;
  communityRating: number;
  status: 'pending' | 'approved';
  airportICAO: string;
  extraInformation: string;
  userId: string;
  createdAt: number;
  updatedAt: number;
  openingHours: string;
}

const AdminLocationsPage: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [mapCenter, setMapCenter] = useState<[number, number]>([51.505, -0.09]);
  const [mapZoom, setMapZoom] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const [locationsResponse, pendingLocationsResponse] = await Promise.all([
        fetch('https://api.spottertools.org/api/locations', {
          headers: { 'Authorization': `Bearer ${token}` }
        }),
        fetch('https://api.spottertools.org/api/admin/pending-locations', {
          headers: { 'Authorization': `Bearer ${token}` }
        })
      ]);

      if (!locationsResponse.ok || !pendingLocationsResponse.ok) {
        throw new Error('Failed to fetch data');
      }

      const [locationsData, pendingLocationsData] = await Promise.all([
        locationsResponse.json(),
        pendingLocationsResponse.json()
      ]);

      const processLocations = (data: any, status: 'approved' | 'pending'): Location[] => {
        if (!data || typeof data !== 'object') return [];
        return Object.entries(data).map(([id, locationData]) => ({
          id,
          ...(locationData as Omit<Location, 'id'>),
          status
        }));
      };

      const approvedLocations = processLocations(locationsData, 'approved');
      const pendingLocations = processLocations(pendingLocationsData, 'pending');
      const allLocations = [...approvedLocations, ...pendingLocations];

      setLocations(allLocations);

      if (allLocations.length > 0) {
        setMapCenter([allLocations[0].latitude, allLocations[0].longitude]);
        setMapZoom(10);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleApprove = async (id: string) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.spottertools.org/api/admin/review-location/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: 'approve' })
      });
      if (!response.ok) {
        throw new Error('Failed to approve location');
      }
      toast.success('Location approved successfully');
      fetchData();
    } catch (error) {
      console.error('Error approving location:', error);
      toast.error('Failed to approve location');
    }
  };

  const handleReject = async (id: string) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.spottertools.org/api/admin/review-location/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ action: 'reject' })
      });
      if (!response.ok) {
        throw new Error('Failed to reject location');
      }
      toast.success('Location rejected successfully');
      fetchData();
    } catch (error) {
      console.error('Error rejecting location:', error);
      toast.error('Failed to reject location');
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://api.spottertools.org/api/locations/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to delete location');
        }
        toast.success('Location deleted successfully');
        fetchData();
      } catch (error) {
        console.error('Error deleting location:', error);
        toast.error('Failed to delete location');
      }
    }
  };

  const handleEdit = (id: string) => {
    navigate(`/admin/edit-location/${id}`);
  };

  const approvedIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const pendingIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const MapComponent = () => {
    const map = useMap();
    useEffect(() => {
      if (selectedLocation) {
        map.setView([selectedLocation.latitude, selectedLocation.longitude], 13);
      }
    }, [map]);
    return null;
  };

  const renderStars = (rating: number) => {
    return Array(5).fill(0).map((_, i) => (
      <Star key={i} className={`h-4 w-4 ${i < rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`} />
    ));
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <Loader className="w-10 h-10 text-blue-500 animate-spin" />
        <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900">
        <AlertCircle className="w-16 h-16 text-red-500" />
        <h1 className="mt-4 text-2xl font-bold text-gray-900 dark:text-gray-100">Error</h1>
        <p className="mt-2 text-lg text-gray-700 dark:text-gray-300">{error}</p>
        <Button onClick={() => navigate('/')} className="mt-6">
          Go Back Home
        </Button>
      </div>
    );
  }

  const pendingLocations = locations.filter(location => location.status === 'pending');
  const approvedLocations = locations.filter(location => location.status === 'approved');

  const LocationListItem: React.FC<{ 
    location: Location; 
    onSelect: (location: Location) => void; 
    onApprove?: (id: string) => void;
    onReject?: (id: string) => void;
    onEdit?: (id: string) => void;
    onDelete?: (id: string) => void;
  }> = ({ location, onSelect, onApprove, onReject, onEdit, onDelete }) => (
    <motion.div 
      className={`flex justify-between items-center p-4 border rounded-lg cursor-pointer shadow-sm hover:shadow-md transition-all duration-200 ${
        location.status === 'pending' 
        ? 'border-orange-300 dark:border-orange-700 bg-orange-50 dark:bg-orange-900/30'
        : 'border-green-300 dark:border-green-700 bg-green-50 dark:bg-green-900/30'
    }`}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    onClick={() => onSelect(location)}
  >
    <div>
      <h3 className="font-bold text-gray-800 dark:text-gray-200">
        {location.title}
        <span className={`ml-2 text-xs px-2 py-1 rounded-full ${
          location.status === 'pending' 
            ? 'bg-orange-200 text-orange-800 dark:bg-orange-800 dark:text-orange-200' 
            : 'bg-green-200 text-green-800 dark:bg-green-800 dark:text-green-200'
        }`}>
          {location.status}
        </span>
      </h3>
      <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
        {location.description.slice(0, 50)}...
      </p>
    </div>
    <div className="flex space-x-2">
      {location.status === 'pending' && onApprove && onReject ? (
        <>
          <Button onClick={(e) => { e.stopPropagation(); onApprove(location.id); }} size="sm" variant="outline" className="bg-green-100 hover:bg-green-200 dark:bg-green-900 dark:hover:bg-green-800">
            <Check className="h-4 w-4" />
          </Button>
          <Button onClick={(e) => { e.stopPropagation(); onReject(location.id); }} size="sm" variant="destructive" className="bg-red-100 hover:bg-red-200 dark:bg-red-900 dark:hover:bg-red-800">
            <X className="h-4 w-4" />
          </Button>
        </>
      ) : onEdit && onDelete ? (
        <>
          <Button onClick={(e) => { e.stopPropagation(); onEdit(location.id); }} size="sm" variant="outline" className="bg-blue-100 hover:bg-blue-200 dark:bg-blue-900 dark:hover:bg-blue-800">
            <Edit className="h-4 w-4" />
          </Button>
          <Button onClick={(e) => { e.stopPropagation(); onDelete(location.id); }} size="sm" variant="destructive" className="bg-red-100 hover:bg-red-200 dark:bg-red-900 dark:hover:bg-red-800">
            <Trash2 className="h-4 w-4" />
          </Button>
        </>
      ) : null}
    </div>
  </motion.div>
  );
  
  const LocationDetail: React.FC<{ icon: React.ElementType; label: string; value: React.ReactNode }> = ({ icon: Icon, label, value }) => (
  <div className="flex items-start">
    <Icon className="mr-3 h-5 w-5 text-gray-400 mt-0.5" />
    <div>
      <p className="text-sm font-medium text-gray-500 dark:text-gray-400">{label}</p>
      <p className="text-gray-700 dark:text-gray-300">{value}</p>
    </div>
  </div>
  );
  
  const NoLocationsMessage: React.FC = () => (
  <div className="text-center py-8">
    <Info className="mx-auto h-12 w-12 text-gray-400" />
    <p className="mt-2 text-lg font-medium text-gray-900 dark:text-gray-100">No locations found</p>
    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">There are currently no locations to display.</p>
  </div>
  );
  
  const LocationsList: React.FC<{ 
  locations: Location[]; 
  title: string; 
  onSelect: (location: Location) => void;
  onApprove?: (id: string) => void;
  onReject?: (id: string) => void;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
  }> = ({ locations, title, onSelect, onApprove, onReject, onEdit, onDelete }) => (
  <div className="mb-4">
    <h3 className={`text-lg font-semibold mb-2 ${
      title.toLowerCase().includes('pending') ? 'text-orange-600 dark:text-orange-400' : 'text-green-600 dark:text-green-400'
    }`}>
      {title}
    </h3>
    <AnimatePresence>
      {locations.map((location) => (
        <LocationListItem 
          key={location.id} 
          location={location} 
          onSelect={onSelect} 
          onApprove={onApprove}
          onReject={onReject}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ))}
    </AnimatePresence>
  </div>
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
      <Toaster position="top-right" />
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="flex-grow container mx-auto pt-20 px-4 py-8">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-900 dark:text-gray-100"
        >
          Admin: Manage Locations
        </motion.h1>
        
        <div className="flex justify-end mb-4">
          <Button onClick={() => navigate('/admin/images')} className="bg-blue-500 hover:bg-blue-600 text-white">
            <ImageIcon className="mr-2 h-4 w-4" />
            Review Images
          </Button>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8">
          <Card className="bg-white dark:bg-gray-800 shadow-lg">
            <CardHeader>
              <CardTitle className="text-2xl font-semibold text-gray-800 dark:text-gray-200">
                All Locations
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-96 mb-6 rounded-lg overflow-hidden shadow-md">
                <MapContainer 
                  center={mapCenter} 
                  zoom={mapZoom} 
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <MapComponent />
                  {locations.map((location) => (
                    <Marker 
                      key={location.id} 
                      position={[location.latitude, location.longitude]} 
                      icon={location.status === 'approved' ? approvedIcon : pendingIcon}
                      eventHandlers={{
                        click: () => setSelectedLocation(location),
                      }}
                    >
                      <Popup>
                        <div className="text-sm">
                          <h3 className="font-bold text-lg mb-1">{location.title}</h3>
                          <p className="mb-2">{location.description}</p>
                          <p className={`text-xs font-semibold ${location.status === 'approved' ? 'text-green-600' : 'text-orange-600'}`}>
                            {location.status === 'approved' ? 'Approved' : 'Pending'}
                          </p>
                        </div>
                      </Popup>
                    </Marker>
                  ))}
                </MapContainer>
              </div>
              <div className="space-y-4 max-h-96 overflow-y-auto pr-2">
                {pendingLocations.length > 0 && (
                  <LocationsList
                    locations={pendingLocations}
                    title="Pending Locations"
                    onSelect={setSelectedLocation}
                    onApprove={handleApprove}
                    onReject={handleReject}
                  />
                )}
                {approvedLocations.length > 0 && (
                  <LocationsList
                    locations={approvedLocations}
                    title="Approved Locations"
                    onSelect={setSelectedLocation}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                )}
                {locations.length === 0 && <NoLocationsMessage />}
              </div>
            </CardContent>
          </Card>
          
          <AnimatePresence>
            {selectedLocation && (
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              >
                <Card className="bg-white dark:bg-gray-800 shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl font-semibold text-gray-800 dark:text-gray-200 flex items-center justify-between">
                      <span>{selectedLocation.title}</span>
                      <span className={`text-sm px-2 py-1 rounded-full ${
                        selectedLocation.status === 'pending' 
                          ? 'bg-orange-200 text-orange-800 dark:bg-orange-800 dark:text-orange-200' 
                          : 'bg-green-200 text-green-800 dark:bg-green-800 dark:text-green-200'
                      }`}>
                        {selectedLocation.status}
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p className="text-gray-600 dark:text-gray-400 mb-6">{selectedLocation.description}</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <LocationDetail icon={MapPin} label="Coordinates" value={`${selectedLocation.latitude.toFixed(6)}, ${selectedLocation.longitude.toFixed(6)}`} />
                      <LocationDetail icon={Camera} label="Best Equipment" value={selectedLocation.bestEquipment} />
                      <LocationDetail icon={SquareParking} label="Parking Information" value={selectedLocation.parkingInformation} />
                      <LocationDetail icon={Shield} label="Security Information" value={selectedLocation.securityInformation} />
                      <LocationDetail icon={Star} label="Community Rating" value={
                        <div className="flex">
                          {renderStars(selectedLocation.communityRating)}
                        </div>
                      } />
                      <LocationDetail icon={Info} label="Extra Information" value={selectedLocation.extraInformation} />
                      <LocationDetail icon={Clock} label="Created" value={new Date(selectedLocation.createdAt).toLocaleString()} />
                      <LocationDetail icon={Clock} label="Updated" value={new Date(selectedLocation.updatedAt).toLocaleString()} />
                      <LocationDetail icon={Plane} label="Airport ICAO" value={selectedLocation.airportICAO} />
                      <LocationDetail icon={Clock} label="Opening Hours" value={selectedLocation.openingHours} />
                    </div>
                    <div className="mt-8 flex justify-end space-x-4">
                      {selectedLocation.status === 'pending' ? (
                        <>
                          <Button onClick={() => handleApprove(selectedLocation.id)} className="bg-green-500 hover:bg-green-600 text-white">
                            <Check className="mr-2 h-4 w-4" />
                            Approve
                          </Button>
                          <Button onClick={() => handleReject(selectedLocation.id)} variant="destructive">
                            <X className="mr-2 h-4 w-4" />
                            Reject
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => handleEdit(selectedLocation.id)} variant="outline">
                            <Edit className="mr-2 h-4 w-4" />
                            Edit
                          </Button>
                          <Button onClick={() => handleDelete(selectedLocation.id)} variant="destructive">
                            <Trash2 className="mr-2 h-4 w-4" />
                            Delete
                          </Button>
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </main>
  
      <Footer />
    </div>
  );
};
export default AdminLocationsPage;