import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { User, Pencil, Plane } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
const AboutPage: React.FC = () => {
  const navigate = useNavigate();
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            About SpotterTools.org
          </motion.h1>
          
          <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700 dark:text-gray-300">
            Empowering aviation enthusiasts with cutting-edge tools and insights.
          </motion.p>

          <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <Pencil className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Our Mission</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    SpotterTools.org is dedicated to providing aviation enthusiasts and professionals with state-of-the-art tools for flight tracking, weather analysis, and runway prediction. Our goal is to enhance the aviation community's experience and knowledge through technology and data-driven insights.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
            
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <User className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">About the Creator</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    SpotterTools.org is the brainchild of a passionate aviation enthusiast who has been a plane spotter for years. Combining a love for aviation with software development skills, I've created this platform to share my expertise and provide valuable tools for the aviation community.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          <motion.h2 variants={itemVariants} className="text-3xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Key Features
          </motion.h2>

          <motion.ul variants={containerVariants} className="list-none space-y-4 mb-12">
            {[
              "Advanced runway prediction based on real-time weather data",
              "Comprehensive flight tracking and analysis tools",
              "In-depth aviation weather information",
              "High-quality aviation photography resources",
              "Continuous updates and new feature releases"
            ].map((feature, index) => (
              <motion.li key={index} variants={itemVariants} className="flex items-start">
                <Plane className="w-6 h-6 mr-3 text-blue-600 dark:text-blue-400 flex-shrink-0 mt-1" />
                <span className="text-gray-700 dark:text-gray-300">{feature}</span>
              </motion.li>
            ))}
          </motion.ul>
        </motion.div>
      </main>

        <Footer />
    </div>
  );
};

export default AboutPage;