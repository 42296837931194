import React from 'react';
import { useNavigate } from 'react-router-dom';

interface HyperlinkTextProps {
  text: string;
  className?: string; // Optional className prop
}

const HyperlinkText: React.FC<HyperlinkTextProps> = ({ text, className }) => {
  const navigate = useNavigate();

  const parseText = (input: string) => {
    const regex = /(\s*\[.*?\]\/[^\s]+\s*)|([^[\]]+)/g;
    const parts = input.match(regex) || [];

    return parts.map((part, index) => {
      const linkMatch = part.match(/\s*\[(.*?)\]\/([^\s]+)\s*/);
      if (linkMatch) {
        const [, linkText, path] = linkMatch;
        return (
          <React.Fragment key={index}>
            {'‎'}
            <span
              className="text-blue-500 hover:text-blue-700 cursor-pointer"
              onClick={() => navigate(`/${path}`)}
            >
              {linkText}
            </span>
            {'‎ '}
          </React.Fragment>
        );
      }
      return part;
    });
  };

  return <span className={className}>{parseText(text)}</span>;
};

export default HyperlinkText;