import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Wind, Compass, Clock, ChevronDown, ChevronUp, Thermometer, Cloud, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Slider } from '@/components/ui/slider';
import Header from '@/components/ui/header';
import RunwayDiagram from '@/components/RunwayDiagram';
import { format } from 'date-fns';
import Papa from 'papaparse';
import Footer from '@/components/ui/footer';
import MovementNotificationContainer from '@/components/MovementNotification';
import { useNavigate } from 'react-router-dom';

interface Runway {
  airport_ident: string;
  length_ft: number;
  width_ft: number;
  surface: string;
  lighted: number;
  closed: number;
  le_ident: string;
  le_latitude_deg: number;
  le_longitude_deg: number;
  le_elevation_ft: number;
  le_heading_degT: number;
  le_displaced_threshold_ft: number;
  he_ident: string;
  he_latitude_deg: number;
  he_longitude_deg: number;
  he_elevation_ft: number;
  he_heading_degT: number;
  he_displaced_threshold_ft: number;
}

interface Airport {
  name: string;
  runways: Runway[];
}

interface PredictedRunway {
  runway: string;
  score: number;
}

interface WeatherData {
  time: Date;
  windSpeed: number | null;
  windDirection: number | 'VRB'; // Can now be 'VRB' for variable
  windGusts: number | null;
  windVariation?: { from: number; to: number }; // For variable wind direction range
  visibility: string;
  temperature: number;
  dewpoint: number;
  cloudCover: string;
}

interface TAFForecast {
  time: Date;
  windSpeed: number;
  windDirection: number;
  windGusts: number | null;
  visibility: string;
  cloudCover: string;
  temperature: number;
  dewpoint: number;
}

interface ForecastPeriod extends TAFForecast {
  type: 'main' | 'tempo' | 'prob';
  probability?: number;
  endTime: Date;
}

const RunwayPredictionPage: React.FC = () => {
  const navigate = useNavigate();
  const [icao, setIcao] = useState('');
  const [airport, setAirport] = useState<Airport | null>(null);
  const [currentWeather, setCurrentWeather] = useState<WeatherData | null>(null);
  const [predictedRunways, setPredictedRunways] = useState<PredictedRunway[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [airportData, setAirportData] = useState<{ [key: string]: Airport }>({});
  const [, setTafData] = useState<TAFForecast[]>([]);
  const [, setSelectedTime] = useState<Date>(new Date());
  const [timeRange, setTimeRange] = useState<{ start: Date; end: Date } | null>(null);
  const [displayWeather, setDisplayWeather] = useState<WeatherData | null>(null);
  const [rawMetar, setRawMetar] = useState<string>('');
  const [rawTaf, setRawTaf] = useState<string>('');
  const [showRawData, setShowRawData] = useState(false);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [forecastPeriods, setForecastPeriods] = useState<ForecastPeriod[]>([]);

  useEffect(() => {
    const loadRunwayData = async () => {
      try {
        const response = await fetch('/data/runways.csv');
        const csvText = await response.text();
        const results = Papa.parse(csvText, { header: true, dynamicTyping: true });
        
        const airports: { [key: string]: Airport } = {};
        results.data.forEach((row: any) => {
          if (row.closed) return;
          if (!airports[row.airport_ident]) {
            airports[row.airport_ident] = { name: row.airport_ident, runways: [] };
          }
          airports[row.airport_ident].runways.push(row);
        });
        setAirportData(airports);
      } catch (err) {
        console.error('Failed to load runway data:', err);
        setError('Failed to load runway data');
      }
    };
  
    loadRunwayData();
  }, []);

  const parseWindData = (windInfo: any): Pick<WeatherData, 'windSpeed' | 'windDirection' | 'windGusts' | 'windVariation'> => {
    const windData: Pick<WeatherData, 'windSpeed' | 'windDirection' | 'windGusts' | 'windVariation'> = {
      windSpeed: windInfo.speed_kts,
      windDirection: windInfo.degrees === 'VRB' ? 'VRB' : Number(windInfo.degrees),
      windGusts: windInfo.gust_kts || null,
    };

    if (windInfo.degrees_from && windInfo.degrees_to) {
      windData.windVariation = {
        from: Number(windInfo.degrees_from),
        to: Number(windInfo.degrees_to)
      };
    }

    return windData;
  };

  const fetchWeatherData = async (icao: string): Promise<{ weather: WeatherData; rawMetar: string }> => {
    const url = `https://api.checkwx.com/metar/${icao}/decoded`;
    const response = await fetch(url, {
      headers: {
        'X-API-Key': 'd812596067f9426686eb46f3d4'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch weather data');
    }

    const data = await response.json();
    const weatherInfo = data.data[0];

    const windData = parseWindData(weatherInfo.wind);

    return {
      weather: {
        time: new Date(weatherInfo.observed),
        ...windData,
        visibility: weatherInfo.visibility.meters,
        temperature: weatherInfo.temperature.celsius,
        dewpoint: weatherInfo.dewpoint.celsius,
        cloudCover: weatherInfo.clouds.map((cloud: any) => cloud.text).join(', ')
      },
      rawMetar: weatherInfo.raw_text
    };
  };

  const processTAFData = (tafData: any): ForecastPeriod[] => {
    const periods: ForecastPeriod[] = [];

    tafData.forecast.forEach((forecast: any) => {
      const basePeriod: ForecastPeriod = {
        type: 'main',
        time: new Date(forecast.timestamp.from),
        endTime: new Date(forecast.timestamp.to),
        windSpeed: forecast.wind?.speed_kts || 0,
        windDirection: forecast.wind?.degrees || 0,
        windGusts: forecast.wind?.gust_kts || null,
        visibility: forecast.visibility?.meters || 'N/A',
        temperature: forecast.temperature?.celsius || 0,
        dewpoint: forecast.dewpoint?.celsius || 0,
        cloudCover: forecast.clouds?.map((cloud: any) => cloud.text).join(', ') || 'N/A'
      };

      periods.push(basePeriod);

      // Handle TEMPO and PROB periods
      if (forecast.change) {
        const changeType = forecast.change.indicator.code;
        const changePeriod: ForecastPeriod = {
          ...basePeriod,
          type: changeType === 'TEMPO' ? 'tempo' : 'prob',
          time: new Date(forecast.timestamp.from),
          endTime: new Date(forecast.timestamp.to),
          probability: forecast.change.probability,
          // Override with specific change conditions
          windSpeed: forecast.wind?.speed_kts || basePeriod.windSpeed,
          windDirection: forecast.wind?.degrees || basePeriod.windDirection,
          windGusts: forecast.wind?.gust_kts || basePeriod.windGusts,
          visibility: forecast.visibility?.meters || basePeriod.visibility,
          cloudCover: forecast.clouds?.map((cloud: any) => cloud.text).join(', ') || basePeriod.cloudCover
        };
        periods.push(changePeriod);
      }
    });

    return periods.sort((a, b) => a.time.getTime() - b.time.getTime());
  };

  const fetchTAFData = async (icao: string): Promise<{ forecasts: ForecastPeriod[]; rawTaf: string }> => {
    const url = `https://api.checkwx.com/taf/${icao}/decoded`;
    const response = await fetch(url, {
      headers: {
        'X-API-Key': 'd812596067f9426686eb46f3d4'
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch TAF data');
    }

    const data = await response.json();
    const tafInfo = data.data[0];

    return {
      forecasts: processTAFData(tafInfo),
      rawTaf: tafInfo.raw_text
    };
  };

  const interpolateWeatherData = (time: Date, before: WeatherData, after: WeatherData): WeatherData => {
    const t1 = before.time.getTime();
    const t2 = after.time.getTime();
    const t = time.getTime();
    const ratio = (t - t1) / (t2 - t1);

    // Handle variable wind directions
    let windDirection: number | 'VRB' = 'VRB';
    if (typeof before.windDirection === 'number' && typeof after.windDirection === 'number') {
      windDirection = interpolateAngle(before.windDirection, after.windDirection, ratio);
    } else if (before.windDirection === 'VRB' && after.windDirection === 'VRB') {
      windDirection = 'VRB';
    } else if (before.windDirection === 'VRB') {
      windDirection = after.windDirection;
    } else if (after.windDirection === 'VRB') {
      windDirection = before.windDirection;
    }

    return {
      time,
      windSpeed: interpolate(before.windSpeed, after.windSpeed, ratio),
      windDirection,
      windGusts: interpolate(before.windGusts, after.windGusts, ratio),
      windVariation: before.windVariation || after.windVariation,
      visibility: before.visibility,
      temperature: interpolate(before.temperature, after.temperature, ratio) ?? 0,
      dewpoint: interpolate(before.dewpoint, after.dewpoint, ratio) ?? 0,
      cloudCover: before.cloudCover
    };
  };

  const interpolate = (a: number | null, b: number | null, ratio: number): number | null => {
    if (a === null || b === null) return null;
    return a + (b - a) * ratio;
  };

  const interpolateAngle = (a: number, b: number, ratio: number): number => {
    const diff = (b - a + 540) % 360 - 180;
    return (a + diff * ratio + 360) % 360;
  };

  const predictRunways = (runways: Runway[], windDirection: number | 'VRB', windSpeed: number): PredictedRunway[] => {
    if (windDirection === 'VRB') {
      // For variable winds, consider all runways equally
      return runways.flatMap(runway => [
        { runway: runway.le_ident, score: windSpeed },
        { runway: runway.he_ident, score: windSpeed }
      ]).sort((a, b) => b.score - a.score).slice(0, 3);
    }
  
    const predictions = runways.flatMap(runway => {
      const headings = [runway.le_heading_degT, runway.he_heading_degT];
      const identifiers = [runway.le_ident, runway.he_ident];
      return headings.map((heading, index) => {
        const diff = Math.abs((heading - windDirection + 360) % 360);
        const crosswind = Math.abs(windSpeed * Math.sin((diff * Math.PI) / 180));
        const headwind = windSpeed * Math.cos((diff * Math.PI) / 180);
        return { 
          runway: identifiers[index], 
          baseIdent: typeof identifiers[index] === 'string' && identifiers[index] ? identifiers[index].slice(0, -1) : '',
          heading, 
          headwind, 
          crosswind, 
          score: headwind - crosswind * 0.5 // Prioritize headwind but penalize crosswind
        };
      });
    });
  
    predictions.sort((a, b) => b.score - a.score);
  
    interface CurrentGroup {
      headings: Set<number>;
      runways: string[];
      score: number;
    }
  
    const groupedPredictions: PredictedRunway[] = [];
    let currentGroup: CurrentGroup | null = null as CurrentGroup | null;
  
    predictions.forEach(pred => {
      if (!currentGroup || !currentGroup.headings.has(pred.heading) || !currentGroup.runways.some(r => r.slice(0, -1) === pred.baseIdent)) {
        if (currentGroup) {
          groupedPredictions.push({ runway: currentGroup.runways.join(' and '), score: currentGroup.score });
        }
        currentGroup = { headings: new Set([pred.heading]), runways: [pred.runway], score: pred.score };
      } else {
        currentGroup.runways.push(pred.runway);
      }
    });
  
    if (currentGroup) {
      groupedPredictions.push({ runway: currentGroup.runways.join(' and '), score: currentGroup.score });
    }
  
    return groupedPredictions.slice(0, 3); // Return top 3 predictions
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setAirport(null);
    setCurrentWeather(null);
    setDisplayWeather(null);
    setPredictedRunways([]);
    setTafData([]);
    setTimeRange(null);
    setRawMetar('');

    try {
      let airportInfo = airportData[icao.toUpperCase()];
      
      if (!airportInfo) {
        const matchingAirports = Object.entries(airportData).filter(([key]) => 
          key.toLowerCase().includes(icao.toLowerCase())
        );
        
        if (matchingAirports.length === 1) {
          airportInfo = matchingAirports[0][1];
        } else if (matchingAirports.length > 1) {
          throw new Error('Multiple airports found. Please provide a more specific ICAO code.');
        }
      }
  
      if (!airportInfo) throw new Error('Airport not found. Please check the ICAO code and try again.');
      setAirport(airportInfo);
  
      const { weather: weatherData, rawMetar } = await fetchWeatherData(icao.toUpperCase());
      setCurrentWeather(weatherData);
      setDisplayWeather(weatherData);
      setRawMetar(rawMetar);
  
      const { forecasts: tafForecasts, rawTaf } = await fetchTAFData(icao.toUpperCase());
      setForecastPeriods(tafForecasts);
      setTafData(tafForecasts.filter(f => f.type === 'main'));
      setRawTaf(rawTaf);
  
      const startTime = weatherData.time;
      const endTime = tafForecasts[tafForecasts.length - 1].endTime;
      setTimeRange({ start: startTime, end: endTime });
      setSelectedTime(startTime);
      setSliderValue(startTime.getTime());
  
      const predictions = predictRunways(airportInfo.runways, weatherData.windDirection, weatherData.windSpeed ?? 0);
      setPredictedRunways(predictions);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const getWeatherForTime = (time: Date): WeatherData => {
    if (time <= currentWeather!.time) {
      return currentWeather!;
    }

    let before = currentWeather!;
    let after = forecastPeriods[0];

    for (let i = 0; i < forecastPeriods.length; i++) {
      if (time < forecastPeriods[i].time) {
        before = i === 0 ? currentWeather! : forecastPeriods[i - 1];
        after = forecastPeriods[i];
        break;
      }
      if (i === forecastPeriods.length - 1) {
        before = forecastPeriods[i];
        after = forecastPeriods[i];
      }
    }

    if (before.time.getTime() === after.time.getTime()) {
      return after;
    }

    return interpolateWeatherData(time, before, after);
  };

  const handleTimeChange = (newValue: number[]) => {
    const updatedTime = new Date(newValue[0]);
    setSelectedTime(updatedTime);
    setSliderValue(newValue[0]);
    
    if (forecastPeriods.length > 0 && currentWeather) {
      const weatherData = getWeatherForTime(updatedTime);
      setDisplayWeather(weatherData);
      if (airport) {
        const predictions = predictRunways(airport.runways, weatherData.windDirection, weatherData.windSpeed ?? 0);
        setPredictedRunways(predictions);
      }
    }
  };

  useEffect(() => {
    if (timeRange && currentWeather) {
      setSliderValue(currentWeather.time.getTime());
    }
  }, [timeRange, currentWeather]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      <main className="container mx-auto px-4 pt-20 py-12">
        {/* Hero Section with Primary Gradient */}
        <motion.div
          className="text-center mb-16 relative"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <motion.div
            className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-full blur-3xl"
            animate={{ 
              scale: [1, 1.1, 1],
              rotate: [0, 5, 0]
            }}
            transition={{ 
              duration: 10,
              repeat: Infinity,
            }}
          />
          
          <h1 className="text-5xl md:text-6xl font-extrabold pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-400 dark:to-blue-500">
            Runway Prediction Tool
          </h1>
          
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto">
            Enter an airport's ICAO code to predict runway usage based on current weather conditions.
          </p>
        </motion.div>
        
        {/* Search Section */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="max-w-xl mx-auto mb-12"
        >
          <Card className="backdrop-blur-md bg-white/90 dark:bg-gray-800/90 shadow-lg">
            <CardContent className="p-6">
              <div className="flex space-x-4">
                <div className="relative flex-grow">
                  <Input
                    type="text"
                    placeholder="Enter airport ICAO"
                    value={icao}
                    onChange={(e) => setIcao(e.target.value.toUpperCase())}
                    className="pl-10 py-6 text-lg"
                  />
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
                <Button 
                  onClick={handleSearch} 
                  disabled={loading}
                  className="bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white px-8"
                >
                  {loading ? 'Searching...' : 'Predict'}
                </Button>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="max-w-xl mx-auto mb-8 text-red-500 text-center font-semibold"
            >
              {error}
            </motion.div>
          )}

          {/* Results Section */}
          {airport && displayWeather && predictedRunways.length > 0 && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="max-w-4xl mx-auto space-y-8"
            >
              {/* Weather Card */}
              <Card className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700">
                <CardContent className="p-8">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-2xl font-bold">{airport.name}</h2>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div>
                    <h3 className="text-xl font-semibold mb-4">Weather Conditions</h3>
                    <div className="space-y-2">
                      <p className="flex items-center">
                        <Wind className="mr-2 w-5 h-5" />
                        <span className="font-medium">Wind:</span>
                        <span className="ml-2">
                          {displayWeather.windSpeed !== null ? Math.round(displayWeather.windSpeed) : 'N/A'} kts
                          {displayWeather.windDirection === 'VRB' 
                            ? ' Variable' 
                            : ` @ ${Math.round(displayWeather.windDirection)}°`
                          }
                          {displayWeather.windVariation && 
                            ` (${displayWeather.windVariation.from}° to ${displayWeather.windVariation.to}°)`
                          }
                        </span>
                        {displayWeather.windGusts && <span className="ml-2">(Gusts {Math.round(displayWeather.windGusts)} kts)</span>}
                      </p>
                      <p className="flex items-center">
                        <Compass className="mr-2 w-5 h-5" /> 
                        <span className="font-medium">Visibility:</span> 
                        <span className="ml-2">{displayWeather.visibility}</span>
                      </p>
                      <p className="flex items-center">
                        <Thermometer className="mr-2 w-5 h-5" />
                        <span className="font-medium">Temperature:</span> 
                        <span className="ml-2">{displayWeather.temperature}°C</span>
                      </p>
                      <p className="flex items-center">
                        <Thermometer className="mr-2 w-5 h-5" />
                        <span className="font-medium">Dew Point:</span> 
                        <span className="ml-2">{displayWeather.dewpoint}°C</span>
                      </p>
                      <p className="flex items-center">
                        <Cloud className="mr-2 w-5 h-5" />
                        <span className="font-medium">Cloud Cover:</span> 
                        <span className="ml-2">{displayWeather.cloudCover}</span>
                      </p>
                      </div>
                    <h3 className="text-xl font-semibold mt-6 mb-4">Predicted Runways</h3>
                    <div className="space-y-2">
                      {predictedRunways.map((runway, index) => (
                        <p key={runway.runway} className={`text-lg ${index === 0 ? 'font-bold text-green-600 dark:text-green-400' : ''}`}>
                          {index + 1}. Runway{runway.runway.includes(' and ') ? 's' : ''} {runway.runway} 
                          <span className="ml-2 text-sm font-normal">(Score: {runway.score.toFixed(2)})</span>
                        </p>
                      ))}
                    </div>
                  </div>
                    <div className="flex justify-center items-center">
                    <RunwayDiagram 
                      runways={predictedRunways} 
                      windDirection={typeof displayWeather.windDirection === 'number' ? displayWeather.windDirection : 0}
                      airport={airport}
                      isVariableWind={displayWeather.windDirection === 'VRB'}
                    />
                    </div>
                </div>
              </CardContent>
            </Card>

              {/* Timeline Card */}
              {timeRange && (
                <Card className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm">
                  <CardContent className="p-6">
                  <h3 className="text-xl font-semibold mb-4">Forecast Time</h3>
                  <div className="flex items-center space-x-4">
                    <Clock className="w-6 h-6 text-gray-500" />
                    <Slider
                      min={timeRange.start.getTime()}
                      max={timeRange.end.getTime()}
                      step={900000} // 15 minutes in milliseconds
                      value={[sliderValue]}
                      onValueChange={handleTimeChange}
                      className="flex-grow"
                    />
                    <span className="text-sm font-medium whitespace-nowrap">
                      {format(new Date(sliderValue), 'MMM d, yyyy HH:mm')}
                    </span>
                  </div>
                </CardContent>
              </Card>
            )}

              {/* Raw Data Card */}
              <Card className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm">
                <CardContent className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold">Raw Weather Data</h3>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowRawData(!showRawData)}
                    className="flex items-center"
                  >
                    {showRawData ? 'Hide' : 'Show'}
                    {showRawData ? <ChevronUp className="ml-1" /> : <ChevronDown className="ml-1" />}
                  </Button>
                </div>
                <AnimatePresence>
                  {showRawData && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md mb-4">
                        <h4 className="font-semibold mb-2">METAR</h4>
                        <pre className="text-sm overflow-x-auto">{rawMetar}</pre>
                      </div>
                      <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md">
                        <h4 className="font-semibold mb-2">TAF</h4>
                        <pre className="text-sm overflow-x-auto">{rawTaf}</pre>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </main>
    <Footer />
  </div>
);
};

export default RunwayPredictionPage;