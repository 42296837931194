import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    onCheckedChange?: (checked: boolean) => void
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    ({ className = '', label, onCheckedChange, ...props }, ref) => {
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onCheckedChange) {
                onCheckedChange(event.target.checked)
            }
            if (props.onChange) {
                props.onChange(event)
            }
        }

        return (
            <label className="flex items-center cursor-pointer">
                <div className="relative">
                    <input
                        type="checkbox"
                        className="sr-only"
                        ref={ref}
                        onChange={handleChange}
                        {...props}
                    />
                    <motion.div
                        className={`w-5 h-5 bg-white dark:bg-gray-800 border-2 border-gray-300 dark:border-gray-600 rounded-md 
                        ${props.checked ? 'bg-blue-500 dark:bg-blue-600 border-blue-500 dark:border-blue-600' : ''}
                        shadow-sm transition-colors duration-200 ease-in-out ${className}`}
                        animate={props.checked ? "checked" : "unchecked"}
                        variants={{
                            checked: { scale: 1.05 },
                            unchecked: { scale: 1 }
                        }}
                        transition={{ duration: 0.2 }}
                    >
                        {props.checked && (
                            <motion.svg
                                className="w-4 h-4 text-black dark:text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                viewBox="0 0 24 24"
                                initial={{ pathLength: 0, opacity: 0 }}
                                animate={{ pathLength: 1, opacity: 1 }}
                                transition={{ duration: 0.3, ease: "easeOut" }}
                            >
                                <motion.path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={3}
                                    d="M5 13l4 4L19 7"
                                />
                            </motion.svg>
                        )}
                    </motion.div>
                </div>
                {label && (
                    <span className="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                        {label}
                    </span>
                )}
            </label>
        )
    }
)

Checkbox.displayName = 'Checkbox'

export { Checkbox }