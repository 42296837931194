import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Clock, Plane, Globe, Gauge, ArrowRight, Navigation,
  Filter, Map, List, SortAsc, SortDesc, RefreshCcw, 
  AlertCircle, History, Info, Share2, Check,
  ArrowUp, Wind,
} from 'lucide-react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Input } from '@/components/ui/input';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Toaster, toast } from 'react-hot-toast';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import Pager from '@/components/ui/Pager';

const commonAircraft = [
  { icao: 'A388', name: 'Airbus A380-800', description: 'Super Heavy Transport', maxAlt: 43100 },
  { icao: 'B738', name: 'Boeing 737-800', description: 'Popular Narrowbody', maxAlt: 41000 },
  { icao: 'A320', name: 'Airbus A320', description: 'Short/Medium Range', maxAlt: 39800 },
  { icao: 'B744', name: 'Boeing 747-400', description: 'Queen of the Skies', maxAlt: 45100 },
  { icao: 'B789', name: 'Boeing 787-9', description: 'Dreamliner', maxAlt: 43100 },
  { icao: 'B77W', name: 'Boeing 777-300ER', description: 'Long Range Widebody', maxAlt: 43100 },
  { icao: 'A35K', name: 'Airbus A350-1000', description: 'Ultra Long Range', maxAlt: 41450 },
  { icao: 'A339', name: 'Airbus A330-900', description: 'Modern Widebody', maxAlt: 41000 }
];

// Enhanced Aircraft Selector Component
const AircraftSelector = ({ aircraftType, setAircraftType }: { aircraftType: string, setAircraftType: (type: string) => void }) => (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8"
    >
      {commonAircraft.map((ac, index) => (
        <motion.button
          key={ac.icao}
          onClick={() => setAircraftType(ac.icao)}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: index * 0.05 }}
          className={`relative overflow-hidden rounded-xl p-4 text-left transition-all duration-300 ${
            aircraftType === ac.icao
              ? 'bg-blue-500 text-white shadow-lg'
              : 'bg-white dark:bg-gray-800 hover:bg-blue-50 dark:hover:bg-gray-700'
          }`}
        >
          <div className="absolute top-0 right-0 p-2">
            <Plane className={`h-6 w-6 ${
              aircraftType === ac.icao ? 'text-white' : 'text-blue-500'
            }`} />
          </div>
          <div>
            <div className="font-bold dark:text-white mb-1">{ac.name}</div>
            <div className="text-sm dark:text-white opacity-75">{ac.description}</div>
            <code className={`text-xs mt-2 inline-block px-2 py-1 rounded ${
              aircraftType === ac.icao
                ? 'bg-white/20'
                : 'bg-blue-100 dark:text-white dark:bg-blue-900'
            }`}>
              {ac.icao}
            </code>
          </div>
        </motion.button>
      ))}
    </motion.div>
);

const CustomAircraftInput = ({ setAircraftType }: { setAircraftType: (type: string) => void }) => {
    const [customIcao, setCustomIcao] = useState('');

    const handleCustomIcaoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomIcao(e.target.value.toUpperCase());
    };

    const handleCustomIcaoSubmit = () => {
        if (customIcao) {
            setAircraftType(customIcao);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleCustomIcaoSubmit();
        }
    };

    return (
        <div className="flex items-center space-x-4 mb-8">
            <Input
                placeholder="Enter custom ICAO code or type ALL to see all aircraft"
                value={customIcao}
                onChange={handleCustomIcaoChange}
                onKeyPress={handleKeyPress}
                className="bg-white dark:bg-gray-800"
            />
        </div>
    );
};

const AircraftTracker = () => {
  // Get aircraft type from URL parameter on initial load
  const getInitialAircraftType = () => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const aircraftParam = params.get('aircraft');
      return aircraftParam || 'A388'; // Default to A388 if no parameter
    }
    return 'A388';
  };

  // Update URL when aircraft type changes
  const updateAircraftType = (newType: string) => {
    setAircraftType(newType);
    if (typeof window !== 'undefined') {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.set('aircraft', newType);
      window.history.pushState({}, '', newUrl.toString());
    }
  };

  const [aircraftType, setAircraftType] = useState(getInitialAircraftType());
  const newUrl = new URL(window.location.href);
  newUrl.searchParams.set('aircraft', aircraftType);
  window.history.pushState({}, '', newUrl.toString());
  const [aircraft, setAircraft] = useState<Array<{
    callsign: string;
    departure: string;
    arrival: string;
    altitude: number;
    groundspeed: number;
    heading: number;
    aircraft_short: string;
    aircraft_faa: string;
    status: string;
    latitude: number;
    longitude: number;
  }>>([]);
  

  const [, setLoading] = useState(true);
  const [lastUpdate, setLastUpdate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('all');
  const [sortBy, setSortBy] = useState('altitude');
  const [sortOrder, setSortOrder] = useState('desc');
  const [viewMode, setViewMode] = useState('grid');
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [isSharing, setIsSharing] = useState(false);

  const filteredFlights = aircraft
    .filter(flight => {
      const matchesSearch = 
        flight.callsign.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flight.departure.toLowerCase().includes(searchTerm.toLowerCase()) ||
        flight.arrival.toLowerCase().includes(searchTerm.toLowerCase());
      
      if (filterBy === 'ground') return matchesSearch && flight.altitude < 1000;
      if (filterBy === 'air') return matchesSearch && flight.altitude >= 1000;
      return matchesSearch;
    })
    .sort((a, b) => {
      const sortValue = sortBy === 'altitude' ? 
        (a.altitude - b.altitude) : 
        (a.groundspeed - b.groundspeed);
      return sortOrder === 'asc' ? sortValue : -sortValue;
    });

  const paginatedFlights = filteredFlights.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredFlights.length / itemsPerPage);

  const fetchData = useCallback(async () => {
    try {
      setIsRefreshing(true);
      const response = await fetch('https://api.spottertools.org/api/vatsim/data');
      const data = await response.json();
      
      const filteredFlights = aircraftType === 'ALL' ? data.pilots : data.pilots.filter((pilot: any) => 
        pilot.aircraft_short?.includes(aircraftType) || 
        pilot.aircraft_faa?.includes(aircraftType)
      );

      const flights = filteredFlights.map((pilot: any) => ({
        callsign: pilot.callsign,
        departure: pilot.departure,
        arrival: pilot.arrival,
        altitude: pilot.altitude,
        groundspeed: pilot.groundspeed,
        heading: pilot.heading,
        aircraft_short: pilot.aircraft_short,
        aircraft_faa: pilot.aircraft_faa,
        status: pilot.status,
        latitude: pilot.latitude,
        longitude: pilot.longitude
      }));
      setAircraft(flights);
      setLastUpdate(new Date().toLocaleTimeString());
      setError(null);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch flight data. Please try again later.');
      console.error('Error:', err);
    } finally {
      setIsRefreshing(false);
    }
  }, [aircraftType]);

  const handleShare = async () => {
    setIsSharing(true);
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success('URL copied to clipboard');
    } catch (err) {
      toast.error('Failed to copy URL to clipboard');
    }
    await new Promise(resolve => setTimeout(resolve, 2000));
    setIsSharing(false);
  };


  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 15000);
    return () => clearInterval(interval);
  }, [fetchData]);
  
// Enhanced flight phase badge that uses altitude and groundspeed
const FlightPhaseBadge = ({ altitude, groundspeed }: { altitude: number; groundspeed: number }) => {
  const getPhaseInfo = () => {
    if (altitude < 1000) {
      return {
        text: groundspeed < 50 ? 'Parked' : 'Ground',
        color: 'bg-green-500',
        icon: <Plane className="h-4 w-4" />
      };
    }
    if (altitude < 10000) {
      return {
        text: 'Low Level',
        color: 'bg-blue-500',
        icon: <ArrowUp className="h-4 w-4" />
      };
    }
    return {
      text: 'Cruise',
      color: 'bg-indigo-600',
      icon: <Wind className="h-4 w-4" />
    };
  };

  const { text, color, icon } = getPhaseInfo();

  return (
    <motion.div 
      className={`flex items-center space-x-1 ${color} text-white px-3 py-1 rounded-full text-sm font-medium shadow-md`}
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      {icon}
      <span>{text}</span>
    </motion.div>
  );
};

// Enhanced Flight Card component
const FlightCard = ({ flight, aircraft }: { flight: any; aircraft: typeof commonAircraft[0] }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card 
      className="relative overflow-hidden transition-all duration-500 transform hover:scale-105"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-purple-500/5"
        animate={{ opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      />

      <CardContent className="p-6">
        <div className="flex justify-between">
          <div className="space-y-2">
            <h3 className="text-2xl font-bold text-blue-600 dark:text-blue-400">
              {flight.callsign}
            </h3>
            <div className="flex items-center space-x-2">
              <FlightPhaseBadge altitude={flight.altitude} groundspeed={flight.groundspeed} />
            </div>
          </div>
          
          <div className="flex items-start space-x-4">
            <div className="text-right">
              <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">
                FL{Math.floor(flight.altitude/100)}
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {flight.altitude.toLocaleString()}ft
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 flex items-center justify-between">
          <motion.div 
            className="flex items-center bg-gray-100 dark:bg-gray-800 rounded-full px-4 py-2"
            animate={{ 
              x: [0, 5, 0],
              transition: { duration: 3, repeat: Infinity }
            }}
          >
            <span className=" dark:text-white font-medium">{flight.departure || 'N/A'}</span>
            <ArrowRight className="mx-2 h-4 w-4 text-blue-500" />
            <span className=" dark:text-white font-medium">{flight.arrival || 'N/A'}</span>
          </motion.div>

          <motion.div
            className="p-3 bg-blue-500/10 rounded-full"
            style={{ transform: `rotate(${flight.heading}deg)` }}
            whileHover={{ scale: 1.2 }}
          >
            <Plane className="h-6 w-6 text-blue-500" />
          </motion.div>
        </div>

        <div className="mt-4 grid grid-cols-2 gap-4">
          <div className="flex items-center space-x-2 bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
            <Gauge className="h-5 w-5 text-blue-500" />
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400">Ground Speed</div>
              <div className="text-lg dark:text-white font-semibold">{flight.groundspeed} kts</div>
            </div>
          </div>

          <div className="flex items-center space-x-2 bg-gray-50 dark:bg-gray-800/50 rounded-lg p-3">
            <Navigation className="h-5 w-5 text-blue-500" />
            <div>
              <div className="text-sm text-gray-500 dark:text-gray-400">Heading</div>
              <div className="text-lg dark:text-white font-semibold">{flight.heading}°</div>
            </div>
          </div>
        </div>

        <div className="mt-4 pt-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex justify-between items-center">
            <div className="text-lg font-medium text-gray-900 dark:text-gray-100">
              {aircraft.name}
            </div>
            <code className="text-sm bg-blue-100 dark:bg-blue-900 px-2 py-1 rounded">
              {aircraft.icao}
            </code>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

return (
  <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900">
    <Toaster 
      position="bottom-right"
      toastOptions={{
        duration: 2000,
        style: {
          background: '#333',
          color: '#fff',
        },
        success: {
          iconTheme: {
            primary: '#4CAF50',
            secondary: '#fff',
          },
        },
        error: {
          iconTheme: {
            primary: '#F44336',
            secondary: '#fff',
          },
        },
      }}
    />
    <Header onNavigate={(page) => console.log(`Navigating to ${page}`)} />
    
    <main className="container mx-auto px-4 pt-20 py-8">
      <motion.div className="space-y-8">
        <div className="text-center space-y-4">
          <motion.h1 
            className="text-6xl font-extrabold"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <span className="text-blue-600 dark:text-blue-400">
              VATSIM Aircraft Tracker
            </span>
          </motion.h1>
          <CustomAircraftInput setAircraftType={updateAircraftType} />
          <AircraftSelector aircraftType={aircraftType} setAircraftType={updateAircraftType} />
        </div>

        <Card className="backdrop-blur-md bg-white/90 dark:bg-gray-800/90 shadow-2xl">
          <CardHeader>
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-4">
              <div className="flex items-center space-x-4">
                <motion.div 
                  whileHover={{ scale: 1.1, rotate: 360 }}
                  className="p-4 bg-blue-500/10 rounded-2xl"
                >
                  <Globe className="w-10 h-10 text-blue-500" />
                </motion.div>
                <div className="flex flex-col">
                  <span className="text-sm dark:text-white text-gray-500">Last updated</span>
                  <div className="flex dark:text-white items-center">
                    <Clock className="mr-2 h-4 w-4 dark:text-white text-blue-500" />
                    {lastUpdate}
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={fetchData}
                      disabled={isRefreshing}
                      className="ml-2"
                    >
                      <RefreshCcw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''} dark:text-white`} />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <motion.div
                  animate={{ scale: [1, 1.05, 1] }}
                  transition={{ duration: 2, repeat: Infinity }}
                  className="bg-blue-500 text-white px-8 py-4 rounded-2xl shadow-lg"
                >
                  <div className="flex items-center">
                    <Plane className="mr-3 h-8 w-8" />
                    <div>
                      <div className="text-3xl font-bold">{filteredFlights.length}</div>
                      <div className="text-sm opacity-90">
                        {aircraftType === 'ALL' ? 'Total Aircraft' : `Active ${aircraftType}'s`}
                      </div>
                    </div>
                  </div>
                </motion.div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleShare}
                  disabled={isSharing}
                  className="bg-white dark:bg-gray-700 text-blue-500 p-4 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-200 disabled:opacity-50"
                >
                  {isSharing ? (
                    <Check className="h-6 w-6 text-green-500" />
                  ) : (
                    <Share2 className="h-6 w-6" />
                  )}
                </motion.button>
              </div>
            </div>
          </CardHeader>

            <CardContent>
              {/* Controls */}
              <div className="space-y-4 mb-6">
                <div className="flex gap-4 flex-wrap">
                  <div className="flex-1">
                    <Input
                      placeholder="Search callsign, departure, or arrival..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="bg-white dark:bg-gray-800"
                    />
                  </div>
                  <div className="flex gap-2">
                    <Button
                      variant={viewMode === 'grid' ? 'default' : 'outline'}
                      onClick={() => setViewMode('grid')}
                    >
                      <Map className="mr-2 h-4 w-4" />
                      Grid
                    </Button>
                    <Button
                      variant={viewMode === 'list' ? 'default' : 'outline'}
                      onClick={() => setViewMode('list')}
                    >
                      <List className="mr-2 h-4 w-4" />
                      List
                    </Button>
                  </div>
                </div>

                <div className="flex flex-wrap gap-4">
                  <Tabs defaultValue={filterBy} onValueChange={setFilterBy}>
                    <TabsList className="bg-blue-100 dark:bg-gray-700 rounded-lg p-2">
                      <TabsTrigger value="all" className="data-[state=active]:bg-blue-500 data-[state=active]:text-white bg-blue-200 dark:bg-gray-800 hover:bg-blue-50 dark:hover:bg-gray-700 dark:text-white rounded-lg p-2 mx-1 dark:data-[state=active]:bg-blue-500 dark:data-[state=active]:text-white">
                        All Aircraft
                      </TabsTrigger>
                      <TabsTrigger value="ground" className="data-[state=active]:bg-blue-500 data-[state=active]:text-white bg-blue-200 dark:bg-gray-800 hover:bg-blue-50 dark:hover:bg-gray-700 dark:text-white rounded-lg p-2 mx-1 dark:data-[state=active]:bg-blue-500 dark:data-[state=active]:text-white">
                        On Ground
                      </TabsTrigger>
                      <TabsTrigger value="air" className="data-[state=active]:bg-blue-500 data-[state=active]:text-white bg-blue-200 dark:bg-gray-800 hover:bg-blue-50 dark:hover:bg-gray-700 dark:text-white rounded-lg p-2 mx-1 dark:data-[state=active]:bg-blue-500 dark:data-[state=active]:text-white">
                        In Air
                      </TabsTrigger>
                    </TabsList>
                  </Tabs>

                  <div className="flex gap-2 ml-auto">
                    <Button
                      variant="outline"
                      onClick={() => setSortBy(sortBy === 'altitude' ? 'speed' : 'altitude')}
                      className="bg-white dark:bg-gray-800"
                    >
                      <Filter className="mr-2 h-4 w-4" />
                      Sort: {sortBy === 'altitude' ? 'Altitude' : 'Speed'}
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                      className="bg-white dark:bg-gray-800"
                    >
                      {sortOrder === 'asc' ? 
                        <SortAsc className="h-4 w-4" /> : 
                        <SortDesc className="h-4 w-4" />
                      }
                    </Button>
                  </div>
                </div>
              </div>

              {/* Flight Cards Grid */}
              <AnimatePresence mode="wait">
                {error ? (
                  <Alert className="mb-6 bg-red-50 dark:bg-red-900/30 border-red-200 dark:border-red-800">
                    <AlertCircle className="h-4 w-4 text-red-500" />
                    <AlertDescription className="text-red-600 dark:text-red-400">{error}</AlertDescription>
                  </Alert>
                ) : filteredFlights.length === 0 ? (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="text-center py-12"
                  >
                    <Plane className="h-16 w-16 mx-auto text-blue-500 mb-4" />
                    <h3 className="text-xl font-semibold text-gray-600 dark:text-gray-400">
                      No {aircraftType} aircraft found
                    </h3>
                    <p className="text-gray-500 dark:text-gray-500 mt-2">
                      {searchTerm ? 'Try adjusting your search terms' : 'No matching aircraft are currently active in the network'}
                    </p>
                  </motion.div>
                ) : (
                  <div className="space-y-6">
                    <motion.div 
                      layout
                      className={`grid gap-6 ${
                        viewMode === 'grid' 
                          ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' 
                          : 'grid-cols-1'
                      }`}
                    >
                      {paginatedFlights.map((flight, index) => (
                        <motion.div
                          key={`${flight.callsign}-${index}`}
                          layout
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.9 }}
                          transition={{ duration: 0.3 }}
                        >
                          <FlightCard flight={flight} aircraft={commonAircraft.find(ac => ac.icao === aircraftType) || commonAircraft[0]} />
                        </motion.div>
                      ))}
                    </motion.div>

                    {filteredFlights.length > itemsPerPage && (
                      <Pager 
                        currentPage={currentPage} 
                        totalPages={totalPages} 
                        onPageChange={setCurrentPage}
                      />
                    )}
                  </div>
                )}
              </AnimatePresence>


              {/* Statistics Cards */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
                className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-6"
              >
                <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:text-white dark:from-blue-900/20 dark:to-blue-800/20">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold mb-2 flex items-center">
                      <Plane className="mr-2 h-5 w-5 text-blue-500" />
                      In Flight
                    </h3>
                    <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                      {filteredFlights.filter(f => f.altitude >= 1000).length}
                    </p>
                  </CardContent>
                </Card>

                <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:text-white dark:from-blue-900/20 dark:to-blue-800/20">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold mb-2 flex items-center">
                      <Gauge className="mr-2 h-5 w-5 text-blue-500" />
                      Average Speed
                    </h3>
                    <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                      {Math.round(
                        filteredFlights.reduce((acc, curr) => acc + curr.groundspeed, 0) / 
                        filteredFlights.length || 0
                      )} kts
                    </p>
                  </CardContent>
                </Card>

                <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:text-white dark:from-blue-900/20 dark:to-blue-800/20">
                  <CardContent className="p-6">
                    <h3 className="text-lg font-semibold mb-2 flex items-center">
                      <Globe className="mr-2 h-5 w-5 text-blue-500" />
                      Average Altitude
                    </h3>
                    <p className="text-3xl font-bold text-blue-600 dark:text-blue-400">
                      FL{Math.round(
                        filteredFlights
                          .filter(f => f.altitude >= 1000)
                          .reduce((acc, curr) => acc + curr.altitude, 0) / 
                        filteredFlights.filter(f => f.altitude >= 1000).length / 100
                      ) || 0}
                    </p>
                  </CardContent>
                </Card>
              </motion.div>

              {/* Status Legend */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
                className="mt-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700"
              >
                <div className="flex dark:text-white items-center mb-4">
                  <Info className="h-5 w-5 text-blue-500 mr-2" />
                  <h3 className="text-lg font-semibold">Flight Status Guide</h3>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="flex items-center dark:text-white space-x-2">
                    <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                    <span>Ground Operations</span>
                  </div>
                  <div className="flex items-center dark:text-white space-x-2">
                    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
                    <span>Low Altitude Flight</span>
                  </div>
                  <div className="flex items-center dark:text-white space-x-2">
                    <div className="w-4 h-4 bg-blue-700 rounded-full"></div>
                    <span>High Altitude Flight</span>
                  </div>
                </div>
              </motion.div>

              {/* Auto-update Notice */}
              <motion.div 
                className="mt-6 text-center text-sm text-gray-500 dark:text-gray-400"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                <div className="flex items-center justify-center p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                  <History className="h-4 w-4 mr-2 text-blue-500" />
                  <span>Data automatically updates every 15 seconds</span>
                </div>
              </motion.div>
            </CardContent>
          </Card>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default AircraftTracker;