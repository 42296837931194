import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, AlertTriangle, Calendar, Copy, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import HyperlinkText from '@/components/ui/HyperLinkText';

interface Movement {
  id: string;
  type: string;
  title: string;
  details: string;
  date: string;
  airportIcao: string;
  imageUrl?: string;
}

interface MovementDetailsPageProps {
  movement: Movement;
  onClose: () => void;
}

const MovementDetailsPage: React.FC<MovementDetailsPageProps> = ({ movement, onClose }) => {
  const [isImageEnlarged, setIsImageEnlarged] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 500
      }
    },
    exit: { 
      opacity: 0, 
      y: 50,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 500
      }
    }
  };

  const imageVariants = {
    normal: { scale: 1 },
    enlarged: { scale: 1.5, transition: { duration: 0.3 } }
  };

  const popupVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  const handleCopyLink = () => {
    const shareUrl = `${window.location.origin}/movement/${movement.id}`;
    navigator.clipboard.writeText(shareUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center p-4 backdrop-blur-sm"
      onClick={onClose}
    >
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="w-full max-w-4xl"
        onClick={(e) => e.stopPropagation()}
      >
        <Card className={`overflow-hidden shadow-2xl ${
          movement.type === 'important'
            ? 'border-l-8 border-red-500 bg-gradient-to-br from-red-50 to-white dark:from-red-900/30 dark:to-gray-800'
            : 'border-l-8 border-blue-500 bg-gradient-to-br from-blue-50 to-white dark:from-blue-900/30 dark:to-gray-800'
        }`}>
          <CardContent className="p-8">
            <div className="flex justify-between items-start mb-6">
              <motion.div 
                className="flex items-center"
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                {movement.type === 'important' ? (
                  <AlertTriangle className="text-red-500 dark:text-red-400 mr-3 h-10 w-10" />
                ) : (
                  <Plane className="text-blue-500 dark:text-blue-400 mr-3 h-10 w-10" />
                )}
                <h2 className="text-3xl font-bold">{movement.title}</h2>
              </motion.div>
            </div>
            
            <motion.div 
              className="mb-6"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <div className="flex items-center text-gray-600 dark:text-gray-300 mb-2">
                <Calendar className="mr-2 h-5 w-5" />
                <span>{movement.date}</span>
              </div>
              <div className="flex items-center text-gray-600 dark:text-gray-300">
                <Plane className="mr-2 h-5 w-5" />
                <Link 
                  to={`/airport/${movement.airportIcao}`}
                  className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
                >
                  {movement.airportIcao}
                </Link>
              </div>
            </motion.div>
            
            <motion.div 
              className="prose dark:prose-invert max-w-none mb-6"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <HyperlinkText text={movement.details} />
            </motion.div>

            {movement.imageUrl && (
              <motion.div 
                className="mb-6 cursor-pointer"
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.5 }}
                onClick={() => setIsImageEnlarged(!isImageEnlarged)}
              >
                <motion.img 
                  src={movement.imageUrl} 
                  alt={movement.title}
                  className="w-full h-auto rounded-lg shadow-lg"
                  variants={imageVariants}
                  animate={isImageEnlarged ? "enlarged" : "normal"}
                />
              </motion.div>
            )}
            
            <motion.div 
              className="mt-8 flex justify-between items-center"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <div className="relative">
                <Button variant="outline" onClick={handleCopyLink}>
                  {isCopied ? (
                    <Check className="mr-2 h-4 w-4" />
                  ) : (
                    <Copy className="mr-2 h-4 w-4" />
                  )}
                  {isCopied ? 'Copied!' : 'Copy Link'}
                </Button>
                <AnimatePresence>
                  {isCopied && (
                    <motion.div
                      variants={popupVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-4 py-2 bg-green-500 text-white rounded-md shadow-lg"
                    >
                      Copied to clipboard!
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <Button onClick={onClose}>Close</Button>
            </motion.div>
          </CardContent>
        </Card>
      </motion.div>
    </motion.div>
  );
};

export default MovementDetailsPage;