import React, { createContext, useContext, useState } from 'react';
import { cn } from '@/lib/utils';

interface TabsContextType {
  activeTab: string;
  setActiveTab: (value: string) => void;
}

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultValue: string;
  onValueChange?: (value: string) => void;
}

export const Tabs = ({ children, defaultValue, onValueChange, ...props }: TabsProps) => {
  const [activeTab, setActiveTabState] = useState(defaultValue);

  const setActiveTab = (value: string) => {
    setActiveTabState(value);
    if (onValueChange) {
      onValueChange(value);
    }
  };

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div {...props}>{children}</div>
    </TabsContext.Provider>
  );
};

export const TabsList = ({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    role="tablist"
    className={cn(
      "flex p-1 bg-gray-100 dark:bg-gray-800 rounded-lg",
      props.className
    )}
    {...props}
  >
    {children}
  </div>
);

export const TabsTrigger = ({ children, value, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement> & { value: string }) => {
  const context = useContext(TabsContext);
  if (!context) throw new Error('TabsTrigger must be used within a Tabs component');

  const { activeTab, setActiveTab } = context;

  return (
    <button
      role="tab"
      aria-selected={activeTab === value}
      data-state={activeTab === value ? 'active' : 'inactive'}
      onClick={() => setActiveTab(value)}
      className={cn(
        "flex-1 px-3 py-2 text-sm font-medium rounded-md transition-all duration-200 ease-in-out",
        activeTab === value
          ? "bg-white dark:bg-gray-700 text-blue-600 dark:text-blue-400 shadow-sm"
          : "text-gray-600 dark:text-gray-400 hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-gray-900 dark:hover:text-gray-100"
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const TabsContent = ({ children, value, ...props }: React.HTMLAttributes<HTMLDivElement> & { value: string }) => {
  const context = useContext(TabsContext);
  if (!context) throw new Error('TabsContent must be used within a Tabs component');

  const { activeTab } = context;

  if (activeTab !== value) return null;

  return (
    <div
      role="tabpanel"
      data-state={activeTab === value ? 'active' : 'inactive'}
      className={cn("mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2", props.className)}
      {...props}
    >
      {children}
    </div>
  );
};