import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, Moon, Sun, Menu, X, User } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';

interface HeaderProps {
    onNavigate: (page: 'home' | 'guide' | 'tools' | 'account') => void;
}

const Header: React.FC<HeaderProps> = ({ onNavigate }) => {
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        return savedMode !== null ? JSON.parse(savedMode) : window.matchMedia('(prefers-color-scheme: dark)').matches;
    });
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (darkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
        
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
        
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        if (favicon) {
            favicon.href = darkMode 
                ? 'https://spottertools.org/dark-favicon.ico'
                : 'https://spottertools.org/light-favicon.ico';
        }
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode((prev: any) => !prev);
    };

    const menuItems = ['Home', 'Tools', 'Guide'];

    const handleProfileClick = () => {
        const token = localStorage.getItem('token');
        navigate(token ? '/profile' : '/account');
    };

    return (
        <motion.header 
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            className={`fixed top-0 w-full z-50 transition-all duration-500 ${darkMode ? 'dark' : ''}`}
        >
            <div 
                className="absolute inset-0 bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg transition-colors duration-500"
            />
        
            <div className="container mx-auto px-6 py-4 relative">
                <div className="flex justify-between items-center">
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        className="flex items-center"
                    >
                        <Link to="/" onClick={() => onNavigate('home')} 
                            className="flex items-center group"
                        >
                            <motion.div
                                className="relative"
                            >
                                <Plane 
                                    className="w-8 h-8 text-blue-500 dark:text-blue-400 transform 
                                             group-hover:text-blue-700 dark:group-hover:text-blue-300 
                                             transition-colors duration-300" 
                                />
                                <motion.div
                                    className="absolute inset-0 bg-blue-500/20 rounded-full blur-lg"
                                    animate={{ scale: [1, 1.2, 1] }}
                                    transition={{ duration: 2, repeat: Infinity }}
                                />
                            </motion.div>
                            <span className="hidden sm:block ml-3 text-2xl font-extrabold text-transparent bg-clip-text
                                         bg-gradient-to-r from-blue-500 to-blue-700 dark:from-blue-400 dark:to-blue-600">
                                SpotterTools.Org
                            </span>
                        </Link>
                    </motion.div>

                    <nav className="hidden md:flex items-center space-x-8">
                        {menuItems.map((item, index) => (
                            <motion.div
                                key={item}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                            >
                                <Link
                                    to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                                    onClick={() => onNavigate(item.toLowerCase() as 'home' | 'guide' | 'tools')}
                                    className="relative text-lg font-semibold text-gray-700 dark:text-gray-300 
                                             hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300"
                                >
                                    {item}
                                    <motion.span
                                        className="absolute left-0 bottom-0 w-full h-0.5 bg-gradient-to-r 
                                                 from-blue-500 to-blue-700 dark:from-blue-400 dark:to-blue-600"
                                        initial={{ scaleX: 0 }}
                                        whileHover={{ scaleX: 1 }}
                                        transition={{ duration: 0.3 }}
                                    />
                                </Link>
                            </motion.div>
                        ))}

                        <div className="flex items-center space-x-4">
                            <motion.div
                                transition={{ duration: 0.3 }}
                            >
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={toggleDarkMode}
                                    className="relative"
                                >
                                    <AnimatePresence mode="wait">
                                        <motion.div
                                            key={darkMode ? 'dark' : 'light'}
                                            initial={{ scale: 0, rotate: -180 }}
                                            animate={{ scale: 1, rotate: 0 }}
                                            exit={{ scale: 0, rotate: 180 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            {darkMode ? (
                                                <Sun className="h-5 w-5 text-yellow-500" />
                                            ) : (
                                                <Moon className="h-5 w-5 text-gray-700" />
                                            )}
                                        </motion.div>
                                    </AnimatePresence>
                                </Button>
                            </motion.div>

                            <Button
                                variant="outline"
                                onClick={handleProfileClick}
                                className="bg-gradient-to-r from-blue-500 to-blue-700 text-white border-none
                                         hover:from-blue-600 hover:to-blue-800 transition-all duration-300
                                         shadow-md hover:shadow-lg"
                            >
                                <User className="mr-2 h-4 w-4" />
                                Profile
                            </Button>
                        </div>
                    </nav>

                    {/* Mobile menu button */}
                    <div className="md:hidden flex items-center space-x-2">
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={toggleDarkMode}
                            className="relative"
                        >
                            {darkMode ? (
                                <Sun className="h-5 w-5 text-yellow-500" />
                            ) : (
                                <Moon className="h-5 w-5 text-gray-700" />
                            )}
                        </Button>
                        <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={mobileMenuOpen ? 'open' : 'closed'}
                                    initial={{ scale: 0, rotate: -180 }}
                                    animate={{ scale: 1, rotate: 0 }}
                                    exit={{ scale: 0, rotate: 180 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    {mobileMenuOpen ? (
                                        <X className="h-6 w-6 text-gray-700 dark:text-gray-300" />
                                    ) : (
                                        <Menu className="h-6 w-6 text-gray-700 dark:text-gray-300" />
                                    )}
                                </motion.div>
                            </AnimatePresence>
                        </Button>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {mobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                        className="md:hidden border-t border-gray-200 dark:border-gray-700 bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg"
                    >
                        <nav className="container mx-auto px-6 py-4 flex flex-col space-y-4">
                            {menuItems.map((item, index) => (
                                <motion.div
                                    key={item}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ delay: index * 0.1 }}
                                >
                                    <Link
                                        to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
                                        onClick={() => {
                                            onNavigate(item.toLowerCase() as 'home' | 'guide' | 'tools');
                                            setMobileMenuOpen(false);
                                        }}
                                        className="block text-lg font-semibold text-gray-700 dark:text-gray-300 
                                                 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300"
                                    >
                                        {item}
                                    </Link>
                                </motion.div>
                            ))}
                            <motion.div
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: menuItems.length * 0.1 }}
                            >
                                <Button
                                    onClick={() => {
                                        handleProfileClick();
                                        setMobileMenuOpen(false);
                                    }}
                                    className="w-full bg-gradient-to-r from-blue-500 to-blue-700 text-white
                                             hover:from-blue-600 hover:to-blue-800 transition-all duration-300"
                                >
                                    <User className="mr-2 h-4 w-4" />
                                    Profile
                                </Button>
                            </motion.div>
                        </nav>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.header>
    );
};

export default Header;