import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from "@/components/ui/button";

interface PagerProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const Pager = ({ currentPage, totalPages, onPageChange }: PagerProps) => {
    const getVisiblePages = () => {
        const pages = [];
        const showEllipsisStart = currentPage > 3;
        const showEllipsisEnd = currentPage < totalPages - 2;

        if (totalPages <= 5) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        if (currentPage <= 3) {
            pages.push(1, 2, 3);
            if (showEllipsisEnd) pages.push('...', totalPages);
        } else if (currentPage >= totalPages - 2) {
            if (showEllipsisStart) pages.push(1, '...');
            pages.push(totalPages - 2, totalPages - 1, totalPages);
        } else {
            pages.push(1, '...');
            pages.push(currentPage - 1, currentPage, currentPage + 1);
            pages.push('...', totalPages);
        }

        return pages;
    };

    return (
        <div className="flex items-center justify-center space-x-2 mt-4">
            <Button
                variant="outline"
                size="icon"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                aria-label="Previous page"
            >
                <ChevronLeft className="h-4 w-4" />
            </Button>

            <div className="flex items-center space-x-1">
                {getVisiblePages().map((page, index) => (
                    typeof page === 'number' ? (
                        <motion.button
                            key={page}
                            onClick={() => onPageChange(page)}
                            className={`px-3 py-1 dark:text-white rounded-md ${
                                page === currentPage 
                                    ? 'bg-blue-500 text-white dark:text-white' 
                                    : 'hover:bg-gray-100 dark:hover:bg-gray-800'
                            }`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {page}
                        </motion.button>
                    ) : (
                        <span key={`ellipsis-${index}`} className="px-2 dark:text-white ">
                            •••
                        </span>
                    )
                ))}
            </div>

            <Button
                variant="outline"
                size="icon"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                aria-label="Next page"
            >
                <ChevronRight className="h-4 w-4" />
            </Button>
        </div>
    );
};

export default Pager;