import React from 'react';

interface Runway {
  airport_ident: string;
  length_ft: number;
  width_ft: number;
  surface: string;
  lighted: number;
  closed: number;
  le_ident: string;
  le_latitude_deg: number;
  le_longitude_deg: number;
  le_elevation_ft: number;
  le_heading_degT: number;
  le_displaced_threshold_ft: number;
  he_ident: string;
  he_latitude_deg: number;
  he_longitude_deg: number;
  he_elevation_ft: number;
  he_heading_degT: number;
  he_displaced_threshold_ft: number;
}

interface Airport {
  name: string;
  runways: Runway[];
}

interface PredictedRunway {
  runway: string;
  score: number;
}

const RunwayDiagram: React.FC<{
  runways: PredictedRunway[],
  windDirection: number,
  airport: Airport,
  isVariableWind: boolean
}> = ({ runways, windDirection, airport, isVariableWind }) => {
  const size = 400;
  const center = size / 2;
  const compassSize = size * 0.9;

  // Remove duplicates from runways array
  const uniqueRunways = Array.from(new Set(runways.map(r => r.runway)))
    .map(runway => runways.find(r => r.runway === runway)!);

  // Sort runways by score in descending order to get the highest scored runway first
  const sortedRunways = [...uniqueRunways].sort((a, b) => b.score - a.score);

  // Calculate the average latitude and longitude of all runways
  const avgLat = airport.runways.reduce((sum, r) => sum + (r.le_latitude_deg + r.he_latitude_deg) / 2, 0) / airport.runways.length;
  const avgLon = airport.runways.reduce((sum, r) => sum + (r.le_longitude_deg + r.he_longitude_deg) / 2, 0) / airport.runways.length;

  // Function to calculate distance between two points (in degrees)
  const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  // Find the runway furthest from the average point
  const maxDistance = Math.max(...airport.runways.map(r =>
    distance(avgLat, avgLon, (r.le_latitude_deg + r.he_latitude_deg) / 2, (r.le_longitude_deg + r.he_longitude_deg) / 2)
  ));

  // Adjust scale factor to keep runways closer together
  const scale = maxDistance > 0 ? (size * 0.4) / maxDistance : 1;

  // Find the longest runway to use as a reference for scaling runway lengths
  const longestRunway = Math.max(...airport.runways.map(r => r.length_ft));
  const runwayLengthScale = longestRunway > 0 ? (size * 0.4) / longestRunway : 1;

  // Group runways by their heading
  const runwayGroups = airport.runways.reduce((groups, runway) => {
    const heading = Math.round(runway.le_heading_degT / 10) * 10; // Round to nearest 10 degrees
    if (!groups[heading]) groups[heading] = [];
    groups[heading].push(runway);
    return groups;
  }, {} as Record<number, Runway[]>);

  const renderWindIndicator = () => {
    if (isVariableWind) {
      // Render a circular arrow for variable winds
      const radius = size / 4;
      const startAngle = -Math.PI / 2;
      const endAngle = Math.PI * 3 / 2;
      const x1 = center + radius * Math.cos(startAngle);
      const y1 = center + radius * Math.sin(startAngle);
      const x2 = center + radius * Math.cos(endAngle);
      const y2 = center + radius * Math.sin(endAngle);

      return (
        <g>
          <path
            d={`M ${x1} ${y1} A ${radius} ${radius} 0 1 1 ${x2} ${y2}`}
            fill="none"
            stroke="#ED8936"
            strokeWidth="3"
            markerEnd="url(#arrowhead)"
          />
          <text
            x={center}
            y={center + radius + 20}
            textAnchor="middle"
            fill="#ED8936"
            fontSize="14"
            fontWeight="bold"
          >
            Variable Wind
          </text>
        </g>
      );
    } else {
      // Render the original wind direction arrow
      return (
        <g>
          <line
            x1={center}
            y1={center}
            x2={center - Math.sin(windDirection * Math.PI / 180) * (size / 2 - 20)}
            y2={center + Math.cos(windDirection * Math.PI / 180) * (size / 2 - 20)}
            stroke="#ED8936"
            strokeWidth="3"
            markerEnd="url(#arrowhead)"
          />
          <text
            x={center - Math.sin(windDirection * Math.PI / 180) * (size / 2 - 40)}
            y={center + Math.cos(windDirection * Math.PI / 180) * (size / 2 - 40)}
            textAnchor="middle"
            dominantBaseline="central"
            fill="#ED8936"
            fontSize="14"
            fontWeight="bold"
          >
            
          </text>
        </g>
      );
    }
  };

  return (
    <svg width={size + 20} height={size + 20} viewBox={`0 0 ${size + 20} ${size + 20}`}>
      {/* Compass Rose */}
      <circle cx={center} cy={center} r={compassSize / 2} fill="none" stroke="#718096" strokeWidth="1" />
      {['N', 'E', 'S', 'W'].map((dir, i) => {
        const angle = i * 90;
        const x = center + Math.sin(angle * Math.PI / 180) * (compassSize / 2 - 15);
        const y = center - Math.cos(angle * Math.PI / 180) * (compassSize / 2 - 15);
        return <text key={dir} x={x} y={y} textAnchor="middle" dominantBaseline="central" fill="#718096" fontSize="14">{dir}</text>;
      })}

      {/* Runways */}
      {Object.values(runwayGroups).map((group, groupIndex) => {
        return group.map((runway, runwayIndex) => {
          const midLat = (runway.le_latitude_deg + runway.he_latitude_deg) / 2;
          const midLon = (runway.le_longitude_deg + runway.he_longitude_deg) / 2;
          const dx = (midLon - avgLon) * scale;
          const dy = (midLat - avgLat) * scale;
          const x = center + dx;
          const y = center + dy; // Flipped
          const length = runway.length_ft * runwayLengthScale;
          const angle = runway.le_heading_degT * (Math.PI / 180);

            // Offset parallel runways
            const offset = (runwayIndex - (group.length - 1) / 2) * 24;
            const offsetX = Math.cos(angle) * offset;
            const offsetY = Math.sin(angle) * offset;
            const highestScoredRunway = sortedRunways[0];

            let leColor;
            let heColor;

            let fixedle = String(runway.le_ident).replace(/\s+$/, "");
            let fixedhe = String(runway.he_ident).replace(/\s+$/, "");

            if (highestScoredRunway.runway.includes(fixedhe)) {
              heColor = "#38A169"; // Green color for predicted runway
              leColor = "#A0AEC0";
            } 
            else
            if (highestScoredRunway.runway.includes(fixedle)) {
              leColor = "#38A169"; // Green color for predicted runway
              heColor = "#A0AEC0";
            } 
            else {
            leColor = "#A0AEC0"; // Default color for non-predicted runways
            heColor = "#A0AEC0"; // Default color for non-predicted runways
            }
            // Default color for runway lines
            const lineColor = "#4299E1"; // Blue color

          return (
            <g key={runway.le_ident}>
              <line
                x1={x - Math.sin(angle) * length / 2 + offsetX}
                y1={y + Math.cos(angle) * length / 2 + offsetY} // Flipped
                x2={x + Math.sin(angle) * length / 2 + offsetX}
                y2={y - Math.cos(angle) * length / 2 + offsetY} // Flipped
                stroke={lineColor}
                strokeWidth={4}
                strokeLinecap="round"
              />
              <text
                x={x - Math.sin(angle) * (length / 2 + 15) + offsetX}
                y={y + Math.cos(angle) * (length / 2 + 15) + offsetY} // Flipped
                textAnchor="middle"
                dominantBaseline="central"
                fill={leColor}
                fontSize="12"
                fontWeight="bold"
              >
                {runway.le_ident}
              </text>
              <text
                x={x + Math.sin(angle) * (length / 2 + 15) + offsetX}
                y={y - Math.cos(angle) * (length / 2 + 15) + offsetY} // Flipped
                textAnchor="middle"
                dominantBaseline="central"
                fill={heColor}
                fontSize="12"
                fontWeight="bold"
              >
                {runway.he_ident}
              </text>
            </g>
          );
        });
      })}

        {/* Wind Indicator */}
        {renderWindIndicator()}

      {/* Wind Direction Arrow */}
      <line
        x1={center}
        y1={center}
        x2={center - Math.sin(windDirection * Math.PI / 180) * (size / 2 - 20)}
        y2={center + Math.cos(windDirection * Math.PI / 180) * (size / 2 - 20)} // Flipped
        stroke="#ED8936"
        strokeWidth="3"
        markerEnd="url(#arrowhead)"
      />
      <defs>
        <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
          <polygon points="0 0, 10 3.5, 0 7" fill="#ED8936" />
        </marker>
      </defs>
    </svg>
  );
};

export default RunwayDiagram;
