import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Search, Plane, ArrowRight, AlertCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import MovementNotificationContainer from '@/components/MovementNotification';

const AirportLookupPage = () => {
  const [icao, setIcao] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!icao) {
      setError('Please enter an ICAO code');
      return;
    }

    const formattedIcao = icao.toUpperCase().trim();
    if (formattedIcao.length !== 4) {
      setError('ICAO code must be exactly 4 characters');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      navigate(`/airport/${formattedIcao}`);
    } catch (err) {
      setError('Error navigating to airport page');
      setIsLoading(false);
    }
  };

  // Animation variants following brand guidelines
  const pageTransitionVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const elementVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        duration: 0.3
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-500/5 via-white to-blue-500/5 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      
      <main className="container mx-auto px-4 py-16">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={pageTransitionVariants}
          className="max-w-2xl mx-auto"
        >
          <motion.div variants={elementVariants} className="text-center mb-12">
          <h1 className="text-5xl md:text-6xl font-extrabold pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-400 dark:to-blue-500">
              Airport Lookup
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-400 font-normal">
              Enter an airport's ICAO code to view detailed information
            </p>
          </motion.div>

          <motion.div variants={elementVariants}>
            <Card className="backdrop-blur-sm bg-white/90 dark:bg-gray-800/90 border-2 border-gray-100 dark:border-gray-700 shadow-lg hover:shadow-xl transition-shadow duration-300">
              <CardContent className="p-6">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="relative">
                    <Input
                      type="text"
                      value={icao}
                      onChange={(e) => {
                        setIcao(e.target.value.toUpperCase());
                        setError(null);
                      }}
                      placeholder="Enter ICAO code (e.g., EGLL)"
                      className="pl-12 py-6 text-lg bg-transparent border-2 border-gray-200 dark:border-gray-700 focus:border-blue-500 dark:focus:border-blue-400 transition-colors"
                      maxLength={4}
                    />
                    <Plane className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-blue-500 dark:text-blue-400" />
                  </div>

                  {error && (
                    <Alert className="bg-red-50 dark:bg-red-900/30 border-red-300 dark:border-red-700">
                      <AlertCircle className="h-5 w-5 text-red-500" />
                      <AlertDescription className="text-red-600 dark:text-red-400">{error}</AlertDescription>
                    </Alert>
                  )}

                  <Button
                    type="submit"
                    className="w-full py-6 text-lg bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white transition-all duration-300 transform hover:scale-[1.02]"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Search className="mr-2 h-5 w-5 animate-spin" />
                    ) : (
                      <Search className="mr-2 h-5 w-5" />
                    )}
                    {isLoading ? 'Searching...' : 'Look Up Airport'}
                  </Button>
                </form>

                <div className="mt-8">
                  <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
                    Popular Airports
                  </h2>
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {['KJFK', 'EGLL', 'EHAM', 'YSSY', 'RJTT', 'EDDF'].map((code) => (
                      <Button
                        key={code}
                        variant="outline"
                        onClick={() => navigate(`/airport/${code}`)}
                        className="flex items-center justify-between group bg-white dark:bg-gray-800 hover:bg-blue-50 dark:hover:bg-blue-900/20 border-2 transition-all duration-300"
                      >
                        <span className="font-semibold">{code}</span>
                        <ArrowRight className="h-4 w-4 ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
                      </Button>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>

            <motion.div
              variants={elementVariants}
              className="mt-8 text-center text-gray-600 dark:text-gray-400"
            >
              <p>
                Not sure about the ICAO code? Visit{' '}
                <a
                  href="https://www.world-airport-codes.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300 font-medium"
                >
                  World Airport Codes
                </a>
                {' '}to look it up.
              </p>
            </motion.div>
          </motion.div>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default AirportLookupPage;