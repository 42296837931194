import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { useNavigate } from 'react-router-dom';
import MovementNotificationContainer from '@/components/MovementNotification';

const ContactPage: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 via-purple-50 to-pink-50 dark:from-gray-800 dark:via-gray-900 dark:to-gray-800 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-3xl mx-auto p-8 bg-white dark:bg-gray-800 shadow-lg rounded-lg"
        >
          <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Contact Us
          </motion.h1>

          {submitted ? (
            <motion.div variants={itemVariants} className="text-center text-2xl text-green-600 dark:text-green-400 font-semibold">
              Thank you for contacting us! We will get back to you shortly.
            </motion.div>
          ) : (
            <motion.form variants={itemVariants} onSubmit={handleSubmit} className="space-y-8">
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full p-3 border border-gray-300 dark:bg-gray-800 dark:border-gray-700 focus:border-purple-500 focus:ring-purple-500 rounded-md shadow-sm transition-all"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="mt-1 block w-full p-3 border border-gray-300 dark:bg-gray-800 dark:border-gray-700 focus:border-purple-500 focus:ring-purple-500 rounded-md shadow-sm transition-all"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    rows={5}
                    className="mt-1 block w-full p-3 border border-gray-300 dark:bg-gray-800 dark:border-gray-700 focus:border-purple-500 focus:ring-purple-500 rounded-md shadow-sm transition-all"
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-shadow shadow-lg hover:shadow-xl"
                >
                  Send Message
                </button>
              </div>
            </motion.form>
          )}

          </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default ContactPage;