import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Edit, X, Check, AlertTriangle, MapPin, Eye, EyeOff, Calendar, Mail, Plus, LogOut, Lock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { toast, Toaster } from 'react-hot-toast';

interface Submission {
  id: string;
  title: string;
  description: string;
  status: 'pending' | 'approved' | 'denied';
  createdAt: number;
  updatedAt: number;
}

const ProfilePage: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [editingSubmission, setEditingSubmission] = useState<Submission | null>(null);
  const [showDenied, setShowDenied] = useState(false);
  const [currentTab, setCurrentTab] = useState('all');
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [isChangeUsernameOpen, setIsChangeUsernameOpen] = useState(false);
  const [newUsername] = useState('');
  const [passwords, setPasswords] = useState({ current: '', new: '', confirm: '' });
  const navigate = useNavigate();


  useEffect(() => {
    fetchUserProfile();
    fetchSubmissions();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await fetch('https://api.spottertools.org/api/user/profile', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        throw new Error('Failed to fetch user profile');
      }
    } catch (error) {
      toast.error('Error fetching user profile');
    }
  };

  const fetchSubmissions = async () => {
    try {
      const response = await fetch('https://api.spottertools.org/api/user/submissions', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (response.ok) {
        const data = await response.json();
        // Sort submissions by updatedAt in descending order and filter out duplicates
        const sortedSubmissions = data.sort((a: Submission, b: Submission) => b.updatedAt - a.updatedAt);
        const uniqueSubmissions = sortedSubmissions.filter((submission: Submission, index: number, self: Submission[]) =>
          index === self.findIndex((t) => t.id === submission.id)
        );
        setSubmissions(uniqueSubmissions);
      } else {
        throw new Error('Failed to fetch submissions');
      }
    } catch (error) {
      toast.error('Error fetching submissions');
    }
  };

  const handleEdit = (submission: Submission) => {
    setEditingSubmission(submission);
  };

  const handleCancelEdit = () => {
    setEditingSubmission(null);
  };

  const handleChangeUsername = async (localUsername: string) => {
    
    try {
      const response = await fetch('https://api.spottertools.org/api/user/change-username', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ newUsername })
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        toast.success('Username updated successfully');
        setUser({ ...user, username: newUsername });
        setIsChangeUsernameOpen(false);
      } else {
        throw new Error('Failed to update username');
      }
    } catch (error) {
      toast.error('Error updating username');
    }
  };

  const handleSaveEdit = async () => {
    if (!editingSubmission) return;

    try {
      const response = await fetch(`https://api.spottertools.org/api/submissions/${editingSubmission.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(editingSubmission)
      });

      if (response.ok) {
        toast.success('Submission updated successfully');
        // Update the submissions list with the edited submission
        setSubmissions(prevSubmissions => 
          prevSubmissions.map(sub => 
            sub.id === editingSubmission.id ? {...editingSubmission, updatedAt: Date.now()} : sub
          )
        );
        setEditingSubmission(null);
      } else {
        throw new Error('Failed to update submission');
      }
    } catch (error) {
      toast.error('Error updating submission');
    }
  };

  const handleResubmit = async (submission: Submission) => {
    try {
      const response = await fetch(`https://api.spottertools.org/api/submissions/${submission.id}/resubmit`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        toast.success('Submission resubmitted successfully');
        fetchSubmissions(); // Refresh the submissions list
      } else {
        throw new Error('Failed to resubmit');
      }
    } catch (error) {
      toast.error('Error resubmitting submission');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    toast.success('Logged out successfully');
    navigate('/');
  }

  const handleChangePassword = async (newPasswords: { current: string, new: string, confirm: string }) => {
    if (newPasswords.new !== newPasswords.confirm) {
      toast.error('New passwords do not match');
      return;
    }
    try {
      const response = await fetch('https://api.spottertools.org/api/user/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          currentPassword: passwords.current,
          newPassword: passwords.new
        })
      });
      if (response.ok) {
        toast.success('Password changed successfully');
        setIsChangePasswordOpen(false);
        setPasswords({ current: '', new: '', confirm: '' });
      } else {
        throw new Error('Failed to change password');
      }
    } catch (error) {
      toast.error('Error changing password');
    }
  };

  const ChangeUsernameForm = () => {
    const [localUsername, setLocalUsername] = useState(newUsername);
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      handleChangeUsername(localUsername);
    };
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={() => setIsChangeUsernameOpen(false)}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <User className="mr-2" /> Change Username
          </h2>
          <form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="New Username"
              value={localUsername}
              onChange={(e) => setLocalUsername(e.target.value)}
              className="mb-4"
            />
            <div className="flex justify-end space-x-2">
              <Button type="button" onClick={() => setIsChangeUsernameOpen(false)} variant="outline">Cancel</Button>
              <Button type="submit">Change Username</Button>
            </div>
          </form>
        </motion.div>
      </motion.div>
    );
  };

  const ChangePasswordForm = () => {
    const [localPasswords, setLocalPasswords] = useState({ current: '', new: '', confirm: '' });
    const [localShowPasswords, setLocalShowPasswords] = useState({ current: false, new: false, confirm: false });
  
    const handleSubmit = (e: React.FormEvent) => {
      e.preventDefault();
      handleChangePassword(localPasswords);
    };
  
    const PasswordInput = ({ name, placeholder }: { name: 'current' | 'new' | 'confirm', placeholder: string }) => (
      <div className="relative">
        <Input
          type={localShowPasswords[name] ? 'text' : 'password'}
          placeholder={placeholder}
          value={localPasswords[name]}
          onChange={(e) => setLocalPasswords({ ...localPasswords, [name]: e.target.value })}
          className="pr-10"
        />
        <button
          type="button"
          onClick={() => setLocalShowPasswords({ ...localShowPasswords, [name]: !localShowPasswords[name] })}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          {localShowPasswords[name] ? (
            <EyeOff className="h-4 w-4 text-gray-400" />
          ) : (
            <Eye className="h-4 w-4 text-gray-400" />
          )}
        </button>
      </div>
    );
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        onClick={() => setIsChangePasswordOpen(false)}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Lock className="mr-2" /> Change Password
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <PasswordInput name="current" placeholder="Current Password" />
            <PasswordInput name="new" placeholder="New Password" />
            <PasswordInput name="confirm" placeholder="Confirm New Password" />
            <div className="flex justify-end space-x-2">
              <Button type="button" onClick={() => setIsChangePasswordOpen(false)} variant="outline">Cancel</Button>
              <Button type="submit">Change Password</Button>
            </div>
          </form>
        </motion.div>
      </motion.div>
    );
  };

  const renderSubmissionCard = (submission: Submission) => (
    <motion.div
      key={submission.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="mb-4 p-6 border rounded-lg shadow-sm bg-white dark:bg-gray-800 transition-all duration-300 hover:shadow-md"
    >
      {editingSubmission && editingSubmission.id === submission.id ? (
        <div className="space-y-4">
          <Input
            value={editingSubmission.title}
            onChange={(e) => setEditingSubmission({...editingSubmission, title: e.target.value})}
            placeholder="Title"
            className="text-lg font-semibold"
          />
          <Textarea
            value={editingSubmission.description}
            onChange={(e) => setEditingSubmission({...editingSubmission, description: e.target.value})}
            placeholder="Description"
            className="text-gray-600 dark:text-gray-400"
          />
          <div className="flex justify-end space-x-2">
            <Button onClick={handleCancelEdit} variant="outline">
              <X className="mr-2 h-4 w-4" /> Cancel
            </Button>
            <Button onClick={handleSaveEdit}>
              <Check className="mr-2 h-4 w-4" /> Save
            </Button>
          </div>
        </div>
      ) : (
        <>
          <h3 className="text-xl font-semibold mb-2">{submission.title}</h3>
          <p className="text-gray-600 dark:text-gray-400 mb-4">{submission.description}</p>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                submission.status === 'approved' ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100' :
                submission.status === 'denied' ? 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100' :
                'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
              }`}>
                {submission.status.charAt(0).toUpperCase() + submission.status.slice(1)}
              </span>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                <Calendar className="inline-block mr-1 h-4 w-4" />
                {new Date(submission.updatedAt).toLocaleDateString()}
              </span>
            </div>
            <div className="space-x-2">
              {submission.status === 'denied' && (
                <Button onClick={() => handleResubmit(submission)} size="sm" variant="secondary">
                  Resubmit
                </Button>
              )}
              <Button onClick={() => handleEdit(submission)} size="sm" variant="outline">
                <Edit className="mr-2 h-4 w-4" /> Edit
              </Button>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-gray-800 dark:text-gray-200 transition-colors duration:300">
      <Toaster position="top-right" />
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto"
        >
          <Card className="mb-8 overflow-hidden shadow-lg">
            <CardHeader className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-6">
              <CardTitle className="text-3xl font-bold flex items-center justify-between">
                <span className="flex items-center">
                  <User className="mr-2" />
                  Profile
                </span>
                <div className="flex space-x-2">
                  <Button variant="outline" size="sm" className="bg-gray-800 text-white hover:bg-gray-700 dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300" onClick={() => setIsChangePasswordOpen(true)}>
                    <Lock className="mr-2 h-4 w-4" />
                    Change Password
                  </Button>
                  <Button variant="outline" size="sm" onClick={handleLogout} className="bg-gray-800 text-white hover:bg-gray-700 dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300">
                    <LogOut className="mr-2 h-4 w-4" />
                    Logout
                  </Button>
                  <Button variant="outline" size="sm" onClick={() => setIsChangeUsernameOpen(true)} className="bg-gray-800 text-white hover:bg-gray-700 dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300">
                    <User className="mr-2 h-4 w-4" />
                    Change Username
                  </Button>
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
              {user && (
                <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-8">
                  <div className="w-32 h-32 bg-gradient-to-br from-blue-400 to-purple-500 rounded-full flex items-center justify-center text-4xl font-bold text-white shadow-lg">
                    {user.email.charAt(0).toUpperCase()}
                  </div>
                  <div className="flex-grow">
                    <h2 className="text-2xl font-semibold mb-2">{user.username}</h2>
                    <p className="text-gray-600 dark:text-gray-400 flex items-center mb-2">
                      <Mail className="mr-2 h-4 w-4" /> {user.email}
                    </p>
                    <p className="text-gray-600 dark:text-gray-400 flex items-center">
                      <Calendar className="mr-2 h-4 w-4" /> Member since {new Date(user.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>

          <Card className="shadow-lg">
            <CardHeader className="bg-gradient-to-r from-green-400 to-blue-500 text-white p-6">
              <CardTitle className="text-2xl font-bold flex items-center justify-between">
                <span className="flex items-center">
                  <MapPin className="mr-2" />
                  Your Submissions
                </span>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setShowDenied(!showDenied)}
                  className="bg-gray-800 text-white hover:bg-gray-700 dark:bg-gray-200 dark:text-gray-800 dark:hover:bg-gray-300"
                  >
                  {showDenied ? <EyeOff className="mr-2 h-4 w-4" /> : <Eye className="mr-2 h-4 w-4" />}
                  {showDenied ? 'Hide Denied' : 'Show Denied'}
                </Button>
              </CardTitle>
            </CardHeader>
            <CardContent className="p-6">
                <Tabs defaultValue={currentTab} onValueChange={setCurrentTab} className="space-y-6">
                <TabsList className="mb-4 space-x-2">
                  <TabsTrigger value="all" className="rounded-md bg-gray-100 dark:bg-gray-700 p-2">All</TabsTrigger>
                  <TabsTrigger value="approved" className="rounded-md bg-gray-100 dark:bg-gray-700 p-2">Approved</TabsTrigger>
                  <TabsTrigger value="pending" className="rounded-md bg-gray-100 dark:bg-gray-700 p-2">Pending</TabsTrigger>
                  {showDenied && <TabsTrigger value="denied" className="rounded-md bg-gray-100 dark:bg-gray-700 p-2">Denied</TabsTrigger>}
                </TabsList>
                <TabsContent value="all">
                  <AnimatePresence>
                  {submissions
                    .filter(sub => showDenied || sub.status !== 'denied')
                    .map(renderSubmissionCard)}
                  {submissions.filter(sub => showDenied || sub.status !== 'denied').length === 0 && (
                    <p className="text-center text-gray-600 dark:text-gray-400">No submissions found.</p>
                  )}
                  </AnimatePresence>
                </TabsContent>
                <TabsContent value="approved">
                  <AnimatePresence>
                  {submissions
                    .filter(sub => sub.status === 'approved')
                    .map(renderSubmissionCard)}
                  {submissions.filter(sub => sub.status === 'approved').length === 0 && (
                    <p className="text-center text-gray-600 dark:text-gray-400">No approved submissions found.</p>
                  )}
                  </AnimatePresence>
                </TabsContent>
                <TabsContent value="pending">
                  <AnimatePresence>
                  {submissions
                    .filter(sub => sub.status === 'pending')
                    .map(renderSubmissionCard)}
                  {submissions.filter(sub => sub.status === 'pending').length === 0 && (
                    <p className="text-center text-gray-600 dark:text-gray-400">No pending submissions found.</p>
                  )}
                  </AnimatePresence>
                </TabsContent>
                {showDenied && (
                  <TabsContent value="denied">
                  <AnimatePresence>
                    {submissions
                    .filter(sub => sub.status === 'denied')
                    .map(renderSubmissionCard)}
                    {submissions.filter(sub => sub.status === 'denied').length === 0 && (
                      <p className="text-center text-gray-600 dark:text-gray-400">No denied submissions found.</p>
                    )}
                  </AnimatePresence>
                  </TabsContent>
                )}
                </Tabs>
              {submissions.length === 0 && (
                <motion.div 
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3 }}
                  className="text-center py-12 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-inner"
                >
                  <AlertTriangle className="mx-auto h-16 w-16 text-yellow-500 mb-4" />
                  <p className="text-2xl font-semibold mb-2">No submissions yet</p>
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    Start by adding a new spotting location!
                  </p>
                  <Button
                    onClick={() => navigate('/add-location')}
                    size="lg"
                    className="animate-pulse bg-gradient-to-r from-green-400 to-blue-500 text-white hover:from-green-500 hover:to-blue-600"
                  >
                    <Plus className="mr-2 h-5 w-5" />
                    Add New Location
                  </Button>
                </motion.div>
              )}
            </CardContent>
          </Card>
        </motion.div>
      </main>
      <Footer />
        <AnimatePresence>
        {isChangeUsernameOpen && <ChangeUsernameForm />}
        {isChangePasswordOpen && <ChangePasswordForm />}
      </AnimatePresence>
    </div>
  );
};

export default ProfilePage;