import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Plane, 
  Info, 
  MapPin, 
  Users, 
  Loader, 
  AlertCircle, 
  Edit, 
  LogIn, 
  Globe, 
  Book, 
  Cloud,
  Ruler,
  Clock
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import HyperlinkText from '@/components/ui/HyperLinkText';
import Papa from 'papaparse';
import 'leaflet/dist/leaflet.css';
import MovementNotificationContainer from '@/components/MovementNotification';

interface Airport {
  icao: string;
  name: string;
  city: string;
  country: string;
  description: string;
}

interface Runway {
  airport_ident: string;
  length_ft: number;
  width_ft: number;
  surface: string;
  lighted: number;
  closed: number;
  le_ident: string;
  le_latitude_deg: number;
  le_longitude_deg: number;
  le_elevation_ft: number;
  le_heading_degT: number;
  le_displaced_threshold_ft: number;
  he_ident: string;
  he_latitude_deg: number;
  he_longitude_deg: number;
  he_elevation_ft: number;
  he_heading_degT: number;
  he_displaced_threshold_ft: number;
}

interface Location {
  id: string;
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  airportICAO: string;
  bestEquipment: string;
  parkingInformation: string;
  securityInformation: string;
  extraInformation: string;
  openingHours?: string;
  communityRating: number;
  status: 'pending' | 'approved';
}

interface WeatherData {
  raw: string;
}

interface Movement {
  id: string;
  title: string;
  details: string;
  date: string;
  type: 'movement' | 'important';
  airportIcao: string;
}

const customIcon = new Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const AirportViewPage: React.FC = () => {
  const { icao } = useParams<{ icao: string }>();
  const navigate = useNavigate();

  const [airport, setAirport] = useState<Airport | null>(null);
  const [runways, setRunways] = useState<Runway[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [movements, setMovements] = useState<Movement[]>([]);
  const [metar, setMetar] = useState<WeatherData | null>(null);
  const [, setTaf] = useState<WeatherData | null>(null);
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);

    const fetchData = async () => {
      try {
        setLoading(true);
        const headers: Record<string, string> = {};
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
        }

        // Fetch airport data
        const airportResponse = await fetch(`https://api.spottertools.org/api/airports/${icao}`, { headers });
        if (airportResponse.status === 401) {
          setError('Please create an account to view airport details.');
          return;
        }

        // Set default airport data if not found
        if (!airportResponse.ok) {
          setAirport({
            icao: icao || 'UNKNOWN',
            name: `Airport ${icao}`,
            city: 'Unknown',
            country: 'Unknown',
            description: 'No description available.'
          });
        } else {
          const airportData = await airportResponse.json();
          setAirport(airportData);
        }

        // Fetch runway data from CSV
        const runwayResponse = await fetch('/data/runways.csv');
        const runwayText = await runwayResponse.text();
        const runwayResults = Papa.parse(runwayText, { header: true, dynamicTyping: true });
        const airportRunways = (runwayResults.data as Runway[]).filter(runway => 
          runway.airport_ident === icao && !runway.closed
        );
        setRunways(airportRunways);

        // Fetch movements
        const movementsResponse = await fetch('https://api.spottertools.org/api/movements', { headers });
        if (movementsResponse.ok) {
          const movementsData = await movementsResponse.json();
          const filteredMovements = (Object.values(movementsData || {}) as Movement[])
            .filter((movement) => movement.airportIcao.toLowerCase() === icao?.toLowerCase())
            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
          setMovements(filteredMovements);
        }

        // Fetch locations
        const locationsResponse = await fetch('https://api.spottertools.org/api/locations', { headers });
        if (locationsResponse.ok) {
          const locationsData = await locationsResponse.json();
          const filteredLocations = (Object.values(locationsData || {}) as Location[])
            .filter((location) => 
              location.airportICAO?.toLowerCase() === icao?.toLowerCase() && 
              location.status === 'approved'
            );
          setLocations(filteredLocations);
        }

        // Fetch weather data
        const [metarResponse, tafResponse] = await Promise.all([
          fetch(`https://api.spottertools.org/api/metar/${icao}`, { headers }),
          fetch(`https://api.spottertools.org/api/taf/${icao}`, { headers })
        ]);

        if (metarResponse.ok) {
          const metarData = await metarResponse.json();
          setMetar(metarData);
        }

        if (tafResponse.ok) {
          const tafData = await tafResponse.json();
          setTaf(tafData);
        }

      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load airport data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (icao) {
      fetchData();
    }
  }, [icao]);

  // Loading state
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
        <Loader className="w-16 h-16 text-blue-500 animate-spin mb-4" />
        <p className="text-xl text-gray-700 dark:text-gray-300">Loading airport information...</p>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
        <AlertCircle className="w-16 h-16 text-red-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">Error</h1>
        <p className="text-xl text-gray-700 dark:text-gray-300 mb-4">{error}</p>
        <Button onClick={() => navigate('/account')} variant="default">
          <LogIn className="mr-2 h-4 w-4" />
          Sign In / Create Account
        </Button>
      </div>
    );
  }

  // Authentication check
  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
        <AlertCircle className="w-16 h-16 text-yellow-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">
          Authentication Required
        </h1>
        <p className="text-xl text-gray-700 dark:text-gray-300 mb-4">
          Please sign in to view airport details.
        </p>
        <Button onClick={() => navigate('/account')} variant="default">
          <LogIn className="mr-2 h-4 w-4" />
          Sign In / Create Account
        </Button>
      </div>
    );
  }

// Main content
return (
  <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800 text-gray-900 dark:text-gray-100">
    <Header onNavigate={(page) => navigate(`/${page}`)} />
    <MovementNotificationContainer />
    
    <main className="container mx-auto px-4 pt-20 py-8">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          {/* Title and Runway Prediction Button */}
          <div className="text-center mb-8">
            <h1 className="text-4xl md:text-5xl font-extrabold mb-4 bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-400 dark:to-blue-500 text-transparent bg-clip-text">
              {airport?.name || `Airport ${icao}`} ({icao})
            </h1>
            <Button
              onClick={() => navigate(`/tools/runways?icao=${icao}`)}
              className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white shadow-md hover:shadow-lg transition-all duration-300"
            >
              <Ruler className="mr-2 h-4 w-4" />
              Predict Runway Usage
            </Button>
          </div>

          {/* Information Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-8">
            {/* Airport Info Card */}
            <Card className="col-span-1 lg:col-span-2 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700 shadow-lg hover:shadow-xl transition-all duration-300">
              <CardHeader>
                <CardTitle className="flex items-center justify-between text-2xl text-gray-900 dark:text-gray-100">
                  <span className="flex items-center">
                    <Info className="mr-2 text-blue-500" />
                    Airport Information
                  </span>
                  <Button variant="outline" size="sm" onClick={() => navigate(`/airport/${icao}/edit`)}>
                    <Edit className="mr-2 h-4 w-4" />
                    Suggest Edit
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="mb-2 flex items-center text-gray-900 dark:text-gray-100">
                      <Globe className="mr-2 h-4 w-4 text-blue-500" />
                      <strong className="text-blue-600 dark:text-blue-400">City:</strong> 
                      <span className="ml-2">{airport?.city || 'Not available'}</span>
                    </p>
                    <p className="mb-2 flex items-center text-gray-900 dark:text-gray-100">
                      <Globe className="mr-2 h-4 w-4 text-blue-500" />
                      <strong className="text-blue-600 dark:text-blue-400">Country:</strong> 
                      <span className="ml-2">{airport?.country || 'Not available'}</span>
                    </p>
                  </div>
                  <div>
                    <p className="mb-2 flex items-start text-gray-900 dark:text-gray-100">
                      <Book className="mr-2 h-4 w-4 text-blue-500 mt-1" />
                      <strong className="text-blue-600 dark:text-blue-400">Description:</strong> 
                      <span className="ml-2">{airport?.description || 'No description available.'}</span>
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>

            {/* Weather Card */}
            <Card className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700 shadow-lg hover:shadow-xl transition-all duration-300">
              <CardHeader>
                <CardTitle className="flex items-center text-xl font-bold text-gray-900 dark:text-gray-100">
                  <Cloud className="mr-2 text-blue-500 dark:text-blue-400" />
                  Current Weather
                </CardTitle>
              </CardHeader>
              <CardContent>
                {metar ? (
                  <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
                    <pre className="text-gray-700 dark:text-gray-300 whitespace-pre-wrap">
                      {metar.raw}
                    </pre>
                  </div>
                ) : (
                  <Alert className="bg-yellow-100/50 dark:bg-yellow-900/30 border-yellow-400">
                    <AlertCircle className="h-4 w-4 text-yellow-600 dark:text-yellow-400" />
                    <AlertDescription className="text-gray-800 dark:text-gray-200">
                      Weather data is not available.
                    </AlertDescription>
                  </Alert>
                )}
              </CardContent>
            </Card>
          </div>

          {/* Current Movements */}
          <Card className="mb-8 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700 shadow-lg">
            <CardHeader>
              <CardTitle className="flex items-center text-2xl text-gray-900 dark:text-gray-100">
                <Plane className="mr-2 text-blue-500 dark:text-blue-400" />
                Current Movements
              </CardTitle>
            </CardHeader>
            <CardContent>
              {movements.length > 0 ? (
                <div className="space-y-4">
                  {movements.map((movement) => (
                    <motion.div
                      key={movement.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      className={`p-4 rounded-lg ${
                        movement.type === 'important'
                          ? 'bg-red-50 dark:bg-red-900/30 border-l-4 border-red-500'
                          : 'bg-blue-50 dark:bg-blue-900/30 border-l-4 border-blue-500'
                      }`}
                    >
                      <div className="flex justify-between items-start">
                        <div>
                          <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100">{movement.title}</h3>
                          <p className="text-gray-600 dark:text-gray-300 flex items-center mt-1">
                            <Clock className="mr-2 h-4 w-4" />
                            {movement.date}
                          </p>
                        </div>
                      </div>
                      <div className="mt-2 text-gray-700 dark:text-gray-300">
                        <HyperlinkText text={movement.details} />
                      </div>
                    </motion.div>
                  ))}
                </div>
              ) : (
                <Alert className="bg-blue-100/50 dark:bg-blue-900/30 border-blue-400">
                  <Info className="h-4 w-4 text-blue-600 dark:text-blue-400" />
                  <AlertDescription className="text-gray-800 dark:text-gray-200">
                    No current movements at this airport.
                  </AlertDescription>
                </Alert>
              )}
              <div className="mt-4 text-center">
                <Button
                  onClick={() => navigate('/movements')}
                  variant="outline"
                  className="text-blue-600 dark:text-blue-400 hover:bg-blue-50 dark:hover:bg-blue-900/50"
                >
                  View All Movements
                </Button>
              </div>
            </CardContent>
          </Card>

          {/* Runway Information */}
          <Card className="mb-8 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700 shadow-lg">
            <CardHeader>
              <CardTitle className="flex items-center text-2xl text-gray-900 dark:text-gray-100">
                <Plane className="mr-2 text-blue-500 dark:text-blue-400" />
                Runway Information
              </CardTitle>
            </CardHeader>
            <CardContent>
              {runways.length > 0 ? (
                <ul className="space-y-4">
                  {runways.map((runway, index) => (
                    <li key={index} className="border-b border-gray-200 dark:border-gray-700 pb-2 last:border-b-0">
                      <strong className="text-lg text-blue-600 dark:text-blue-400">
                        {runway.le_ident}/{runway.he_ident}
                      </strong>
                      <p className="text-gray-600 dark:text-gray-300">
                        Length: {runway.length_ft.toLocaleString()} ft, Width: {runway.width_ft} ft
                      </p>
                      <p className="text-gray-600 dark:text-gray-300">
                        Surface: {runway.surface === 'ASP' ? 'Asphalt' : runway.surface}
                      </p>
                      <p className="text-gray-600 dark:text-gray-300">
                        {runway.lighted ? 'Lighted' : 'Not lighted'}
                      </p>
                    </li>
                  ))}
                </ul>
              ) : (
                <Alert className="bg-yellow-100/50 dark:bg-yellow-900/30 border-yellow-400">
                  <AlertCircle className="h-4 w-4 text-yellow-600 dark:text-yellow-400" />
                  <AlertDescription className="text-gray-800 dark:text-gray-200">
                    No runway information available for this airport.
                  </AlertDescription>
                </Alert>
              )}
            </CardContent>
          </Card>

          {/* Spotting Locations */}
          <Card className="mb-8 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700 shadow-lg">
            <CardHeader>
              <CardTitle className="flex items-center text-2xl text-gray-900 dark:text-gray-100">
                <MapPin className="mr-2 text-blue-500 dark:text-blue-400" />
                Spotting Locations
              </CardTitle>
            </CardHeader>
            <CardContent>
              {locations.length > 0 ? (
                <div className="space-y-8">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {locations.map((location) => (
                      <motion.div
                        key={location.id}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg hover:shadow-md transition-shadow duration-300"
                      >
                        <h3 className="font-semibold text-lg text-gray-900 dark:text-gray-100 mb-2">
                          {location.title}
                        </h3>
                        <p className="text-gray-600 dark:text-gray-300 mb-4">{location.description}</p>
                        <Link
                          to={`/location/${location.id}`}
                          className="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 font-medium"
                        >
                          View Details →
                        </Link>
                      </motion.div>
                    ))}
                  </div>

                  {/* Map remains the same */}
                  <div className="h-[400px] rounded-lg overflow-hidden shadow-inner">
                    <MapContainer 
                      center={[locations[0]?.latitude || 0, locations[0]?.longitude || 0]} 
                      zoom={12} 
                      style={{ height: '100%', width: '100%' }}
                      className="z-0"
                    >
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      {locations.map((location) => (
                        <Marker 
                          key={location.id}
                          position={[location.latitude, location.longitude]}
                          icon={customIcon}
                        >
                          <Popup>
                            <div className="p-2">
                              <h3 className="font-bold text-lg mb-2">{location.title}</h3>
                              <p className="text-sm text-gray-600 mb-2">
                                {location.description}
                              </p>
                              <Button 
                                onClick={() => navigate(`/location/${location.id}`)}
                                size="sm"
                                className="w-full bg-blue-500 hover:bg-blue-600 text-white"
                              >
                                View Details
                              </Button>
                            </div>
                          </Popup>
                        </Marker>
                      ))}
                    </MapContainer>
                  </div>
                </div>
              ) : (
                <Alert className="bg-blue-100/50 dark:bg-blue-900/30 border-blue-400">
                  <Info className="h-4 w-4 text-blue-600 dark:text-blue-400" />
                  <AlertDescription className="text-gray-800 dark:text-gray-200">
                    No spotting locations have been added for this airport yet. Be the first to contribute!
                  </AlertDescription>
                </Alert>
              )}
              
              <div className="mt-8 text-center">
                <Button 
                  onClick={() => navigate('/add-location', { state: { airportICAO: icao } })}
                  className="bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white px-6 py-3 rounded-full shadow-lg transition-all duration-300 transform hover:-translate-y-1 hover:shadow-xl"
                >
                  <Users className="mr-2 h-4 w-4" />
                  Add New Spotting Location
                  </Button>
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </AnimatePresence>
      </main>
      
      <Footer />
    </div>
  );
};

export default AirportViewPage;