import React from 'react';
import { motion } from 'framer-motion';
import { ShieldCheck, FileText, Lock, CheckCircle, Globe } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';

import { useNavigate } from 'react-router-dom';
import MovementNotificationContainer from '@/components/MovementNotification';

const TermsOfServicePage: React.FC = () => {
  const navigate = useNavigate();
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-4xl mx-auto"
        >
          <motion.h1 variants={itemVariants} className="text-4xl md:text-5xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Terms of Service
          </motion.h1>

          <motion.p variants={itemVariants} className="text-xl mb-8 text-center text-gray-700 dark:text-gray-300">
            These Terms of Service govern your use of our platform, services, and products. By accessing or using our services, you agree to be bound by these terms.
          </motion.p>

          <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {/* User Obligations Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <ShieldCheck className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">User Obligations</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    As a user of our platform, you agree to:
                  </p>
                  <ul className="list-disc ml-6 text-gray-600 dark:text-gray-400 mt-4 space-y-2">
                    <li>Provide accurate and up-to-date information</li>
                    <li>Maintain the confidentiality of your account and password</li>
                    <li>Use the platform in compliance with applicable laws and regulations</li>
                    <li>Refrain from engaging in prohibited activities, such as spamming, hacking, or distributing malware</li>
                  </ul>
                </CardContent>
              </Card>
            </motion.div>

            {/* Account Termination Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <FileText className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Account Termination</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    We reserve the right to terminate or suspend your account for violating these terms or engaging in activities that harm the platform, other users, or third parties.
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    Upon termination, you will no longer have access to the platform, and any data associated with your account may be deleted.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            {/* Limitation of Liability Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <Lock className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Limitation of Liability</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    To the maximum extent permitted by law, we are not liable for any indirect, incidental, or consequential damages arising from your use of our platform, services, or products.
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    This includes, but is not limited to, damages for loss of data, profits, or goodwill, even if we have been advised of the possibility of such damages.
                  </p>
                </CardContent>
              </Card>
            </motion.div>

            {/* Modifications to Terms Section */}
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <CheckCircle className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Modifications to Terms</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    We may modify these terms from time to time. If we make changes, we will notify you by posting the revised terms on our platform or through other communication methods.
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    Continued use of the platform following any changes constitutes your acceptance of the updated terms.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>

          {/* Governing Law Section */}
          <motion.h2 variants={itemVariants} className="text-3xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
            Governing Law and Disputes
          </motion.h2>

          <motion.div variants={containerVariants} className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-12">
            <motion.div variants={itemVariants}>
              <Card className="h-full">
                <CardContent className="p-6">
                  <div className="flex items-center mb-4">
                    <Globe className="w-8 h-8 mr-3 text-blue-600 dark:text-blue-400" />
                    <h2 className="text-2xl font-semibold">Governing Law</h2>
                  </div>
                  <p className="text-gray-600 dark:text-gray-400">
                    These terms and any disputes related to them will be governed by and interpreted in accordance with the laws of the jurisdiction in which SpotterTools.org operates, without regard to its conflict of law provisions.
                  </p>
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    Any disputes arising under these terms will be subject to the exclusive jurisdiction of the courts in that jurisdiction.
                  </p>
                </CardContent>
              </Card>
            </motion.div>
          </motion.div>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default TermsOfServicePage;
