import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { MapPin, Camera, Car, Shield, Clock, UserCog } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import MovementNotificationContainer from '@/components/MovementNotification';

interface GuideSectionProps {
  icon: React.ComponentType<{ className?: string }>;
  title: string;
  children: React.ReactNode;
}

const GuideSection: React.FC<GuideSectionProps> = ({ icon: Icon, title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    className="mb-12"
  >
    <Card className="overflow-hidden">
      <CardContent className="p-6">
        <div className="flex items-center mb-4">
          <div className="bg-blue-100 dark:bg-blue-900 p-3 rounded-full mr-4">
            <Icon className="w-8 h-8 text-blue-500 dark:text-blue-300" />
          </div>
          <h2 className="text-2xl font-semibold">{title}</h2>
        </div>
        <div className="pl-16">{children}</div>
      </CardContent>
    </Card>
  </motion.div>
);

interface SubmissionStepProps {
  number: number;
  title: string;
  description: string;
}

const SubmissionStep: React.FC<SubmissionStepProps> = ({ number, title, description }) => (
  <motion.div 
    className="flex items-start mb-4"
    initial={{ opacity: 0, x: -20 }}
    whileInView={{ opacity: 1, x: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5, delay: number * 0.1 }}
  >
    <div className="bg-blue-500 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4 flex-shrink-0">
      {number}
    </div>
    <div>
      <h3 className="font-semibold text-lg mb-1">{title}</h3>
      <p className="text-gray-600 dark:text-gray-400">{description}</p>
    </div>
  </motion.div>
);

const LocationSubmissionGuide = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('GuideSeen', 'true');
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      <main className="flex-grow container mx-auto px-4 pt-20 py-12">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-900 dark:text-gray-100"
        >
          Guide to Submitting Spotting Locations
        </motion.h1>

        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-12 text-center"
        >
          <p className="text-xl mb-6">
            Follow this guide to submit high-quality spotting locations and help fellow aviation enthusiasts.
          </p>
        </motion.div>

        <GuideSection icon={MapPin} title="Essential Information">
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Title:</strong> A descriptive name for the location</li>
            <li><strong>Description:</strong> Detailed explanation of what can be seen</li>
            <li><strong>Coordinates:</strong> Precise latitude and longitude</li>
            <li><strong>Airport ICAO:</strong> Four-letter ICAO code of the nearest airport</li>
          </ul>
        </GuideSection>

        <GuideSection icon={Camera} title="Photography Details">
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Best Equipment:</strong> Recommended camera and lens combinations</li>
            <li><strong>Extra Information:</strong> Tips for getting the best shots</li>
          </ul>
        </GuideSection>

        <GuideSection icon={Car} title="Parking and Access">
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Parking Information:</strong> Availability, cost, and restrictions</li>
            <li><strong>Opening Hours:</strong> When the location is accessible in local time e.g. 6:30 AM - 7:45 PM</li>
          </ul>
        </GuideSection>

        <GuideSection icon={Shield} title="Security Information">
          <ul className="list-disc pl-6 space-y-2">
            <li>Presence of security patrols</li>
            <li>Signage or restrictions</li>
            <li>Guidelines for interacting with security personnel</li>
          </ul>
        </GuideSection>

        <GuideSection icon={Clock} title="Submission Process">
          <SubmissionStep 
            number={1} 
            title="Gather Information" 
            description="Collect all the necessary details about your spotting location."
          />
          <SubmissionStep 
            number={2} 
            title="Fill Out the Form" 
            description="Use our submission form to enter all the location details accurately."
          />
          <SubmissionStep 
            number={3} 
            title="Review Your Submission" 
            description="Double-check all the information for accuracy before submitting."
          />
          <SubmissionStep 
            number={4} 
            title="Submit for Review" 
            description="Once you're satisfied, submit your location for admin review."
          />
        </GuideSection>

        <GuideSection icon={UserCog} title="Admin Review Process">
          <ol className="list-decimal pl-6 space-y-2">
            <li>Admins review the submitted information for completeness and accuracy.</li>
            <li>They may cross-reference the location with other sources to verify details.</li>
            <li>If needed, admins might contact you for additional information.</li>
            <li>Once approved, your location will be added to the public database.</li>
            <li>If not approved, you'll receive feedback on what needs to be improved.</li>
          </ol>
        </GuideSection>

        <motion.div 
          className="mt-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <Button 
            onClick={() => navigate('/add-location')}
            className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-full text-lg shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
          >
            Submit a New Location
          </Button>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default LocationSubmissionGuide;