import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Camera, SquareParking, Shield, Star, Info, MapPin, Plane,
  Clock, Share2, Calendar, ArrowLeft, ExternalLink, AlertTriangle
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import MovementNotificationContainer from '@/components/MovementNotification';
import { LocationImageUpload } from '@/components/LocationImageUpload';
import toast from 'react-hot-toast';

interface LocationImage {
  id: string;
  groupId: string;
  Url: string;
  status: 'approved' | 'pending' | 'rejected';
  uploadedAt: number;
}
interface Location {
  id: string;
  latitude: number;
  longitude: number;
  title: string;
  description: string;
  bestEquipment: string;
  parkingInformation: string;
  securityInformation: string;
  communityRating: number;
  status: 'pending' | 'approved';
  airportICAO: string;
  extraInformation: string;
  openingHours: string;
  createdAt?: number;
  updatedAt?: number;
  images?: LocationImage[];
}

const LocationDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [location, setLocation] = useState<Location | null>(null);
  const [userRating, setUserRating] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showShareTooltip, setShowShareTooltip] = useState(false);
  const [locationImages, setLocationImages] = useState<LocationImage[]>([]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('No authentication token found');
        
        const response = await fetch(`https://api.spottertools.org/api/locations/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        
        if (!response.ok) throw new Error('Failed to fetch location details');
        
        const data = await response.json();
        setLocation(data);
      } catch (error) {
        console.error('Error fetching location:', error);
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, [id]);

  const fetchLocationImages = useCallback(async () => {
    if (!id) return;
    
    try {
      const token = localStorage.getItem('token');
      if (!token) return;
      
      const response = await fetch(`https://api.spottertools.org/api/location-images/${id}`, {
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      });
      
      // Handle 404 gracefully
      if (response.status === 404) {
        console.log('Location not found or no images available');
        setLocationImages([]);
        return;
      }
      
      if (!response.ok) {
        throw new Error('Failed to fetch location images');
      }
      
      const data = await response.json();
      
      // Handle no images case
      if (Array.isArray(data)) {
        setLocationImages(data);
      } else if (data.error) {
        console.error('Error from server:', data.error);
        setLocationImages([]);
      } else {
        setLocationImages([]);
      }
    } catch (error) {
      console.error('Error fetching location images:', error);
      setLocationImages([]);
    }
  }, [id]);
  
  useEffect(() => {
    fetchLocationImages();
  }, [fetchLocationImages]);
  const handleRating = async (rating: number) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found');
      
      const response = await fetch(`https://api.spottertools.org/api/locations/${id}/rate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ rating })
      });
      
      if (response.ok) {
        setUserRating(rating);
        const updatedLocation = await response.json();
        setLocation(updatedLocation);
        toast.success('Rating submitted successfully!');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit rating');
      }
    } catch (error) {
      console.error('Error rating location:', error);
      setError((error as Error).message);
      toast.error('Failed to submit rating');
    }
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowShareTooltip(true);
    setTimeout(() => setShowShareTooltip(false), 2000);
  };

  const InfoCard = ({ icon: Icon, title, content, className = "", delay = 0 }: { icon: React.ComponentType<any>, title: string, content: string, className?: string, delay?: number }) => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay, duration: 0.5, type: "spring" }}
      className={`bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-xl p-6 shadow-lg 
        border border-blue-100 dark:border-blue-900
        hover:shadow-xl hover:border-blue-200 dark:hover:border-blue-800 
        transform hover:-translate-y-1 transition-all duration-300 ${className}`}
    >
      <div className="flex items-center mb-4">
        <div className="p-3 rounded-full bg-gradient-to-br from-blue-100 to-indigo-100 
          dark:from-blue-900 dark:to-indigo-900 mr-4 shadow-inner">
          <Icon className="h-6 w-6 text-blue-600 dark:text-blue-400" />
        </div>
        <h3 className="text-xl font-semibold bg-clip-text text-transparent 
          bg-gradient-to-r from-blue-600 to-indigo-600 
          dark:from-blue-400 dark:to-indigo-400">{title}</h3>
      </div>
      <p className="text-gray-600 dark:text-gray-300 leading-relaxed">{content || 'Not specified'}</p>
    </motion.div>
  );

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
        <div className="relative">
          <div className="h-32 w-32 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-blue-500">
            <Plane className="h-12 w-12" />
          </div>
        </div>
      </div>
    );
  }

  if (error || !location) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-gray-900 dark:to-gray-800">
        <Card className="w-full max-w-md transform hover:scale-105 transition-transform duration-300">
          <CardContent className="p-8">
            <div className="text-center">
              <div className="inline-block p-4 bg-red-100 dark:bg-red-900 rounded-full mb-4">
                <AlertTriangle className="h-12 w-12 text-red-500" />
              </div>
              <h2 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-4">Error</h2>
              <p className="text-gray-700 dark:text-gray-300 mb-6">
                {error === 'No authentication token found' 
                  ? 'Please create an account to view more details.' 
                  : (error || 'Failed to load location details')}
              </p>
              <Button 
                onClick={() => navigate(error === 'No authentication token found' ? '/account' : '/locations')}
                className="w-full"
              >
                {error === 'No authentication token found' ? 'Sign In / Sign Up' : 'Back to Locations'}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  // Replace the existing images section with this updated version
  const ImagesSection = () => (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.85 }}
      className="md:col-span-2 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-xl p-8 
        shadow-lg border border-blue-100 dark:border-blue-900"
    >
      <div className="mb-8">
        <h2 className="text-2xl font-bold bg-clip-text text-transparent 
          bg-gradient-to-r from-blue-600 to-indigo-600 
          dark:from-blue-400 dark:to-indigo-400 mb-4">
          Location Images
        </h2>
        
        <LocationImageUpload locationId={id || ''} onUploadComplete={() => {
          // Refresh images after new upload
          fetchLocationImages();
        }} />
      </div>

      {locationImages.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {locationImages.map((image, index) => (
            <motion.div
              key={image.id}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.1 * index }}
              className="relative group aspect-square rounded-xl overflow-hidden shadow-md"
            >
              <img
                src={image.Url}
                alt={`View ${index + 1} of ${location.title}`}
                className="w-full h-full object-cover transform transition-transform duration-300 
                  group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent 
                opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="absolute bottom-0 left-0 right-0 p-4">
                  <p className="text-white text-sm">
                    Uploaded {new Date(image.uploadedAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <div className="text-center py-12 bg-gray-100/50 dark:bg-gray-700/20 
          rounded-xl border-2 border-dashed border-gray-300 dark:border-gray-600">
          <Camera className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500 mb-4" />
          <p className="text-gray-500 dark:text-gray-400">No approved images yet</p>
          <p className="text-sm text-gray-400 dark:text-gray-500 mt-2">
            Upload images using the form above
          </p>
        </div>
      )}
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-indigo-50 
      dark:from-gray-900 dark:via-gray-800 dark:to-indigo-900">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      
      <main className="container mx-auto pt-20 px-4 py-15">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="max-w-6xl mx-auto"
        >
          <Card className="overflow-hidden shadow-2xl border-0 bg-white/90 dark:bg-gray-800/90 backdrop-blur-lg">
            <CardHeader className="relative bg-gradient-to-r from-blue-600 via-indigo-600 to-blue-600 text-white p-16">
              <motion.div
                className="absolute inset-0 bg-blue-500/20"
                animate={{ 
                  backgroundPosition: ['0% 0%', '100% 100%'],
                  scale: [1, 1.1, 1]
                }}
                transition={{ 
                  duration: 10, 
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
              />
              
              {/* Navigation and Share buttons */}
              <div className="absolute top-4 w-full flex justify-between px-4">
                <Button 
                  variant="ghost" 
                  onClick={() => navigate('/locations')}
                  className="text-white hover:bg-white/20 backdrop-blur-sm"
                >
                  <ArrowLeft className="mr-2 h-4 w-4" />
                  Back to Map
                </Button>
                
                <div className="relative">
                  <Button
                    variant="ghost"
                    onClick={handleShare}
                    className="text-white hover:bg-white/20 backdrop-blur-sm"
                  >
                    <Share2 className="h-4 w-4" />
                  </Button>
                  <AnimatePresence>
                    {showShareTooltip && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute right-0 mt-2 px-3 py-1 bg-black/80 backdrop-blur-sm 
                          text-white text-sm rounded-full shadow-lg"
                      >
                        Link copied!
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
              
              {/* Title and Meta Information */}
              <div className="relative text-center z-10">
                <motion.h1 
                  className="text-5xl md:text-6xl font-black mb-6 text-white drop-shadow-lg"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {location.title}
                </motion.h1>
                
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  className="flex items-center justify-center space-x-8"
                >
                  <Link 
                    to={`/airport/${location.airportICAO}`}
                    className="flex items-center px-4 py-2 rounded-full bg-white/20 backdrop-blur-sm
                      hover:bg-white/30 transition-colors"
                  >
                    <Plane className="mr-2 h-5 w-5" />
                    {location.airportICAO}
                    <ExternalLink className="ml-1 h-4 w-4" />
                  </Link>
                  
                  <div className="flex items-center px-4 py-2 rounded-full bg-white/20 backdrop-blur-sm">
                    <Calendar className="mr-2 h-5 w-5" />
                    {location.createdAt ? new Date(location.createdAt).toLocaleDateString() : 'N/A'}
                  </div>
                </motion.div>
              </div>
            </CardHeader>

            <CardContent className="p-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <InfoCard 
                  icon={Info} 
                  title="Description" 
                  content={location.description} 
                  className="md:col-span-2"
                  delay={0.2}
                />
                
                <InfoCard 
                  icon={MapPin} 
                  title="Coordinates" 
                  content={`${location.latitude.toFixed(6)}, ${location.longitude.toFixed(6)}`}
                  delay={0.3}
                />
                
                <InfoCard 
                  icon={Camera} 
                  title="Best Equipment" 
                  content={location.bestEquipment}
                  delay={0.4}
                />
                
                <InfoCard 
                  icon={SquareParking} 
                  title="Parking Information" 
                  content={location.parkingInformation}
                  delay={0.5}
                />
                
                <InfoCard 
                  icon={Shield} 
                  title="Security Information" 
                  content={location.securityInformation}
                  delay={0.6}
                />

                <InfoCard 
                  icon={Clock} 
                  title="Opening Hours" 
                  content={location.openingHours}
                  delay={0.7}
                />
                
                <InfoCard 
                  icon={Info} 
                  title="Extra Information" 
                  content={location.extraInformation}
                  delay={0.8}
                />

              <ImagesSection />
                
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.9 }}
                  className="md:col-span-2 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-xl p-8 
                    shadow-lg border border-blue-100 dark:border-blue-900"
                >
                  <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent 
                    bg-gradient-to-r from-blue-600 to-indigo-600 
                    dark:from-blue-400 dark:to-indigo-400">Community Rating</h2>
                  
                  <div className="flex items-center mb-6">
                    <div className="flex">
                      {Array(5).fill(0).map((_, i) => (
                        <Star 
                          key={i} 
                          className={`h-8 w-8 ${
                            i < location.communityRating 
                              ? 'text-yellow-400 fill-current' 
                              : 'text-gray-300'
                          } transform hover:scale-110 transition-transform duration-200`}
                        />
                      ))}
                    </div>
                    <span className="ml-4 text-2xl font-semibold text-gray-700 dark:text-gray-300">
                      {location.communityRating.toFixed(1)} / 5.0
                    </span>
                  </div>
                  
                  <div className="mt-6 pt-6 border-t border-gray-200 dark:border-gray-700">
                    <h3 className="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-300">Rate this Location</h3>
                    <div className="flex">
                      {Array(5).fill(0).map((_, i) => (
                        <Star 
                          key={i}
                          className={`h-8 w-8 cursor-pointer transition-all duration-200 
                            ${i < userRating ? 'text-yellow-400 fill-current' : 'text-gray-300'}
                            hover:text-yellow-500 transform hover:scale-125`}
                          onClick={() => handleRating(i + 1)}
                        />
                      ))}
                    </div>
                  </div>
                </motion.div>
              </div>
            </CardContent>
          </Card>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default LocationDetailsPage;