import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Check, 
  X, 
  Image as ImageIcon, 
  ChevronLeft, 
  ChevronRight,
  MapPin,
  Calendar,
  Loader,
  CheckIcon
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { toast } from 'react-hot-toast';

interface Location {
  id: string;
  title: string;
  airportICAO: string;
}

interface Image {
  id: string;
  Url: string;
  uploadedAt: number;
  status: 'pending' | 'approved' | 'rejected';
}

export default function AdminImageReviewPage() {
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [pendingImages, setPendingImages] = useState<Image[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingAction, setLoadingAction] = useState(false);
  const navigate = useNavigate();

  // Filter locations based on search term
  const filteredLocations = locations.filter(location => 
    location.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    location.airportICAO.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://api.spottertools.org/api/locations', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        if (!response.ok) throw new Error('Failed to fetch locations');
        
        const data = await response.json();
        const locationArray = Object.entries(data).map(([id, loc]: [string, any]) => ({
          id,
          title: loc.title,
          airportICAO: loc.airportICAO
        }));
        setLocations(locationArray);
      } catch (error) {
        toast.error('Failed to load locations');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchPendingImages = async () => {
      if (!selectedLocation) return;
      
      setLoading(true);
      try {
        const response = await fetch(
          `https://api.spottertools.org/api/admin/location-images/${selectedLocation.id}`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        
        if (!response.ok) throw new Error('Failed to fetch pending images');
        
        const data = await response.json();
        setPendingImages(data || []);
        setCurrentImageIndex(0);
      } catch (error) {
        toast.error('Failed to load pending images');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingImages();
  }, [selectedLocation]);

  const handleImageAction = async (action: 'approve' | 'reject') => {
    if (!selectedLocation || !pendingImages[currentImageIndex]) return;

    setLoadingAction(true);
    try {
      const response = await fetch(
        `https://api.spottertools.org/api/admin/location-images/${selectedLocation.id}/images/${pendingImages[currentImageIndex].id}/review`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ action })
        }
      );

      if (!response.ok) throw new Error('Failed to process image');

      toast.success(`Image ${action}ed successfully`);
      
      // Remove the processed image and update the UI
      setPendingImages(prev => prev.filter((_, index) => index !== currentImageIndex));
      setCurrentImageIndex(prev => prev >= pendingImages.length - 1 ? Math.max(0, prev - 1) : prev);
    } catch (error) {
      toast.error(`Failed to ${action} image`);
      console.error('Error:', error);
    } finally {
      setLoadingAction(false);
    }
  };

  const NavigationButton = ({ direction, onClick }: { direction: 'prev' | 'next', onClick: () => void }) => (
    <Button
      variant="outline"
      size="icon"
      onClick={onClick}
      disabled={
        direction === 'prev' ? currentImageIndex === 0 : 
        currentImageIndex === pendingImages.length - 1
      }
      className="absolute top-1/2 transform -translate-y-1/2 z-10 bg-white/80 hover:bg-white/90 dark:bg-gray-800/80 dark:hover:bg-gray-800/90"
      style={{
        [direction === 'prev' ? 'left' : 'right']: '1rem'
      }}
    >
      {direction === 'prev' ? <ChevronLeft /> : <ChevronRight />}
    </Button>
  );

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="container mx-auto px-4 pt-20 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="space-y-8"
        >
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">
              Image Review Dashboard
            </h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Locations Panel */}
            <Card className="md:col-span-1">
              <CardHeader>
                <CardTitle className="flex items-center space-x-2 dark:text-white">
                  <MapPin className="h-5 w-5" />
                  <span>Locations</span>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="mb-4">
                  <Input
                    placeholder="Search locations..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full"
                  />
                </div>
                <div className="space-y-2 max-h-[600px] overflow-y-auto">
                  {filteredLocations.map((location) => (
                    <Button
                      key={location.id}
                      variant={selectedLocation?.id === location.id ? "default" : "outline"}
                      className="w-full justify-start"
                      onClick={() => setSelectedLocation(location)}
                    >
                      <div className="truncate text-left">
                        <div className="font-medium">{location.title}</div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {location.airportICAO}
                        </div>
                      </div>
                    </Button>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Image Review Panel */}
            <Card className="md:col-span-2">
              <CardHeader>
                <CardTitle className="flex items-center justify-between dark:text-white">
                  <div className="flex items-center space-x-2">
                    <ImageIcon className="h-5 w-5" />
                    <span>Image Review</span>
                  </div>
                  {selectedLocation && pendingImages.length > 0 && (
                    <span className="text-sm text-gray-500">
                      {currentImageIndex + 1} of {pendingImages.length}
                    </span>
                  )}
                </CardTitle>
              </CardHeader>
              <CardContent>
                {loading ? (
                  <div className="h-96 flex items-center justify-center">
                    <Loader className="h-8 w-8 animate-spin" />
                  </div>
                ) : !selectedLocation ? (
                  <div className="h-96 flex items-center justify-center text-gray-500">
                    <div className="text-center">
                      <MapPin className="h-12 w-12 mx-auto mb-4" />
                      <p>Select a location to review images</p>
                    </div>
                  </div>
                ) : pendingImages.length === 0 ? (
                  <div className="h-96 flex items-center justify-center text-gray-500">
                    <div className="text-center">
                      <CheckIcon className="h-12 w-12 mx-auto mb-4" />
                      <p>No pending images to review</p>
                    </div>
                  </div>
                ) : (
                  <div className="relative">
                    <NavigationButton
                      direction="prev"
                      onClick={() => setCurrentImageIndex(i => Math.max(0, i - 1))}
                    />
                    
                    <div className="relative h-96 bg-gray-900 rounded-lg overflow-hidden">
                      <img
                        src={pendingImages[currentImageIndex].Url}
                        alt="Pending review"
                        className="w-full h-full object-contain"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                        <div className="text-white">
                          <div className="flex items-center space-x-2">
                            <Calendar className="h-4 w-4" />
                            <span className="text-sm">
                              Uploaded: {new Date(pendingImages[currentImageIndex].uploadedAt).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <NavigationButton
                      direction="next"
                      onClick={() => setCurrentImageIndex(i => Math.min(pendingImages.length - 1, i + 1))}
                    />

                    <div className="mt-4 flex justify-center space-x-4">
                      <Button
                        variant="outline"
                        className="bg-red-100 hover:bg-red-200 text-red-600"
                        onClick={() => handleImageAction('reject')}
                        disabled={loadingAction}
                      >
                        <X className="mr-2 h-4 w-4" />
                        Reject
                      </Button>
                      <Button
                        variant="outline"
                        className="bg-green-100 hover:bg-green-200 text-green-600"
                        onClick={() => handleImageAction('approve')}
                        disabled={loadingAction}
                      >
                        <Check className="mr-2 h-4 w-4" />
                        Approve
                      </Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
}