// tracker.tsx
import React, { useState, useEffect } from 'react';
import PlatformDisplay from '@/components/PlatformDisplay';

interface SercoTrip {
  Run: string;
  Uid: string;
  Cancelled: boolean;
  Ncar: number;
  State: string;
  Patterncode: string;
  Schedule: string;
  Actual: string;
  Delay: number;
  Destination: string;
  Pattern: string;
  DisplayDelayTime: string;
  MinutesDelayTime: number;
  actualDisplayTime24: string;
  actualDisplayTime12: string;
  PatternFullDisplay: string;
  Line: string;
  LineFull: string;
  Link: string;
  Platform: string;
}

interface SercoStationResponse {
  Name: string;
  LastUpdate: string;
  Trips: SercoTrip[];
}

const lineColors: Record<string, string> = {
  "AIR": "#44BFAF",
  "MID": "#96194A",
  "ARM": "#F4A81C",
  "FRE": "#015DA7",
  "YAN": "#939435",
  "THO": "#F4A81C",
  "MAN": "#D26027"
};

export interface TrackerProps {

  station: string;

  platform: string;

}

const Tracker: React.FC<TrackerProps> = ({ station, platform }) => {
  const [services, setServices] = useState<SercoStationResponse | null>(null);

  const hardCodedStation = station;
  const hardCodedPlatform = platform;

useEffect(() => {
  const fetchServices = async () => {
    try {
      const response = await fetch(`https://api.spottertools.org/dev/station/stationname=${encodeURIComponent(station)}`);
      const jsonData = await response.json();
      
      // Parse XML response from raw property
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(jsonData.raw, "text/xml");

      const stationData: SercoStationResponse = {
        Name: xmlDoc.getElementsByTagName('Name')[0]?.textContent || '',
        LastUpdate: xmlDoc.getElementsByTagName('LastUpdate')[0]?.textContent || '',
        Trips: Array.from(xmlDoc.getElementsByTagName('SercoTrip')).map(trip => ({
          Run: trip.getElementsByTagName('Run')[0]?.textContent || '',
          Uid: trip.getElementsByTagName('Uid')[0]?.textContent || '',
          Cancelled: trip.getElementsByTagName('Cancelled')[0]?.textContent === 'True',
          Ncar: parseInt(trip.getElementsByTagName('Ncar')[0]?.textContent || '0'),
          State: trip.getElementsByTagName('State')[0]?.textContent || '',
          Patterncode: trip.getElementsByTagName('Patterncode')[0]?.textContent || '',
          Schedule: trip.getElementsByTagName('Schedule')[0]?.textContent || '',
          Actual: trip.getElementsByTagName('Actual')[0]?.textContent || '',
          Delay: parseInt(trip.getElementsByTagName('Delay')[0]?.textContent || '0'),
          Destination: trip.getElementsByTagName('Destination')[0]?.textContent || '',
          Pattern: trip.getElementsByTagName('Pattern')[0]?.textContent || '',
          DisplayDelayTime: trip.getElementsByTagName('DisplayDelayTime')[0]?.textContent || '',
          MinutesDelayTime: parseInt(trip.getElementsByTagName('MinutesDelayTime')[0]?.textContent || '0'),
          actualDisplayTime24: trip.getElementsByTagName('actualDisplayTime24')[0]?.textContent || '',
          actualDisplayTime12: trip.getElementsByTagName('actualDisplayTime12')[0]?.textContent || '',
          PatternFullDisplay: trip.getElementsByTagName('PatternFullDisplay')[0]?.textContent || '',
          Line: trip.getElementsByTagName('Line')[0]?.textContent || '',
          LineFull: trip.getElementsByTagName('LineFull')[0]?.textContent || '',
          Link: trip.getElementsByTagName('Link')[0]?.textContent || '',
          Platform: trip.getElementsByTagName('Platform')[0]?.textContent || '',
        }))
      };

      setServices(stationData);
    } catch (err) {
      console.error('Error fetching services:', err);
    }
  };

  // Initial fetch
  fetchServices();

  // Set up interval for updates every 10 seconds
  const interval = setInterval(fetchServices, 10000);

  // Cleanup interval on component unmount
  return () => clearInterval(interval);
}, [hardCodedStation, station]); // Empty dependency array means this effect runs once on mount


    // Select trips for the given platform
    const platformTrips = services?.Trips.filter(trip => trip.Platform === hardCodedPlatform) || [];
    const firstTrip = platformTrips[0];
    const secondTrip = platformTrips[1];

    const lineColor = firstTrip ? lineColors[firstTrip.Line] || "bg-gray-500" : "bg-gray-500";

  return (
    <PlatformDisplay 
      platformNumber={firstTrip ? parseInt(firstTrip.Platform.replace(/\D/g, '')) || 1 : 1} 
      currentDestination={firstTrip ? firstTrip.Destination : ''} 
      minutesToDestination={firstTrip ? Math.max(1, Math.floor((new Date(firstTrip.Actual).getTime() - new Date().getTime()) / 60000)) : 1}
      cars={firstTrip ? firstTrip.Ncar : 0} 
      pattern={firstTrip ? firstTrip.Patterncode : ''}
      headerBgColor={lineColor}
      AIRline={firstTrip?.Line === "AIR"}
      stops={firstTrip ? 
        firstTrip.PatternFullDisplay.split(', ').map(name => ({ name })) :
        []
      }
      nextService={secondTrip ? {
        destination: secondTrip.Destination,
        minutes: Math.max(1, Math.floor((new Date(secondTrip.Actual).getTime() - new Date().getTime()) / 60000)),
        pattern: secondTrip.Patterncode,
        isPlane: secondTrip.Line === "AIR"
      } : undefined}
      alert={{
        message: "",
        color: "text-yellow-300"
      }}
      currentTime={new Date().toLocaleTimeString('en-US', { 
        hour: 'numeric',
        minute: '2-digit',
        hour12: true 
      }).toLowerCase()}
    />
  );
};

export default Tracker;