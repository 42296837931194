import React, { useState, useEffect, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PlusCircle, 
  Star, 
  Search, 
  Layers,
  MapPin,
  Plane,
  TrendingUp,
  Users,
  ArrowRight,
  ExternalLink,
  Filter,
  X
} from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectItem } from '@/components/ui/select';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { useNavigate, Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import MovementNotificationContainer from '@/components/MovementNotification';

interface Location {
  id: string;
  latitude: number;
  longitude: number;
  title: string;
  description: string;
  bestEquipment: string;
  parkingInformation: string;
  securityInformation: string;
  communityRating: number;
  status: 'pending' | 'approved';
  airportICAO: string;
  extraInformation: string;
  openingHours: string;
  views?: number;
}

interface Airport {
  icao: string;
  name: string;
  spotCount: number;
  rating: number;
}

const LocationPage: React.FC = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [mapCenter, setMapCenter] = useState<[number, number]>([51.505, -0.09]);
  const [mapZoom] = useState(3);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [activeLayer, setActiveLayer] = useState('CartoDB Dark');
  const [isLayersVisible, setIsLayersVisible] = useState(false);
  const [popularAirports, setPopularAirports] = useState<Airport[]>(() => []);
  const [selectedAirport, setSelectedAirport] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [uniqueAirports, setUniqueAirports] = useState<Set<string>>(new Set());
  const navigate = useNavigate();

  // Modify the useEffect to calculate unique airports
  useEffect(() => {
    const savedLayer = localStorage.getItem('mapLayer') || 'CartoDB Dark';
    setActiveLayer(savedLayer);
    
    const fetchData = async () => {
      try {
        const [locationsRes, airportsRes] = await Promise.all([
          fetch('https://api.spottertools.org/api/locations'),
          fetch('https://api.spottertools.org/api/airports/popular')
        ]);
        
        const locationsData = await locationsRes.json();
        const airportsData = await airportsRes.json();
        
        const locationsArray = Object.entries(locationsData).map(([id, location]) => ({
          ...(location as Location),
          id,
        }));
        
        // Calculate unique airports
        const uniqueAirportSet = new Set(
          locationsArray.map(location => location.airportICAO)
            .filter(icao => icao && icao.trim() !== '')
        );
        setUniqueAirports(uniqueAirportSet);
        
        setLocations(locationsArray);
        setPopularAirports(airportsData);
        
        if (locationsArray.length > 0) {
          setMapCenter([locationsArray[0].latitude, locationsArray[0].longitude]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
  }, []);

  const customIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const renderStars = (rating: number) => {
    return Array(5).fill(0).map((_, i) => (
      <Star 
        key={i} 
        className={`h-4 w-4 ${i < rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`} 
      />
    ));
  };

  const filteredLocations = useMemo(() => {
    return locations.filter(location => {
      const matchesSearch = (
        (location.title && location.title.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (location.airportICAO && location.airportICAO.toLowerCase().includes(searchTerm.toLowerCase()))
      );

      const matchesAirport = !selectedAirport || 
        location.airportICAO.toLowerCase() === selectedAirport.toLowerCase();

      return matchesSearch && matchesAirport;
    });
  }, [locations, searchTerm, selectedAirport]);

  const resetFilters = () => {
    setSearchTerm('');
    setSelectedAirport('');
  };

  const popularLocations = useMemo(() => {
    return [...locations]
      .sort((a, b) => (b.views || 0) - (a.views || 0))
      .slice(0, 6);
  }, [locations]);

  const MapUpdater = () => {
    const map = useMap();
    useEffect(() => {
      if (selectedLocation) {
        map.setView([selectedLocation.latitude, selectedLocation.longitude], 12);
      }
    }, [map]);
    return null;
  };

  const mapLayers = [
    { name: 'OpenStreetMap', url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' },
    { name: 'CartoDB Light', url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png' },
    { name: 'CartoDB Dark', url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png' },
    { name: 'Satellite', url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' },
  ];

  const handleLayerChange = (layerName: string) => {
    setActiveLayer(layerName);
    localStorage.setItem('mapLayer', layerName);
  };

  const SearchControls = () => (
    <div className="mb-8">
      <Card className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm shadow-lg">
        <CardContent className="p-6">
          <div className="flex flex-col md:flex-row items-center gap-4">
            <div className="relative flex-grow">
              <Input
                type="text"
                placeholder="Search locations or ICAO codes..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            
            <div className="flex items-center gap-4 w-full md:w-auto">
              
              <Button
                variant="outline"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex-1 md:flex-none"
              >
                <Filter className="mr-2" />
                {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
              </Button>
              
              <Button
                variant="outline"
                onClick={resetFilters}
                className="flex-1 md:flex-none"
              >
                <X className="mr-2" />
                Reset
              </Button>
            </div>
          </div>

          <AnimatePresence>
            {isFilterOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-4"
              >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <Select defaultValue={selectedAirport} onChange={setSelectedAirport}>
                  <SelectItem value="">All Airports</SelectItem>
                  {Array.isArray(popularAirports) && popularAirports.map(airport => (
                    <SelectItem key={airport.icao} value={airport.icao}>
                    {airport.icao} - {airport.name}
                    </SelectItem>
                  ))}
                  </Select>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <h1 className="text-5xl md:text-6xl font-extrabold pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-400 dark:to-blue-500">
            Spotting Locations
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400 max-w-3xl mx-auto">
            Discover and share the best aircraft spotting locations around the world
          </p>
        </motion.div>

        <SearchControls />

        <Card className="mb-12 overflow-hidden shadow-lg">
          <CardContent className="p-0">
            <div className="relative h-[600px]">
              <MapContainer 
                center={mapCenter} 
                zoom={mapZoom} 
                style={{ height: '100%', width: '100%' }}
                zoomControl={false}
                className="z-0"
                minZoom={2}
                maxBounds={[[-90, -180], [90, 180]]}
              >
                {mapLayers.map((layer) => (
                  <TileLayer
                    key={layer.name}
                    url={layer.url}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    opacity={activeLayer === layer.name ? 1 : 0}
                  />
                ))}
                <ZoomControl position="bottomright" />
                <MapUpdater />
                {filteredLocations.map((location) => (
                  <Marker 
                    key={location.id} 
                    position={[location.latitude, location.longitude]} 
                    icon={customIcon}
                    eventHandlers={{ click: () => setSelectedLocation(location) }}
                  >
                    <Popup>
                      <div className="text-gray-800 max-w-sm">
                        <h3 className="font-bold text-lg mb-2">{location.title}</h3>
                        <p className="text-sm mb-3">{location.description.substring(0, 100)}...</p>
                        <div className="flex items-center mb-3">
                          <span className="text-sm font-semibold mr-2">Rating:</span>
                          <div className="flex">{renderStars(location.communityRating)}</div>
                        </div>
                        <Button 
                          onClick={() => navigate(`/location/${location.id}`)}
                          className="w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white"
                        >
                          View Details
                        </Button>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>

              {/* Map Layer Controls */}
              <div className="absolute bottom-4 left-4 z-10">
                <div className="relative">
                  <Button
                    variant="outline"
                    size="icon"
                    className="bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm shadow-lg"
                    onClick={() => setIsLayersVisible(!isLayersVisible)}
                  >
                    <Layers className="h-5 w-5" />
                  </Button>
                  <AnimatePresence>
                    {isLayersVisible && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        className="absolute bottom-full left-0 mb-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-2 w-48"
                      >
                        {mapLayers.map((layer) => (
                          <Button
                            key={layer.name}
                            variant={activeLayer === layer.name ? "default" : "ghost"}
                            className={`w-full mb-1 justify-start text-sm ${
                              activeLayer === layer.name
                                ? 'bg-blue-500 text-white dark:bg-blue-600 dark:text-white'
                                : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                            }`}
                            onClick={() => handleLayerChange(layer.name)}
                          >
                            {layer.name}
                          </Button>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Popular Locations Section */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <Card className="h-full">
              <CardHeader>
                <CardTitle className="flex items-center text-2xl text-gray-900 dark:text-white">
                  <TrendingUp className="mr-2 h-6 w-6 text-blue-500" />
                  Popular Locations
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  {popularLocations.map((location) => (
                    <motion.div
                      key={location.id}
                      whileHover={{ scale: 1.02 }}
                      className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors dark:text-white"
                    >
                      <Link 
                        to={`/location/${location.id}`}
                        className="flex items-center justify-between"
                      >
                        <div>
                          <h3 className="font-semibold text-lg mb-1">{location.title}</h3>
                          <div className="flex items-center text-sm text-gray-500">
                            <MapPin className="h-4 w-4 mr-1" />
                            {location.airportICAO}
                            <span className="mx-2">•</span>
                            <div className="flex">{renderStars(location.communityRating)}</div>
                          </div>
                        </div>
                        <ArrowRight className="h-5 w-5 text-blue-500" />
                      </Link>
                    </motion.div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </motion.div>

          {/* Popular Airports Section */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <Card className="h-full">
              <CardHeader>
                <CardTitle className="flex items-center text-2xl dark:text-white">
                  <Plane className="mr-2 h-6 w-6 text-blue-500 " />
                  Popular Airports
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  {Array.isArray(popularAirports) && popularAirports.map((airport) => (
                    <motion.div
                      key={airport.icao}
                      whileHover={{ scale: 1.02 }}
                      className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                    >
                      <Link 
                        to={`/airport/${airport.icao}`}
                        className="flex items-center justify-between"
                      >
<div>
                          <h3 className="font-semibold text-lg mb-1">{airport.name}</h3>
                          <div className="flex items-center text-sm text-gray-500">
                            <Users className="h-4 w-4 mr-1" />
                            {airport.spotCount} spotting locations
                            <span className="mx-2">•</span>
                            <div className="flex">{renderStars(airport.rating)}</div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <span className="text-blue-500 font-semibold mr-2">{airport.icao}</span>
                          <ExternalLink className="h-4 w-4 text-blue-500" />
                        </div>
                      </Link>
                    </motion.div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12"
        >
          <Card className="bg-gradient-to-br from-blue-500/10 to-purple-500/10 dark:from-blue-500/5 dark:to-purple-500/5">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Locations</p>
                  <h4 className="text-3xl font-bold text-gray-900 dark:text-gray-100">{locations.length}</h4>
                </div>
                <div className="p-3 bg-blue-500/20 rounded-full">
                  <MapPin className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gradient-to-br from-green-500/10 to-emerald-500/10 dark:from-green-500/5 dark:to-emerald-500/5">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Airports Covered</p>
                  <h4 className="text-3xl font-bold text-gray-900 dark:text-gray-100">{uniqueAirports.size}</h4>
                </div>
                <div className="p-3 bg-green-500/20 rounded-full">
                  <Plane className="h-6 w-6 text-green-600 dark:text-green-400" />
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gradient-to-br from-yellow-500/10 to-orange-500/10 dark:from-yellow-500/5 dark:to-orange-500/5">
            <CardContent className="p-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Active Contributors</p>
                  <h4 className="text-3xl font-bold text-gray-900 dark:text-gray-100">250+</h4>
                </div>
                <div className="p-3 bg-yellow-500/20 rounded-full">
                  <Users className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
                </div>
              </div>
            </CardContent>
          </Card>
        </motion.div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-12"
        >
          <Card className="bg-gradient-to-r from-blue-500 to-purple-600 text-white">
            <CardContent className="p-12">
              <h2 className="text-3xl font-bold mb-4">Share Your Favorite Spotting Location</h2>
              <p className="text-lg mb-8 opacity-90">
                Help grow the community by sharing your knowledge with fellow aviation enthusiasts.
              </p>
              <Button
                onClick={() => navigate('/add-location')}
                className="flex-1 md:flex-none bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white"
              >
                <PlusCircle className="mr-2 h-5 w-5" />
                Add Location
              </Button>
            </CardContent>
          </Card>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default LocationPage;