import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { Mail, Lock, User, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import MovementNotificationContainer from '@/components/MovementNotification';

const SignupSigninPage: React.FC = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const storeToken = (token: string) => {
    if (token && token.split('.').length === 3) {
      localStorage.setItem('token', token);
    } else {
      console.error('Received malformed token:', token);
      toast.error('Authentication error. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (isSignUp && password !== confirmPassword) {
      toast.error("Passwords don't match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`https://api.spottertools.org/api/${isSignUp ? 'signup' : 'signin'}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(isSignUp ? { email, username, password } : { email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'An error occurred');
      }

      storeToken(data.token);
      toast.success(isSignUp ? 'Account created successfully!' : 'Logged in successfully!');
      
      setTimeout(() => {
        navigate('/profile');
      }, 1500);
    } catch (err) {
      toast.error((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Toaster position="top-right" />
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      <main className="flex-grow container mx-auto px-4 pt-20 py-12 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md"
        >
          <Card className="backdrop-blur-md bg-white/90 dark:bg-gray-800/90 shadow-2xl rounded-3xl overflow-hidden">
            <CardContent className="p-8">
              <motion.div
                initial={{ scale: 0.9, rotateY: 0 }}
                animate={{ scale: 1, rotateY: 360 }}
                transition={{ duration: 0.5 }}
                className="mb-8"
              >
                <User className="w-20 h-20 mx-auto text-blue-500 dark:text-blue-400" />
              </motion.div>
              <h2 className="text-3xl font-bold mb-6 text-center text-gray-800 dark:text-gray-100">
                {isSignUp ? 'Create an Account' : 'Welcome Back'}
              </h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-4">
                  <div className="relative">
                    <Mail className="absolute top-3 left-3 w-5 h-5 text-gray-400" />
                    <Input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="pl-10 bg-white/50 dark:bg-gray-700/50 border-2 focus:ring-2 focus:ring-blue-500 rounded-full"
                    />
                  </div>
                  {isSignUp && (
                    <div className="relative">
                      <User className="absolute top-3 left-3 w-5 h-5 text-gray-400" />
                      <Input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        className="pl-10 bg-white/50 dark:bg-gray-700/50 border-2 focus:ring-2 focus:ring-blue-500 rounded-full"
                      />
                    </div>
                  )}
                  <div className="relative">
                    <Lock className="absolute top-3 left-3 w-5 h-5 text-gray-400" />
                    <Input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="pl-10 bg-white/50 dark:bg-gray-700/50 border-2 focus:ring-2 focus:ring-blue-500 rounded-full"
                    />
                  </div>
                  <AnimatePresence>
                    {isSignUp && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className="relative"
                      >
                        <Lock className="absolute top-3 left-3 w-5 h-5 text-gray-400" />
                        <Input
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                          className="pl-10 bg-white/50 dark:bg-gray-700/50 border-2 focus:ring-2 focus:ring-blue-500 rounded-full"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <Button 
                  type="submit" 
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-300 text-lg py-3 rounded-full"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="flex items-center justify-center">
                      <Loader className="animate-spin mr-2" />
                      Processing...
                    </span>
                  ) : (
                    isSignUp ? 'Sign Up' : 'Sign In'
                  )}
                </Button>
              </form>
              <p className="mt-6 text-center text-gray-600 dark:text-gray-300">
                {isSignUp ? 'Already have an account?' : "Don't have an account?"}
                <Button
                  variant="link"
                  onClick={() => setIsSignUp(!isSignUp)}
                  className="ml-1 text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 font-semibold"
                >
                  {isSignUp ? 'Sign In' : 'Sign Up'}
                </Button>
              </p>
            </CardContent>
          </Card>
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default SignupSigninPage;