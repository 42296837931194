import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { Check } from 'lucide-react';

interface AnimatedPopupProps {
  title: string;
  message: string;
  icon: React.ReactNode;
  actionText: string;
  onAction: () => void;
  iconComponents?: React.ElementType[];
}

const AnimatedPopup: React.FC<AnimatedPopupProps> = ({
  title,
  message,
  icon,
  actionText,
  onAction,
  iconComponents = []
}) => {
  const controls = useAnimation();
  
  interface Particle {
    id: number;
    x: number;
    y: number;
    size: number;
  }

  const [particles, setParticles] = useState<Particle[]>([]);

  useEffect(() => {
    controls.start(i => ({
      y: [0, -20, 0],
      transition: { 
        duration: 1.5,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: i * 0.2
      }
    }));

    setParticles(Array.from({ length: 100 }, (_, i) => ({
      id: i,
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 4 + 1
    })));
  }, [controls]);

  return (
    <div className="relative w-full h-screen overflow-hidden backdrop-blur-lg">
      {particles.map((particle) => (
        <motion.div
          key={particle.id}
          className="absolute rounded-full bg-white"
          style={{
            left: `${particle.x}%`,
            top: `${particle.y}%`,
            width: particle.size,
            height: particle.size
          }}
          animate={{
            y: [`${particle.y}%`, `${particle.y - 10}%`],
            opacity: [0, 1, 0]
          }}
          transition={{
            duration: Math.random() * 2 + 1,
            repeat: Infinity,
            repeatType: 'loop'
          }}
        />
      ))}

      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div
          className="bg-white dark:bg-gray-800 rounded-lg p-8 flex flex-col items-center relative"
          initial={{ scale: 0.5, rotate: -10 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <motion.div
            className="text-6xl mb-6"
            animate={{ 
              rotateY: 360,
              scale: [1, 1.2, 1],
            }}
            transition={{ 
              rotateY: { duration: 1.5, repeat: Infinity, repeatType: "loop" },
              scale: { duration: 1, repeat: Infinity, repeatType: "reverse" }
            }}
          >
            {icon}
          </motion.div>
          
          <motion.h2
            className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-100"
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            {title}
          </motion.h2>
          
          {iconComponents.length > 0 && (
            <motion.div className="flex justify-center space-x-4 mb-6">
              {iconComponents.map((Icon, index) => (
                <motion.div
                  key={index}
                  className="bg-blue-500 rounded-full p-3"
                  initial={{ scale: 0 }}
                  animate={controls}
                  custom={index}
                >
                  <Icon className="text-white h-8 w-8" />
                </motion.div>
              ))}
            </motion.div>
          )}
          
          <motion.div
            className="text-green-500 flex items-center"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 1.2, type: "spring" }}
          >
            <Check className="mr-2 h-6 w-6" />
            <span className="text-xl">{message}</span>
          </motion.div>
          
          <motion.button
            className="mt-8 px-6 py-2 bg-blue-500 text-white rounded-full font-semibold"
            whileHover={{ scale: 1.05, boxShadow: "0px 0px 8px rgb(59,130,246)" }}
            whileTap={{ scale: 0.95 }}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            onClick={onAction}
          >
            {actionText}
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default AnimatedPopup;