import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { Plane, Heart } from 'lucide-react';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <motion.footer 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="relative bg-gradient-to-r from-blue-50 to-purple-50 dark:from-gray-900 dark:to-gray-800"
    >
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col items-center space-y-4">
          <div className="flex items-center">
            <Plane className="h-6 w-6 text-blue-600 dark:text-blue-400 transform" />
            <span className="hidden sm:block ml-3 text-2xl font-extrabold text-transparent bg-clip-text
                                         bg-gradient-to-r from-blue-500 to-blue-700 dark:from-blue-400 dark:to-blue-600">
                                SpotterTools.Org
                            </span>
          </div>

          <div className="flex space-x-4">
            <Button
              variant="link"
              onClick={() => navigate('/privacy')}
              className="text-gray-600 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400"
            >
              Privacy
            </Button>
            <Button
              variant="link"
              onClick={() => navigate('/terms')}
              className="text-gray-600 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400"
            >
              Terms
            </Button>
            <Button
              variant="link"
              onClick={() => navigate('/contact')}
              className="text-gray-600 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400"
            >
              Contact
            </Button>
          </div>

          <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
            <span>&copy; 2024 SpotterTools.org</span>
            <Heart className="h-3 w-3 mx-2 text-red-500" />
            <span>Made for aviation enthusiasts</span>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};

export default Footer;