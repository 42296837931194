import React from 'react';
import { motion } from 'framer-motion';
import { Plane } from 'lucide-react';
import { Button } from '@/components/ui/button';
import Header from '@/components/ui/header';
import { useNavigate } from 'react-router-dom';
import Footer from '@/components/ui/footer';

const ComingSoonPage: React.FC = () => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />

      <main className="container mx-auto px-6 py-16 text-center">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="max-w-3xl mx-auto"
        >
          <motion.h1
            variants={itemVariants}
            className="text-4xl md:text-5xl font-extrabold mb-6 text-gray-900 dark:text-gray-100"
          >
            Coming Soon!
          </motion.h1>

          <motion.p
            variants={itemVariants}
            className="text-lg md:text-xl mb-10 text-gray-700 dark:text-gray-300"
          >
            Exciting new features are on the way. Stay tuned for updates!
          </motion.p>

          <motion.div variants={itemVariants}>
            <Plane className="w-20 h-20 text-blue-600 dark:text-blue-400 mx-auto mb-6" />
          </motion.div>

          <motion.div variants={itemVariants}>
            <p className="text-md text-gray-700 dark:text-gray-300 mb-6">
              Meanwhile, check out our current features below:
            </p>
            <Button
              variant="outline"
              onClick={() => navigate('/')}
              className="text-blue-600 dark:text-blue-400 border-blue-600 dark:border-blue-400 hover:bg-blue-50 dark:hover:bg-gray-700 py-2 px-8 font-medium"
            >
              Back to Home
            </Button>
          </motion.div>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

export default ComingSoonPage;