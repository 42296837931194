import React, { useEffect, useState } from 'react';

interface Stop {
  name: string;
}

interface NextService {
  destination: string;
  minutes: number;
  pattern: string;
  isPlane?: boolean;
}


interface AlertMessage {
  message: string;
  color?: string;
}

interface PlatformDisplayProps {
    platformNumber: number;
    currentDestination: string;
    minutesToDestination: number;
    cars: number;
    pattern: string;
    stops: Stop[];
    nextService?: NextService;
    currentTime: string;
    alert?: AlertMessage;
    headerBgColor?: string; // Optional prop
    AIRline?: boolean; // Optional prop
}

const PlatformDisplay: React.FC<PlatformDisplayProps> = ({
    platformNumber,
    currentDestination,
    minutesToDestination,
    cars,
    pattern,
    stops,
    nextService,
    alert,
    headerBgColor,
    AIRline
}) => {
    // Add state for time
    const [time, setTime] = useState(new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    }).toLowerCase());

    // Add effect to update time every second
    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date().toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            }).toLowerCase());
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, []);
  // Helper function to chunk stops into rows
  const chunkStops = (stops: Stop[], itemsPerRow: number) => {
    return stops.reduce((rows: Stop[][], stop: Stop, index: number) => {
      if (index % itemsPerRow === 0) rows.push([]);
      rows[Math.floor(index / itemsPerRow)].push(stop);
      return rows;
    }, []);
  };

  // Group stops into rows of 3
  const stopsRows = chunkStops(stops, 3);

  return (
    <div className="w-[800px] bg-black pb-1 font-['SaaSeriesEMDOT'] text-white">
    <style>
        {`
            @font-face {
            font-family: 'SaaSeriesEMDOT';
            src: url('/fonts/SaaSeriesEMDOT.woff2') format('woff2'),
                url('/fonts/SaaSeriesEMDOT.woff') format('woff'),
                url('/fonts/SaaSeriesEMDOT.ttf') format('truetype');
            font-weight: 200;
            font-style: light;
            }
        }`}
    </style>
        {/* Header Row */}
        <div className={`flex justify-between items-center px-5 py-1`} style={{ backgroundColor: headerBgColor || '#808080' }}>
            <div className="text-3xl">Platform {platformNumber}</div>
            <div className="flex space-x-6 text-xl mt-2">
                <span>Mins</span>
                <span>Pattern</span>
                <span>Cars</span>
            </div>
        </div>

        {/* Destination Row */}
        <div className="flex justify-between items-center px-4 py-3 text-4xl font- border-b-2 border-whte-600">
            <div className="flex items-center">
            <div className="text-5xl -mt-4 pt-3 pb-2" style={minutesToDestination === 1 ? { animation: 'blink-orange-white 1.5s step-start 0s infinite' } : undefined}>
                <style>
                {`
                @keyframes blink-orange-white {
                    50% { color: orange; }
                    0%, 100% { color: white; }
                }`}
                </style>
                {currentDestination}
            </div>
            {AIRline && (
                <span className="inline-block bg-blue-700 ml-6 -mt-2">
                <svg className="w-8 h-8 text-white rotate-[-45deg]" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z"/>
                </svg>
                </span>
            )}
            </div>
            <div className="flex text-5xl pt-1 space-x-6">
                <span 
                    className="w-14 pr-3 -mt-4 text-right align-top"
                    style={minutesToDestination === 1 ? { animation: 'blink-orange-white 1.5s step-start 0s infinite' } : undefined}
                >
                    {minutesToDestination ?? '-'}
                </span>
                <span className="w-14 pr-3 -mt-4 text-right align-top">{pattern ?? '-'}</span>
                <span className="w-14 pr-2 -mt-4 text-right align-top">{cars ?? '-'}</span>
            </div>
        </div>

        {/* Stops Section */}
        <div className="px-4 py-3 border-b-2 border-white-600">
            <div className="flex">
                <div className="w-24 text-3xl">
                    Stops<br />
                    <span className="float-right mr-9">at:</span>
                </div>
                <div className="flex-1 text-4xl font-light">
                    {stopsRows.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                            {row.map((stop, index) => (
                                <React.Fragment key={stop.name}>
                                    {stop.name}
                                    {index < row.length - 1 && ', '}
                                </React.Fragment>
                            ))}
                            {rowIndex < stopsRows.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>

        {nextService && (
                <div className="px-4 py-3 border-b-2 border-gray-600">
                    <div className="flex justify-between">
                        <div className="space-y-1">
                            <div className="text-2xl mt-1">Then:</div>
                            <div className="flex items-center space-x-1">
                                <span className="text-3xl -mt-4">{nextService.destination}</span>
                                {nextService.isPlane && (
                                    <span className="inline-flex items-center">
                                        <span className="inline-block bg-blue-700 ml-36 -mt-4">
                                            <svg className="w-8 h-8 text-white rotate-[-45deg]" viewBox="0 0 24 24" fill="currentColor">
                                                <path d="M21 16v-2l-8-5V3.5c0-.83-.67-1.5-1.5-1.5S10 2.67 10 3.5V9l-8 5v2l8-2.5V19l-2 1.5V22l3.5-1 3.5 1v-1.5L13 19v-5.5l8 2.5z"/>
                                            </svg>
                                        </span>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="flex space-x-5 ml-auto mr-12">
                            <div className="flex flex-col items-start">
                                <div className="text-2xl text-gray-200">Mins</div>
                                <div className="text-2xl">{nextService.minutes}</div>
                            </div>
                            <div className="flex flex-col">
                                <div className="text-2xl text-gray-200">Pattern</div>
                                <div className="text-2xl text-center">{nextService.pattern}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        {/* Alert and Time Row */}
        <div className="mt-0 px-4  bg-black flex justify-between items-center">
            <div className="flex-1 relative overflow-hidden h-10">
                {alert && (
                    <div 
                        className={`${alert.color || 'text-yellow-300'} font- text-2xl py-1 absolute w-max whitespace-nowrap`}
                        style={{ animation: 'scroll-text 20s linear infinite' }}
                    >
                        {alert.message}
                    </div>
                )}
                <style>
                    {`
                    @keyframes scroll-text {
                        0% { transform: translateX(100%); }
                        100% { transform: translateX(-100%); }
                    }`}
                </style>
            </div>
            <div className="text-3xl font- ml-8">
                {time}
            </div>
        </div>
    </div>

        );
    };

export default PlatformDisplay;