import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, AlertTriangle, ChevronDown, Info, BarChart2, Calendar, Search, Filter, X } from 'lucide-react';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Select, SelectItem } from '@/components/ui/select';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import MovementDetailsPage from './MovementDetailsPage';
import HyperlinkText from '@/components/ui/HyperLinkText';

interface Movement {
  id: string;
  type: string;
  title: string;
  details: string;
  date: string;
  airportIcao: string;
}

const AircraftMovements: React.FC = () => {
  const [movements, setMovements] = useState<Movement[]>([]);
  const [filteredMovements, setFilteredMovements] = useState<Movement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showMore, setShowMore] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState<Movement | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [selectedAirport, setSelectedAirport] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const sortMovements = useCallback((movements: Movement[]): Movement[] => {
    return movements.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      const now = new Date();
      now.setUTCHours(0, 0, 0, 0);

      if (isToday(a.date) && !isToday(b.date)) return -1;
      if (!isToday(a.date) && isToday(b.date)) return 1;
      if (isToday(a.date) && isToday(b.date)) return 0;

      if (aDate >= now && bDate < now) return -1;
      if (aDate < now && bDate >= now) return 1;

      return bDate.getTime() - aDate.getTime();
    });
  }, []);

  useEffect(() => {
    const fetchMovements = async () => {
      try {
        const response = await fetch(`https://api.spottertools.org/api/movements`);
        if (!response.ok) {
          throw new Error('Failed to fetch movements');
        }
        const data = await response.json();
        const movementsArray = Object.values(data).map((item: any) => ({
          id: item.id,
          type: item.type,
          title: item.title,
          details: item.details,
          date: item.date,
          airportIcao: item.airportIcao,
        })) as Movement[];
        if (Array.isArray(movementsArray)) {
          setMovements(movementsArray);
          setFilteredMovements(sortMovements(movementsArray));
        } else {
          throw new Error('Fetched data is not an array');
        }        
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchMovements();
    const interval = setInterval(fetchMovements, 300000); // 300000 ms = 5 minutes
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [sortMovements]);

  useEffect(() => {
    // Check for openMovement query parameter
    const searchParams = new URLSearchParams(location.search);
    const openMovementId = searchParams.get('openMovement');
    if (openMovementId) {
      const movement = movements.find(m => m.id === openMovementId);
      if (movement) {
        setSelectedMovement(movement);
        setIsDetailsOpen(true);
      }
    }
  }, [location.search, movements]);

  useEffect(() => {
    // Filter movements based on search and filter criteria
    let filtered = movements;

    if (searchTerm) {
      filtered = filtered.filter(m => 
        m.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        m.details.toLowerCase().includes(searchTerm.toLowerCase()) ||
        m.airportIcao.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedType !== 'all') {
      filtered = filtered.filter(m => m.type === selectedType);
    }

    if (selectedAirport) {
      filtered = filtered.filter(m => 
        m.airportIcao.toLowerCase().includes(selectedAirport.toLowerCase())
      );
    }

    setFilteredMovements(sortMovements(filtered));
  }, [movements, searchTerm, selectedType, selectedAirport, sortMovements]);

  const isInPast = (date: string) => {
    const movementDate = new Date(date);
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    return movementDate < currentDate;
  };
  
  const isInFuture = (date: string) => {
    const movementDate = new Date(date);
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    return movementDate > currentDate;
  };
  
  const isToday = (date: string) => {
    const movementDate = new Date(date);
    const currentDate = new Date();
    return (
      movementDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
      movementDate.getUTCMonth() === currentDate.getUTCMonth() &&
      movementDate.getUTCDate() === currentDate.getUTCDate()
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  const getStatistics = () => {
    const total = filteredMovements.length;
    const important = filteredMovements.filter(m => m.type === 'important').length;
    const normal = total - important;
    return { total, important, normal };
  };

  const stats = getStatistics();

  const resetFilters = () => {
    setSearchTerm('');
    setSelectedType('all');
    setSelectedAirport('');
    isFilterOpen && setIsFilterOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-5xl md:text-6xl font-extrabold pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-400 dark:to-blue-500">
            Aircraft Movements & News
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 dark:text-gray-400 max-w-3xl mx-auto">
            Stay updated with the latest aircraft movements, emergencies, and aviation news from around the world.
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12"
        >
          <Card className="bg-green-100 dark:bg-green-900 border-green-300 dark:border-green-700 transform hover:scale-105 transition-transform duration-300">
            <CardContent className="p-6 flex items-center justify-between">
              <div>
                <h3 className="text-2xl font-bold text-green-700 dark:text-green-300">Total Movements</h3>
                <p className="text-4xl font-extrabold text-green-600 dark:text-green-400">{stats.total}</p>
              </div>
              <BarChart2 className="h-12 w-12 text-green-500 dark:text-green-400" />
            </CardContent>
          </Card>
          <Card className="bg-blue-100 dark:bg-blue-900 border-blue-300 dark:border-blue-700 transform hover:scale-105 transition-transform duration-300">
            <CardContent className="p-6 flex items-center justify-between">
              <div>
                <h3 className="text-2xl font-bold text-blue-700 dark:text-blue-300">Interesting Flights</h3>
                <p className="text-4xl font-extrabold text-blue-600 dark:text-blue-400">{stats.normal}</p>
              </div>
              <Plane className="h-12 w-12 text-blue-500 dark:text-blue-400" />
            </CardContent>
          </Card>
          <Card className="bg-red-100 dark:bg-red-900 border-red-300 dark:border-red-700 transform hover:scale-105 transition-transform duration-300">
            <CardContent className="p-6 flex items-center justify-between">
              <div>
                <h3 className="text-2xl font-bold text-red-700 dark:text-red-300">Important</h3>
                <p className="text-4xl font-extrabold text-red-600 dark:text-red-400">{stats.important}</p>
              </div>
              <AlertTriangle className="h-12 w-12 text-red-500 dark:text-red-400" />
            </CardContent>
          </Card>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <div className="relative w-full md:w-1/2">
              <Input
                type="text"
                placeholder="Search movements..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 w-full"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
            <div className="flex items-center gap-4 w-full md:w-auto">
              <Button
                variant="outline"
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center"
              >
                <Filter className="mr-2" />
                {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
              </Button>
              <Button
                variant="outline"
                onClick={resetFilters}
                className="flex items-center"
              >
                <X className="mr-2" />
                Reset Filters
              </Button>
            </div>
          </div>
          <AnimatePresence>
            {isFilterOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4"
              >
                <Select defaultValue={selectedType} onChange={setSelectedType} className='select'>
                  <SelectItem value="all">All Types</SelectItem>
                  <SelectItem value="movement">Interesting</SelectItem>
                  <SelectItem value="important">Important</SelectItem>
                </Select>
                <Input
                  type="text"
                  placeholder="Enter Airport ICAO"
                  value={selectedAirport}
                  onChange={(e) => setSelectedAirport(e.target.value)}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        
        {loading ? (
          <div className="flex flex-col justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-blue-500"></div>
            <p className="mt-4 text-xl text-gray-600 dark:text-gray-400">Loading movements...</p>
          </div>
        ) : error ? (
          <Card className="bg-red-100 dark:bg-red-900 border-red-300 dark:border-red-700 shadow-lg">
            <CardContent className="p-8 text-center">
              <AlertTriangle className="mx-auto mb-6 h-16 w-16 text-red-600 dark:text-red-400" />
              <h2 className="text-3xl font-bold mb-4 text-red-700 dark:text-red-300">Error</h2>
              <p className="text-xl text-red-600 dark:text-red-400">
                {error === "Cannot convert undefined or null to object" ? "No movements found" : error}
              </p>
            </CardContent>
          </Card>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid gap-8 md:grid-cols-2 lg:grid-cols-3"
          >
            <AnimatePresence>
              {filteredMovements.slice(0, showMore ? filteredMovements.length : 6).map((item, index) => (
                <motion.div key={index} variants={itemVariants} layout>
                  <Card 
                    className={`overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-1 ${
                      item.type === 'important'
                        ? 'border-l-8 border-red-500 bg-red-50 dark:bg-red-900/30'
                        : 'border-l-8 border-blue-500 bg-white dark:bg-gray-800'
                    }`}
                  >
                    <CardContent className="p-6">
                      <div className="flex items-center mb-4">
                        {item.type === 'important' ? (
                          <AlertTriangle className="text-red-500 dark:text-red-400 mr-3 h-8 w-8" />
                        ) : (
                          <Plane className="text-blue-500 dark:text-blue-400 mr-3 h-8 w-8" />
                        )}
                        <h3 className="text-2xl font-bold truncate">{item.title}</h3>
                      </div>
                        <HyperlinkText text={item.details} className="text-gray-600 dark:text-gray-300 mb-6 line-clamp-3" />
                      <div className="flex justify-between items-center">
                        <div className="flex items-center text-sm font-medium text-gray-500 dark:text-gray-400">
                          <div className="flex items-center">
                            <Calendar className="mr-2 h-5 w-5 text-gray-500 dark:text-gray-400" />
                            <span>{item.date}</span>
                          </div>
                          <span className="mx-2">|</span>
                          <div className="flex items-center">
                            <Plane className="mr-2 h-5 w-5 text-gray-500 dark:text-gray-400" />
                            <Link 
                              to={`/airport/${item.airportIcao}`}
                              className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 transition-colors duration-300"
                            >
                              {item.airportIcao}
                            </Link>
                          </div>
                        </div>
                        <Button 
                          variant="outline" 
                          size="sm" 
                          className="hover:bg-blue-100 dark:hover:bg-blue-900 transition-colors duration-300"
                          onClick={() => {
                            setSelectedMovement(item);
                            setIsDetailsOpen(true);
                            // Update URL with movement ID
                            navigate(`/movements?openMovement=${item.id}`, { replace: true });
                          }}
                        >
                          View Details
                        </Button>
                      </div>
                      <div className="mt-2">
                        {isToday(item.date) && (
                          <span className="inline-block px-2 py-1 text-xs font-semibold text-green-800 bg-green-200 rounded-full mr-2">Today</span>
                        )}
                        {isInFuture(item.date) && (
                          <span className="inline-block px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full mr-2">Future</span>
                        )}
                        {isInPast(item.date) && (
                          <span className="inline-block px-2 py-1 text-xs font-semibold text-red-800 bg-red-200 rounded-full mr-2">Past</span>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>          
        )}

        {!loading && !error && filteredMovements.length > 6 && (
          <motion.div 
            className="text-center mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <Button
              onClick={() => setShowMore(!showMore)}
              variant="outline"
              size="lg"
              className="group transition-all duration-300 hover:bg-blue-100 dark:hover:bg-blue-900 rounded-full px-8 py-3 text-lg font-semibold"
            >
              {showMore ? 'Show Less' : 'View More'}
              <ChevronDown className={`ml-2 h-5 w-5 transition-transform duration-300 ${showMore ? 'rotate-180' : ''}`} />
            </Button>
          </motion.div>
        )}

        {!loading && !error && filteredMovements.length === 0 && (
          <Card className="mt-12 bg-blue-50 dark:bg-blue-900/30 border-blue-200 dark:border-blue-700 shadow-lg">
            <CardContent className="p-8 text-center">
              <Info className="mx-auto mb-6 h-16 w-16 text-blue-500 dark:text-blue-400" />
              <h2 className="text-3xl font-bold mb-4 text-blue-700 dark:text-blue-300">No Movements Found</h2>
              <p className="text-xl text-blue-600 dark:text-blue-400">There are currently no aircraft movements matching your criteria.</p>
            </CardContent>
          </Card>
        )}

        <AnimatePresence>
          {isDetailsOpen && selectedMovement && (
            <MovementDetailsPage
              movement={selectedMovement}
              onClose={() => {
                setIsDetailsOpen(false);
                setSelectedMovement(null);
                // Remove the openMovement query parameter from the URL
                navigate('/movements', { replace: true });
              }}
            />
          )}
        </AnimatePresence>
      </main>
      
      <Footer />
    </div>
  );
};

export default AircraftMovements;