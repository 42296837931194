import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { Icon } from 'leaflet';
import { motion, AnimatePresence } from 'framer-motion';
import { Camera, SquareParking, Shield, Send, MapPin, Check, X, Plane, Clock, ExternalLink, AlertCircle } from 'lucide-react';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import { Checkbox } from '@/components/ui/checkbox';
import Papa from 'papaparse';
import 'leaflet/dist/leaflet.css';
import  AnimatedPopup from '@/components/animations/Popup';
import MovementNotificationContainer from '@/components/MovementNotification';
interface LocationSubmission {
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  bestEquipment: string;
  parkingInformation: string;
  securityInformation: string;
  airportICAO: string;
  extraInformation: string;
  openingHours?: string;
}

const LocationSubmissionPage: React.FC = () => {
  const [submission, setSubmission] = useState<LocationSubmission>({
    title: '',
    description: '',
    latitude: 51.505,
    longitude: -0.09,
    bestEquipment: '',
    parkingInformation: '',
    securityInformation: '',
    airportICAO: '',
    extraInformation: '',
    openingHours: '',
  });
  const [mapCenter, setMapCenter] = useState<[number, number]>([0,0]);
  const [mapZoom] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const [airportValid, setAirportValid] = useState<boolean | null>(null);
  const [runwaysData, setRunwaysData] = useState<any[]>([]);
  const [guideRead, setGuideRead] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Load and parse the CSV file
    fetch('/data/runways.csv')
      .then(response => response.text())
      .then(csvString => {
        const results = Papa.parse(csvString, { header: true, skipEmptyLines: true });
        setRunwaysData(results.data);
      })
      .catch(error => {
        console.error('Error loading runway data:', error);
        toast.error('Failed to load runway data. Please try again.');
      });

    if (localStorage.getItem('GuideSeen') !== 'true') {
      navigate('/guide');
    }

    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setSubmission(prev => ({ ...prev, latitude, longitude }));
          setMapCenter([latitude, longitude]);
        },
        () => {
          toast.error("Couldn't get your location. Using default.");
        }
      );
    }
  }, [navigate]);

  useEffect(() => {
    // Validate form
    const isValid = 
      submission.title.trim() !== '' &&
      submission.description.trim() !== '' &&
      submission.airportICAO.trim() !== '' &&
      airportValid === true &&
      guideRead;
    setFormValid(isValid);
  }, [submission, airportValid, guideRead]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setSubmission(prev => ({ ...prev, [name]: name === 'airportICAO' ? value.toUpperCase() : value }));

    if (name === 'airportICAO') {
      validateAirport(value.toUpperCase());
    }
  };

  const validateAirport = (icao: string) => {
    const airport = runwaysData.find(runway => runway.airport_ident.toUpperCase() === icao.toUpperCase());
    setAirportValid(!!airport);
  };

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return token && token.split('.').length === 3 ? { 'Authorization': `Bearer ${token}` } : {};
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formValid) {
      toast.error('Please fill in all required fields and confirm you have read the guide.');
      return;
    }

    // Check if the location is outside of maxBounds
    const { latitude, longitude } = submission;
    if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
      toast.error('Location is outside of valid bounds. Please select a valid location.');
      return;
    }

    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No authentication token found. Please log in again.');
      
      const response = await fetch('https://api.spottertools.org/api/locations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getAuthHeaders()['Authorization'] || '',
        },
        body: JSON.stringify(submission)
      });
  
      if (response.ok) {
        setShowAnimation(true); // Show the animation instead of navigating immediately
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error submitting location');
      }
    } catch (error) {
      console.error('Error submitting location:', error);
      toast.error((error as Error).message || 'Failed to submit location. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const customIcon = new Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
  });

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        setSubmission(prev => ({
          ...prev,
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        }));
      },
    });
    return null;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-gray-800 dark:text-gray-200 transition-colors duration-300">
      <Toaster position="top-right" />
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      {showAnimation && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <AnimatedPopup
          title="Location Submitted!"
          message="Successfully added location for review!"
          icon="⭐"
          actionText="Awesome!"
          onAction={() => navigate('/locations')}
          iconComponents={[Plane, MapPin, Camera]}
        />
      </div>
      )}
      
      <main className="container mx-auto px-4 pt-20 py-12">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-8 text-center text-gray-900 dark:text-gray-100">
          Submit New Spotting Location
        </h1>
          
          <Card className="backdrop-blur-md bg-white/90 dark:bg-gray-800/90 shadow-2xl rounded-3xl">
            <CardHeader>
              <CardTitle className="text-2xl font-semibold text-center">Location Details</CardTitle>
            </CardHeader>
            <CardContent className="p-8">
              <form onSubmit={handleSubmit} className="space-y-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="space-y-6">
                    <InputField
                      label="Airport ICAO"
                      name="airportICAO"
                      value={submission.airportICAO}
                      onChange={handleInputChange}
                      icon={<Plane />}
                      required
                      placeholder="Enter airport ICAO"
                      valid={airportValid}
                    />
                    <InputField
                      label="Location Title"
                      name="title"
                      value={submission.title}
                      onChange={handleInputChange}
                      required
                      placeholder="Enter a title for this location"
                    />
                    <TextareaField
                      label="Description"
                      name="description"
                      value={submission.description}
                      onChange={handleInputChange}
                      required
                      placeholder="Describe the spotting location"
                      rows={4}
                    />
                    <InputField
                      label="Best Equipment"
                      name="bestEquipment"
                      value={submission.bestEquipment}
                      onChange={handleInputChange}
                      required
                      icon={<Camera />}
                      placeholder="Recommended equipment"
                    />
                    <InputField
                      label="Parking Information"
                      name="parkingInformation"
                      value={submission.parkingInformation}
                      onChange={handleInputChange}
                      required
                      icon={<SquareParking />}
                      placeholder="Parking details"
                    />
                    <InputField
                      label="Security Information"
                      name="securityInformation"
                      value={submission.securityInformation}
                      onChange={handleInputChange}
                      required
                      icon={<Shield />}
                      placeholder="Security considerations"
                    />
                      <InputField
                      label="Opening Hours"
                      name="openingHours"
                      value={submission.openingHours || ''}
                      onChange={handleInputChange}
                      required
                      icon={<Clock />}
                      placeholder="Enter opening hours in local timezone"
                    />
                    <TextareaField
                      label="Extra Information"
                      name="extraInformation"
                      value={submission.extraInformation}
                      required
                      onChange={handleInputChange}
                      placeholder="Any additional information"
                      rows={4}
                    />
                  </div>

                  <div className="space-y-6">
                    <label className="block text-lg font-medium text-gray-700 dark:text-gray-300">
                      Select Location on Map
                    </label>
                    <div className="h-[400px] rounded-2xl overflow-hidden shadow-lg">
                      <MapContainer 
                        center={mapCenter} 
                        zoom={mapZoom} 
                        style={{ height: '100%', width: '100%' }}
                        className="z-0"
                        minZoom={1}
                        maxBounds={[[-90, -180], [90, 180]]}
                      >
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker 
                          position={[submission.latitude, submission.longitude]} 
                          icon={customIcon}
                        />
                        <MapEvents />
                      </MapContainer>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <InputField
                        label="Latitude"
                        name="latitude"
                        value={submission.latitude}
                        onChange={handleInputChange}
                        icon={<MapPin />}
                        type="number"
                        step="any"
                        required
                      />
                      <InputField
                        label="Longitude"
                        name="longitude"
                        value={submission.longitude}
                        onChange={handleInputChange}
                        icon={<MapPin />}
                        type="number"
                        step="any"
                        required
                      />
                    </div>
                    <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-inner">
                      <div className="flex items-start space-x-3">
                        <Checkbox
                          id="guideRead"
                          checked={guideRead}
                          onCheckedChange={(checked) => setGuideRead(checked as boolean)}
                          className="mt-1"
                        />
                        <div className="flex-grow">
                          <label
                            htmlFor="guideRead"
                            className="text-sm font-medium leading-none cursor-pointer"
                          >
                            I confirm that I have read and understood the submission guide
                          </label>
                          <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                            Please ensure you've reviewed our guidelines before submitting a location.
                          </p>
                        </div>
                      </div>
                      
                      <div className="mt-4 flex items-center justify-between">
                        <Button 
                          variant="outline" 
                          size="sm"
                          onClick={() => window.open('/guide', '_blank')}
                          className="text-xs flex items-center"
                        >
                          Open Guide <ExternalLink className="ml-1 h-3 w-3" />
                        </Button>
                        
                        <div className="relative">
                          <Button
                            variant="ghost"
                            size="sm"
                            className="text-xs"
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                          >
                            Why is this important? <AlertCircle className="ml-1 h-3 w-3" />
                          </Button>
                          
                          <AnimatePresence>
                            {showTooltip && (
                              <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 10 }}
                                className="absolute right-0 mt-2 p-2 bg-white dark:bg-gray-700 rounded shadow-lg z-10 w-64"
                              >
                                <p className="text-xs text-gray-600 dark:text-gray-300">
                                  Reading the guide ensures that you provide accurate and valuable information, 
                                  maintaining the quality of our spotting locations database.
                                </p>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Button 
                  type="submit" 
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-300 text-lg py-3 rounded-full"
                  disabled={isLoading || !formValid}
                >
                  {isLoading ? (
                    <AnimatePresence>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="flex items-center justify-center"
                      >
                        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white mr-2"></div>
                        Submitting...
                      </motion.div>
                    </AnimatePresence>
                  ) : (
                    <>
                      <Send className="mr-2 h-5 w-5" />
                      Submit Location
                    </>
                  )}
                </Button>
              </form>
            </CardContent>
          </Card>
        </motion.div>
      </main>

      <Footer />
    </div>
  );
};

interface InputFieldProps {
  label: string;
  name: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  required?: boolean;
  placeholder?: string;
  type?: string;
  step?: string;
  valid?: boolean | null;
}

const InputField: React.FC<InputFieldProps> = ({ label, name, value, onChange, icon, required, placeholder, type = "text", step, valid }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="relative flex items-center">
      {icon && <span className="absolute left-3 text-gray-400">{icon}</span>}
      <Input
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        type={type}
        step={step}
        className={`${icon ? 'pl-10' : ''} pr-10 bg-white/50 dark:bg-gray-700/50 border-2 `}
      />
      {name === 'airportICAO' && valid !== null && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          {valid ? (
            <Check className="h-5 w-5 text-green-500" />
          ) : (
            <X className="h-5 w-5 text-red-500" />
          )}
        </span>
      )}
    </div>
  </div>
);

interface TextareaFieldProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  icon?: React.ReactNode;
  required?: boolean;
  placeholder?: string;
  rows?: number;
}

const TextareaField: React.FC<TextareaFieldProps> = ({ label, name, value, onChange, icon, required, placeholder, rows }) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
      {label} {required && <span className="text-red-500">*</span>}
    </label>
    <div className="relative">
      {icon && <span className="absolute top-3 left-3 text-gray-400">{icon}</span>}
      <Textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        rows={rows}
        className={`${icon ? 'pl-10' : ''} bg-white/50 dark:bg-gray-700/50 border-2`}
      />
    </div>
  </div>
);

export default LocationSubmissionPage;