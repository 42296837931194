import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Edit, Trash2, Plus, Check, X, AlertTriangle, Calendar, Info, Plane } from 'lucide-react';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectItem } from '@/components/ui/select';
import { toast, Toaster } from 'react-hot-toast';
import { Alert, AlertDescription } from '@/components/ui/alert';
import HyperlinkText from '@/components/ui/HyperLinkText';

interface Movement {
  id: string;
  title: string;
  details: string;
  date: string;
  type: 'movement' | 'important';
  airportIcao: string;
}

interface User {
  isAdmin: boolean;
  manageMovements: boolean;
}

const API_BASE_URL = 'https://api.spottertools.org/api';

const AdminMovementsManagement: React.FC = () => {
  const [movements, setMovements] = useState<Movement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentMovement, setCurrentMovement] = useState<Movement | null>(null);
  const navigate = useNavigate();
  const [movementType, setMovementType] = useState<'movement' | 'important'>('movement');

  const fetchMovements = useCallback(async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('No authentication token found');
        }

        const response = await fetch(`${API_BASE_URL}/admin/movements`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
                throw new Error('Your session has expired. Please log in again.');
            }
            throw new Error(`Failed to fetch movements: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        const movementsArray: Movement[] = data ? Object.entries(data).map(([id, movement]: [string, any]) => ({
            ...movement,
            id: movement.id || id, // Use the movement's id if available, otherwise use the object key
        })) : [];

        setMovements(movementsArray);
    } catch (error) {
        console.error('Error fetching movements:', error);
        setError(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
        setLoading(false);
    }
}, [navigate]);

  useEffect(() => {
    const checkUserPermissions = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`${API_BASE_URL}/user/permissions`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            localStorage.removeItem('token');
            navigate('/account');
            throw new Error('Your session has expired. Please log in again.');
          }
          throw new Error(`Failed to fetch user permissions: ${response.status} ${response.statusText}`);
        }

        const userData: User = await response.json();
        if (!userData.isAdmin && !userData.manageMovements) {
          navigate('/');
          toast.error('You do not have permission to access this page');
        }
      } catch (error) {
        console.error('Error checking user permissions:', error);
        setError(error instanceof Error ? error.message : 'An unknown error occurred');
        setLoading(false);
      }
    };

    checkUserPermissions();
    fetchMovements();
  }, [fetchMovements, navigate]);

  const handleAddMovement = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const newMovement: Omit<Movement, 'id'> = {
      title: formData.get('title') as string,
      details: formData.get('details') as string,
      date: formData.get('date') as string,
      type: movementType,
      airportIcao: formData.get('airportIcao') as string,
    };

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`${API_BASE_URL}/admin/movements`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newMovement),
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
          throw new Error('Your session has expired. Please log in again.');
        }
        throw new Error('Failed to add movement');
      }

      toast.success('Movement added successfully');
      fetchMovements();
      setIsEditing(false);
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const handleEditMovement = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const updatedMovement: Movement = {
      id: currentMovement?.id || '',
      title: formData.get('title') as string,
      details: formData.get('details') as string,
      date: formData.get('date') as string,
      type: movementType,
      airportIcao: formData.get('airportIcao') as string,
    };

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`${API_BASE_URL}/admin/movements/${updatedMovement.id}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updatedMovement),
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
          throw new Error('Your session has expired. Please log in again.');
        }
        throw new Error('Failed to update movement');
      }

      toast.success('Movement updated successfully');
      fetchMovements();
      setIsEditing(false);
      setCurrentMovement(null);
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'An unknown error occurred');
    }
  };

  const handleDeleteMovement = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this movement?')) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch(`${API_BASE_URL}/admin/movements/${id}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            localStorage.removeItem('token');
            navigate('/login');
            throw new Error('Your session has expired. Please log in again.');
          }
          throw new Error('Failed to delete movement');
        }

        toast.success('Movement deleted successfully');
        fetchMovements();
      } catch (error) {
        toast.error(error instanceof Error ? error.message : 'An unknown error occurred');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
        <Header onNavigate={(page) => navigate(`/${page}`)} />
        <main className="container mx-auto px-4 pt-20 py-12">
          <Alert>
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          <p className="mt-4 text-center">
            If this error persists, please try logging out and logging back in, or contact support.
          </p>
          <div className="mt-8 text-center">
            <Button onClick={() => navigate('/')} variant="outline">
              Return to Home
            </Button>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <Toaster position="top-right" />
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      
      <main className="container mx-auto px-4 pt-20 py-12">
        <motion.h1 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-4xl font-bold mb-8 text-center text-gray-900 dark:text-gray-100"
        >
          Manage Aircraft Movements
        </motion.h1>

        <Card className="mb-8 shadow-lg">
          <CardHeader>
            <CardTitle className="text-2xl">
              {isEditing ? (currentMovement ? 'Edit Movement' : 'Add New Movement') : 'Movements List'}
            </CardTitle>
          </CardHeader>
          <CardContent>
            {isEditing ? (
              <form onSubmit={currentMovement ? handleEditMovement : handleAddMovement} className="space-y-4">
                <Input name="title" defaultValue={currentMovement?.title} placeholder="Title" required />
                <div className="space-y-2">
                  <Textarea 
                    name="details" 
                    defaultValue={currentMovement?.details} 
                    placeholder="Details" 
                    required 
                  />
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Tip: You can add hyperlinks like this: [Text]/link/to/page
                  </p>
                </div>
                <Input
                  name="date"
                  type="date"
                  defaultValue={currentMovement?.date ? new Date(currentMovement.date).toISOString().split('T')[0] : ''}
                  required
                />
                <Input 
                  name="airportIcao" 
                  defaultValue={currentMovement?.airportIcao} 
                  placeholder="Airport ICAO" 
                  required 
                />
                <Select 
                  onChange={(value) => setMovementType(value as 'movement' | 'important')} 
                  defaultValue={currentMovement?.type || 'movement'}
                >
                  <SelectItem value="movement">Interesting</SelectItem>
                  <SelectItem value="important">Important</SelectItem>
                </Select>
                <div className="flex justify-end space-x-2">
                  <Button type="submit" className="bg-green-500 hover:bg-green-600 text-white">
                    <Check className="mr-2 h-4 w-4" />
                    {currentMovement ? 'Update' : 'Add'}
                  </Button>
                  <Button type="button" onClick={() => {
                    setIsEditing(false);
                    setCurrentMovement(null);
                  }} variant="secondary">
                    <X className="mr-2 h-4 w-4" />
                    Cancel
                  </Button>
                </div>
              </form>
            ) : (
              <>
                <Button onClick={() => setIsEditing(true)} className="mb-4 bg-blue-500 hover:bg-blue-600 text-white">
                  <Plus className="mr-2 h-4 w-4" />
                  Add New Movement
                </Button>
                <AnimatePresence>
                  {movements.length > 0 ? (
                    <motion.div layout className="space-y-4">
                      {movements.map((movement) => (
                        <motion.div
                          key={movement.id}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Card className={`border-l-4 ${movement.type === 'important' ? 'border-l-red-500' : 'border-l-blue-500'}`}>
                            <CardContent className="p-4">
                              <div className="flex justify-between items-start">
                                <div>
                                  <h3 className="font-semibold text-lg">{movement.title}</h3>
                                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center mt-1">
                                    <Calendar className="mr-1 h-4 w-4" /> {movement.date}
                                  </p>
                                  <p className="text-sm text-gray-600 dark:text-gray-400 flex items-center mt-1">
                                    <Plane className="mr-1 h-4 w-4" /> {movement.airportIcao}
                                  </p>
                                  <p className="text-sm text-gray-700 dark:text-gray-300 mt-2 flex items-start">
                                    <Info className="mr-1 h-4 w-4 mt-1 flex-shrink-0" />
                                    <HyperlinkText text={movement.details} />
                                  </p>
                                </div>
                                <div className="flex space-x-2">
                                  <Button
                                    onClick={() => {
                                      setCurrentMovement(movement);
                                      setIsEditing(true);
                                    }}
                                    variant="outline"
                                    size="sm"
                                  >
                                    <Edit className="h-4 w-4" />
                                  </Button>
                                  <Button onClick={() => handleDeleteMovement(movement.id)} variant="destructive" size="sm">
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </motion.div>
                      ))}
                    </motion.div>
                  ) : (
                    <p className="text-center text-gray-500 dark:text-gray-400">No movements found.</p>
                  )}
                </AnimatePresence>
              </>
            )}
          </CardContent>
        </Card>
      </main>
      
      <Footer />
    </div>
  );
};

export default AdminMovementsManagement;