import React, { useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { MapPin, Plane, ChevronRight, Users, Award, Compass } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import Header from '@/components/ui/header';
import { useNavigate } from 'react-router-dom';
import Footer from '@/components/ui/footer';
import MovementNotificationContainer from '@/components/MovementNotification';

interface FeatureCardProps {
  icon: React.ElementType;
  title: string;
  description: string;
  onClick: () => void;
  delay?: number;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon: Icon, title, description, onClick, delay = 0 }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ scale: 1.02 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="group"
    >
      <Card 
        className={`
          relative overflow-hidden 
          bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg 
          shadow-lg transition-all duration-500 
          cursor-pointer h-full border-2 border-transparent 
          hover:border-blue-400 dark:hover:border-blue-500
        `}
        onClick={onClick}
      >
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-purple-500/10"
          animate={{ opacity: isHovered ? 1 : 0 }}
          transition={{ duration: 0.3 }}
        />
        
        <CardContent className="p-8 flex flex-col h-full relative z-10">
          <motion.div 
            className="flex flex-col items-center mb-6"
            animate={{ y: isHovered ? -5 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="relative">
              <motion.div
                className="absolute inset-0 bg-blue-500/20 rounded-full blur-xl"
                animate={{ scale: isHovered ? 1.2 : 1 }}
                transition={{ duration: 0.3 }}
              />
              <Icon className="w-20 h-20 mb-4 text-blue-600 dark:text-blue-400 relative z-10" />
            </div>
            <h3 className="text-3xl font-bold text-blue-600 dark:text-blue-400">
              {title}
            </h3>
          </motion.div>

          <p className="text-lg text-gray-600 dark:text-gray-400 text-center mb-6 flex-grow">
            {description}
          </p>

          <motion.div
            className="mt-auto"
            animate={{ x: isHovered ? 5 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <Button 
              className="w-full group bg-blue-500 hover:bg-blue-600 text-white"
            >
              Explore
              <ChevronRight className="ml-2 group-hover:translate-x-1 transition-transform" />
            </Button>
          </motion.div>
        </CardContent>
      </Card>
    </motion.div>
  );
};

const StatsCard = ({ icon: Icon, value, label }: { icon: any, value: string, label: string }) => (
  <motion.div 
    className="flex items-center bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg p-6 rounded-xl shadow-lg"
    whileHover={{ scale: 1.05, rotate: 1 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <div className="mr-4 bg-blue-500 p-3 rounded-lg">
      <Icon className="w-8 h-8 text-white" />
    </div>
    <div>
      <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
        {value}
      </div>
      <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
    </div>
  </motion.div>
);

const HomePage = () => {
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-900 text-gray-800 dark:text-gray-200 transition-colors duration-300 overflow-hidden">
      
      <div className="relative z-10">
        <Header onNavigate={(page) => navigate(`/${page}`)} />
        <MovementNotificationContainer />

        <main className="container mx-auto px-4 pt-20 py-16">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="max-w-6xl mx-auto"
          >
            <motion.div
              className="text-center mb-20 relative"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
            >
              <motion.div
                className="absolute inset-0 bg-blue-500/5 dark:bg-blue-500/10 rounded-full blur-3xl"
                style={{ y }}
                animate={{ 
                  scale: [1, 1.1, 1],
                  rotate: [0, 5, 0]
                }}
                transition={{ 
                  duration: 10,
                  repeat: Infinity,
                }}
              />
              
              <h1 className="text-7xl font-black mb-8 relative">
                <span className="text-blue-600 dark:text-blue-400">
                  SpotterTools
                </span>
              </h1>
              
              <p className="text-2xl text-gray-700 dark:text-gray-300 max-w-3xl mx-auto leading-relaxed relative z-10">
                Your comprehensive platform for aviation enthusiasts. Discover spotting locations, predict runway usage, and explore advanced aviation tools.
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
              <FeatureCard 
                icon={Compass} 
                title="Ultimate Tools" 
                description="Access our suite of professional aviation tools, including runway predictions, movement tracking, and more."
                onClick={() => navigate('/tools')}
                delay={0.2}
              />
              <FeatureCard 
                icon={MapPin} 
                title="Global Community" 
                description="Join thousands of aviation enthusiasts worldwide. Share locations, insights, and experiences."
                onClick={() => navigate('/locations')}
                delay={0.4}
              />
            </div>

            <motion.div 
              className="text-center mb-20 relative"
              whileInView={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 20 }}
              viewport={{ once: true }}
            >
              <h2 className="text-4xl font-bold mb-8 text-blue-600 dark:text-blue-400">
                Ready to elevate your aviation experience?
              </h2>
              <Button 
                onClick={() => navigate('/tools')}
                className="text-xl py-6 px-12 rounded-full relative overflow-hidden group bg-blue-500 hover:bg-blue-600"
              >
                <motion.span
                  className="absolute inset-0 bg-white dark:bg-gray-800 opacity-0 group-hover:opacity-10 transition-opacity duration-300"
                  animate={{ rotate: [0, 360] }}
                  transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
                />
                <span className="relative z-10 flex items-center">
                  Explore Our Tools
                  <Plane className="ml-3 inline-block" />
                </span>
              </Button>
            </motion.div>

            <motion.div 
              className="text-center"
              whileInView={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 20 }}
              viewport={{ once: true }}
            >
              <h3 className="text-3xl font-bold mb-8 text-blue-600 dark:text-blue-400">
                Join our growing community
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <StatsCard icon={MapPin} value="500+" label="Spotting Locations" />
                <StatsCard icon={Users} value="1,000+" label="Active Users" />
                <StatsCard icon={Award} value="4.9/5" label="User Rating" />
              </div>
            </motion.div>
          </motion.div>
        </main>
        
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;