import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Plane, Loader, AlertCircle, Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import MovementNotificationContainer from '@/components/MovementNotification';

interface AirportSubmission {
  icao: string;
  iata: string;
  name: string;
  city: string;
  country: string;
  description: string;
  runways: string[];
  terminals: string[];
  services: {
    food: string[];
    toilets: string[];
  };
  airlines: number;
  commonAircraftTypes: string[];
  curfew?: string;
}

const AdminAirportSubmissionPage: React.FC = () => {
  const navigate = useNavigate();
  const [submission, setSubmission] = useState<AirportSubmission>({
    icao: '',
    iata: '',
    name: '',
    city: '',
    country: '',
    description: '',
    runways: [''],
    terminals: [''],
    services: {
      food: [''],
      toilets: [''],
    },
    airlines: 0,
    commonAircraftTypes: [''],
    curfew: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setSubmission(prev => ({ ...prev, [name]: value }));
  };

  const handleArrayInputChange = (index: number, field: keyof AirportSubmission, value: string) => {
    setSubmission(prev => ({
      ...prev,
      [field]: Array.isArray(prev[field]) 
        ? (prev[field] as string[]).map((item, i) => i === index ? value : item) 
        : prev[field],
    }));
  };

  const handleServiceInputChange = (index: number, service: 'food' | 'toilets', value: string) => {
    setSubmission(prev => ({
      ...prev,
      services: {
        ...prev.services,
        [service]: prev.services[service].map((item, i) => i === index ? value : item),
      },
    }));
  };

  const addArrayField = (field: keyof AirportSubmission) => {
    setSubmission(prev => ({
      ...prev,
      [field]: Array.isArray(prev[field]) 
        ? [...(prev[field] as string[]), ''] 
        : prev[field],
    }));
  };

  const addServiceField = (service: 'food' | 'toilets') => {
    setSubmission(prev => ({
      ...prev,
      services: {
        ...prev.services,
        [service]: [...prev.services[service], ''],
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch('https://api.spottertools.org/api/admin/airports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(submission),
      });

      if (!response.ok) {
        throw new Error('Failed to submit airport data');
      }

      setSuccess(true);
      setSubmission({
        icao: '',
        iata: '',
        name: '',
        city: '',
        country: '',
        description: '',
        runways: [''],
        terminals: [''],
        services: {
          food: [''],
          toilets: [''],
        },
        airlines: 0,
        commonAircraftTypes: [''],
        curfew: '',
      });
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      <main className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold mb-6 text-center">Admin: Submit Airport</h1>
          
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center">
                <Plane className="mr-2" />
                Airport Submission Form
              </CardTitle>
            </CardHeader>
            <CardContent>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Input
                    name="icao"
                    value={submission.icao}
                    onChange={handleInputChange}
                    placeholder="ICAO"
                    required
                  />
                  <Input
                    name="iata"
                    value={submission.iata}
                    onChange={handleInputChange}
                    placeholder="IATA"
                    required
                  />
                </div>
                <Input
                  name="name"
                  value={submission.name}
                  onChange={handleInputChange}
                  placeholder="Airport Name"
                  required
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <Input
                    name="city"
                    value={submission.city}
                    onChange={handleInputChange}
                    placeholder="City"
                    required
                  />
                  <Input
                    name="country"
                    value={submission.country}
                    onChange={handleInputChange}
                    placeholder="Country"
                    required
                  />
                </div>
                <Textarea
                  name="description"
                  value={submission.description}
                  onChange={handleInputChange}
                  placeholder="Airport Description"
                  required
                />
                <div>
                  <h3 className="font-semibold mb-2">Runways</h3>
                  {submission.runways.map((runway, index) => (
                    <Input
                      key={index}
                      value={runway}
                      onChange={(e) => handleArrayInputChange(index, 'runways', e.target.value)}
                      placeholder={`Runway ${index + 1}`}
                      className="mb-2"
                    />
                  ))}
                  <Button type="button" onClick={() => addArrayField('runways')} className="mt-2">
                    Add Runway
                  </Button>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Terminals</h3>
                  {submission.terminals.map((terminal, index) => (
                    <Input
                      key={index}
                      value={terminal}
                      onChange={(e) => handleArrayInputChange(index, 'terminals', e.target.value)}
                      placeholder={`Terminal ${index + 1}`}
                      className="mb-2"
                    />
                  ))}
                  <Button type="button" onClick={() => addArrayField('terminals')} className="mt-2">
                    Add Terminal
                  </Button>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">Services</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <h4 className="font-medium mb-1">Food Options</h4>
                      {submission.services.food.map((food, index) => (
                        <Input
                          key={index}
                          value={food}
                          onChange={(e) => handleServiceInputChange(index, 'food', e.target.value)}
                          placeholder={`Food Option ${index + 1}`}
                          className="mb-2"
                        />
                      ))}
                      <Button type="button" onClick={() => addServiceField('food')} className="mt-2">
                        Add Food Option
                      </Button>
                    </div>
                    <div>
                      <h4 className="font-medium mb-1">Toilets</h4>
                      {submission.services.toilets.map((toilet, index) => (
                        <Input
                          key={index}
                          value={toilet}
                          onChange={(e) => handleServiceInputChange(index, 'toilets', e.target.value)}
                          placeholder={`Toilet ${index + 1}`}
                          className="mb-2"
                        />
                      ))}
                      <Button type="button" onClick={() => addServiceField('toilets')} className="mt-2">
                        Add Toilet
                      </Button>
                    </div>
                  </div>
                </div>
                <Input
                  name="airlines"
                  type="number"
                  value={submission.airlines}
                  onChange={handleInputChange}
                  placeholder="Number of Airlines"
                  required
                />
                <div>
                  <h3 className="font-semibold mb-2">Common Aircraft Types</h3>
                  {submission.commonAircraftTypes.map((aircraft, index) => (
                    <Input
                      key={index}
                      value={aircraft}
                      onChange={(e) => handleArrayInputChange(index, 'commonAircraftTypes', e.target.value)}
                      placeholder={`Aircraft Type ${index + 1}`}
                      className="mb-2"
                    />
                  ))}
                  <Button type="button" onClick={() => addArrayField('commonAircraftTypes')} className="mt-2">
                    Add Aircraft Type
                  </Button>
                </div>
                <Input
                  name="curfew"
                  value={submission.curfew}
                  onChange={handleInputChange}
                  placeholder="Curfew (if applicable)"
                />
                <Button type="submit" className="w-full" disabled={loading}>
                  {loading ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : <Plane className="mr-2 h-4 w-4" />}
                  {loading ? 'Submitting...' : 'Submit Airport'}
                </Button>
              </form>
            </CardContent>
          </Card>
          
          {error && (
            <Alert className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {success && (
            <Alert className="mt-4 bg-green-100 dark:bg-green-800 text-green-800 dark:text-green-100">
              <Check className="h-4 w-4" />
              <AlertDescription>Airport submitted successfully!</AlertDescription>
            </Alert>
          )}
        </motion.div>
      </main>
      
      <Footer />
    </div>
  );
};

export default AdminAirportSubmissionPage;