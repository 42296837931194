import Tracker from "@/pages/TransperthTrackerPage";
import { useEffect, useState } from "react";

interface Station {
    LineId: string;
    LineName: string;
    StationId: string;
    StationName: string;
    StopOrder: number;
    Outbound: boolean;
  }
  
  interface GroupedStations {
    [key: string]: Station[];
  }
  
  const StationSelector: React.FC = () => {
    const [stations, setStations] = useState<GroupedStations>({});
    const [selectedStation, setSelectedStation] = useState("");
    const [platforms, setPlatforms] = useState<string[]>([]);
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [showDisplay, setShowDisplay] = useState(false);
  
    useEffect(() => {
      const fetchStations = async () => {
        const response = await fetch('/data/stations.xml');
        const text = await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, 'text/xml');
        
        const lineStations = Array.from(xml.getElementsByTagName('LineStation'));
        const grouped = lineStations.reduce((acc: GroupedStations, station) => {
          const lineName = station.getElementsByTagName('LineName')[0].textContent || '';
          const stationData: Station = {
            LineId: station.getElementsByTagName('LineId')[0].textContent || '',
            LineName: lineName,
            StationId: station.getElementsByTagName('StationId')[0].textContent || '',
            StationName: station.getElementsByTagName('StationName')[0].textContent || '',
            StopOrder: parseInt(station.getElementsByTagName('StopOrder')[0].textContent || '0'),
            Outbound: station.getElementsByTagName('Outbound')[0].textContent === 'true'
          };
          
          if (!acc[lineName]) acc[lineName] = [];
          if (!acc[lineName].some(s => s.StationName === stationData.StationName)) {
            acc[lineName].push(stationData);
          }
          return acc;
        }, {});
  
        setStations(grouped);
      };
  
      fetchStations();
    }, []);
  
    const handleStationSelect = async (station: string) => {
      setSelectedStation(station);
      const response = await fetch(`https://api.spottertools.org/dev/station/stationname=${encodeURIComponent(station)}`);
      const data = await response.json();
      const parser = new DOMParser();
      const xml = parser.parseFromString(data.raw, 'text/xml');
      const trips = Array.from(xml.getElementsByTagName('SercoTrip'));
      const uniquePlatforms = Array.from(new Set(trips.map(trip => 
        trip.getElementsByTagName('Platform')[0]?.textContent ?? ''
      ).filter(platform => platform !== '')));
      setPlatforms(uniquePlatforms);
    };
  
    if (showDisplay && selectedStation && selectedPlatform) {
      return <Tracker station={selectedStation} platform={selectedPlatform} />;
    }
  
    return (
      <div className="p-4">
        <h2 className="text-xl mb-4">Select Station and Platform</h2>
        <div className="space-y-4">
          <div>
            <label className="block mb-2">Station:</label>
            <select 
              className="w-full p-2 border rounded"
              onChange={(e) => handleStationSelect(e.target.value)}
              value={selectedStation}
            >
              <option value="">Select a station</option>
              {Object.entries(stations).map(([line, stations]) => (
                <optgroup key={line} label={line}>
                  {stations.map(station => (
                    <option key={station.StationId} value={station.StationName}>
                      {station.StationName}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
  
          {platforms.length > 0 && (
            <div>
              <label className="block mb-2">Platform:</label>
              <select 
                className="w-full p-2 border rounded"
                onChange={(e) => setSelectedPlatform(e.target.value)}
                value={selectedPlatform}
              >
                <option value="">Select a platform</option>
                {platforms.map(platform => (
                  <option key={platform} value={platform}>{platform}</option>
                ))}
              </select>
            </div>
          )}
  
          {selectedStation && selectedPlatform && (
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => setShowDisplay(true)}
            >
              Show Display
            </button>
          )}
        </div>
      </div>
    );
  };
  
  export default StationSelector;