import React from 'react';
import { motion } from 'framer-motion';
import { Ruler, Plane, TowerControl, ChevronRight, Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import Header from '@/components/ui/header';
import Footer from '@/components/ui/footer';
import MovementNotificationContainer from '@/components/MovementNotification';

import { LucideIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface ToolCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  onClick: () => void;
  index: number;
}

const ToolCard: React.FC<ToolCardProps> = ({ icon: Icon, title, description, onClick, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      whileHover={{ y: -8, transition: { duration: 0.2 } }}
      className="group relative"
    >
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-2xl transform group-hover:scale-105 transition-transform duration-300" />
      <Card className="relative h-full bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm border-2 border-gray-100 dark:border-gray-700 overflow-hidden">
        <div className="p-6 flex flex-col h-full">
          <div className="flex items-center space-x-4 mb-4">
            <div className="p-3 bg-blue-500/10 dark:bg-blue-400/10 rounded-xl">
              <Icon className="w-8 h-8 text-blue-600 dark:text-blue-400" />
            </div>
            <h3 className="text-xl font-bold text-gray-900 dark:text-gray-100">{title}</h3>
          </div>
          
          <p className="text-gray-600 dark:text-gray-400 mb-6 flex-grow">{description}</p>
          
          <Button 
            onClick={onClick}
            className="group w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white"
          >
            Launch Tool
            <ChevronRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
          </Button>
        </div>
      </Card>
    </motion.div>
  );
};

const ToolsPage = () => {
  const navigate = useNavigate();

  const tools = [
    {
      icon: Ruler,
      title: "Runway Prediction",
      description: "Predict the most likely runway for landing based on current weather conditions and airport data.",
      onClick: () => navigate('/tools/runways')
    },
    {
      icon: Plane,
      title: "Spotting Locations",
      description: "Discover the best spotting locations near you and around the world.",
      onClick: () => navigate('/locations')
    },
    {
      icon: Plane,
      title: "Aircraft Movements & News",
      description: "Get up-to-date information on aircraft movements and news at airports worldwide.",
      onClick: () => navigate('/movements')
    },
    {
      icon: TowerControl,
      title: "Airport Lookup",
      description: "Enter an airport's ICAO code in this tool to view detailed information.",
      onClick: () => navigate('/airports')
    },
    {
      icon: Plane,
      title: "Live VATSIM Tracker",
      description: "Track live VATSIM flight counts in real-time.",
      onClick: () => navigate('/vatsim/tracker')
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
      <Header onNavigate={(page) => navigate(`/${page}`)} />
      <MovementNotificationContainer />
      
      <main className="container mx-auto px-4 pt-20 py-12">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-5xl md:text-6xl font-extrabold pb-4 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-700 dark:from-blue-400 dark:to-blue-500">
              Advanced Aviation Tools
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-400 max-w-3xl mx-auto">
              Explore our collection of powerful tools designed for aviation enthusiasts and professionals.
            </p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            {tools.map((tool, index) => (
              <ToolCard key={index} {...tool} index={index} />
            ))}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="mt-16 text-center"
          >
            <Button
              onClick={() => navigate('/contact')}
              variant="outline"
              className="group text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
            >
              <Plus className="mr-2 h-4 w-4 group-hover:rotate-90 transition-transform duration-300" />
              Request New Tool
            </Button>
          </motion.div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default ToolsPage;